export const RESPONSE = {
    PARTIAL_SUCCESS: "PARTIAL_SUCCESS",
    SUCCESS: "SUCCESS",
    UNAUTHORIZED: "UNAUTHORIZED",
    BAG_WEIGHT_LIMIT_REACHED: "BAG_WEIGHT_LIMIT_REACHED",
    PACKAGE_ALREADY_STOWED: "PACKAGE_ALREADY_STOWED",
    NO_PLAN_PRESENT: "NO_PLAN_PRESENT",
    INVALID_MOVE: "INVALID_MOVE",
    INVALID_SCAN_DONE: "INVALID_SCAN_DONE",
    BAG_ALREADY_OPEN: "BAG_ALREADY_OPEN",
    UNKNOWN_ERROR_OCCURRED: "UNKNOWN_ERROR_OCCURRED",
    BAG_NOT_OPEN: "BAG_NOT_OPEN",
    INVALID_PACKAGE_STATE: "INVALID_PACKAGE_STATE",
    STATION_MISMATCH: "STATION_MISMATCH",
    CONTAINER_DOES_NOT_EXIST: "CONTAINER_DOES_NOT_EXIST",
    UNSUPPORTED_BARCODE: "UNSUPPORTED_BARCODE",
    NON_EMPTY_BAG: "NON_EMPTY_BAG",
    BAG_ALREADY_CLOSED: "BAG_ALREADY_CLOSED",
    OV_AREA_MISMATCH: "OV_AREA_MISMATCH",
    NON_EMPTY_LOCATION: "NON_EMPTY_LOCATION",
    CART_ALREADY_ATTACHED: "CART_ALREADY_ATTACHED",
    NON_EMPTY_CART: "NON_EMPTY_CART",
    CART_NOT_ATTACHED: "CART_NOT_ATTACHED",
    ERROR: "ERROR",
    INVALID_PACKAGE_SORTGROUP: "INVALID_PACKAGE_SORTGROUP",
    EMPTY_LOCATION: "EMPTY_LOCATION",
    CONTAINER_NOT_OPEN: "CONTAINER_NOT_OPEN",
    TOO_MANY_CONTAINERS: "TOO_MANY_CONTAINERS",
    EMPTY_CONTAINER: "EMPTY_CONTAINER",
    NO_INTERNET: "NO_INTERNET",
    NO_DESTINATION_FOUND : "NO_DESTINATION_FOUND",
    NO_BAG_FOUND: "NO_BAG_FOUND",
    BAG_STATION_MISMATCH: "BAG_STATION_MISMATCH",
    PRINT_SUCCESS: "PRINT_SUCCESS",
    CONTAINER_SORTKEY_MISMATCH: "CONTAINER_SORTKEY_MISMATCH"
}
