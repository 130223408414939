import React, {useEffect, useContext} from 'react';
import {injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";
import HeadingView, {HEADING_TYPE} from "../../heading-view/HeadingView";
import ScanView from "../../scan-view/ScanView";
import {COMMON_STRINGS, NOTIFICATION_STRING} from "../../../constants/Strings";
import DetailsCardWithCartView from "../../stow/details-card/DetailsCardWithCartView";
import {blockBackPress, getFormattedString, resetBlockedBackPress} from "../../utils/Util";
import AppDataStore from "../../app-data";
import {CartHotSwapContext} from "../context/CartHotSwapContext";
import {COMMON_ACTIONS} from "../../../constants/ActionType";
import {doesScannedCartMatchesWithExpectedCart} from "../../utils/Util";
import {NotificationContext} from "../../notification/context/NotificationContext";
import {NOTIFICATION_TYPE} from "../../../constants/NotificationType";
import {AppNavigationContext} from "../../app-navigation/context/AppNavigationContext";
import {getHelpData} from "../data/CartHotSwapViewData";
import {playSoundByAlertType} from "../../sound/SoundManager";
import {NotificationType} from "../../../constants/Constants";
import {redirectToStage} from "../action/CartHotSwapAction";
import {Logger} from "@amzn/dolphin-web-framework";

const CartHotSwapView = (props) => {

    const {hotSwapDispatch} = useContext(CartHotSwapContext);
    const {navActions: {setHelpOption, closeHelpOption, openBackEnabled, initContext, openBackDrop, closeBackEnabled}} = useContext(AppNavigationContext)
    const {notificationActions: {setNotification, clearNotification}} = useContext(NotificationContext)

    useEffect( () => {
        clearNotification();
        AppDataStore.startHotSwap();
        setHelpOption(true, getHelpData(props, closeHelpOption));
        blockBackPress(initContext, closeBackEnabled);
        playSoundByAlertType(NotificationType.WARNING);
        Logger.log.info("CartHotSwapView Mount for " + cartId);
        return () => {
            setHelpOption(true, {list: []});
            resetBlockedBackPress(openBackEnabled, initContext, openBackDrop);
            Logger.log.info("CartHotSwapView Unmount for " + cartId);
        }
    }, []);

    const cartId = (props.state && props.state.cartId) ? props.state.cartId : null;
    const routeId = (props.state && props.state.routeId) ? props.state.routeId : null;

    const cartInfoData =
        [{
            description: getFormattedString(props, COMMON_STRINGS.station_pair),
            title: (props.state && props.state.stationPair) ? props.state.stationPair : null
        }, {
            description: getFormattedString(props, COMMON_STRINGS.location),
            title: (props.state && props.state.location) ? props.state.location : null
        }, {
            description: getFormattedString(props, COMMON_STRINGS.cart),
            title: cartId
        }];

    const getHeaderData = () => {
        return {
            primaryTitle: COMMON_STRINGS.HOT_SWAP,
            secondaryTitle: COMMON_STRINGS.SCAN_CART
        }
    };

    const renderCartInfoView = () => {
        return <DetailsCardWithCartView snapshots={cartInfoData} headerFontSize="Large"/>
    };

    const onScan = (input) => {
        hotSwapDispatch({type: COMMON_ACTIONS.LOADING});
        if (doesScannedCartMatchesWithExpectedCart(input, cartId)) {
            //Redirect to STAGE Module
            redirectToStage(hotSwapDispatch, props, cartId, routeId);
        } else {
            const values = {
                scannedCartId: input,
            };
            setNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: NOTIFICATION_STRING.ALERT_ERROR_INVALID_CART_SCAN,
                values
            });
        }

    };

    return (
        <div>
            <Column spacingInset="medium" alignmentHorizontal="center">
                <HeadingView {...getHeaderData()} type={HEADING_TYPE.PRIMARY}/>
            </Column>
            <Column spacingInset="medium">
                {renderCartInfoView()}
            </Column>
            <ScanView onScan={onScan} ariaLabel={getFormattedString(props, getHeaderData().secondaryTitle)}/>
        </div>
    )
};

export default injectIntl(CartHotSwapView)
