import React, {Component} from "react";
import {SnapshotView} from "@amzn/dolphin-uiweb-framework";
import Box from "@amzn/meridian/box";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";

class RouteDetailCardView extends Component {

    createSnapshots = (snapshots, headerFontSize) => {
        return snapshots.map((currentSnapshot, index) =>
            <SnapshotView key={index} title={currentSnapshot.title} description={currentSnapshot.description}
                          headerFontSize={headerFontSize}/>
        )
    }

    setDisplay = (snapshots) => {
        return (
            <Column width="100%" spacing="none">
                <Row spacing="none">
                    {snapshots[0]}
                    {snapshots[1]}
                    {snapshots[2]}
                </Row>
                <Row spacing="none">
                    {snapshots[3]}
                    {snapshots[4]}
                    {snapshots[5]}
                </Row>
            </Column>
        )
    }

    onClick = (e) => {
        this.props.handleTrackingIdsDialog(this.props.index)
        //prevents further bubbling up to parent elements of the current event.
        e.stopPropagation()
    }

    render() {
        const {backgroundColor, headerFontSize, snapshots} = this.props
        const dataSnapshots = this.createSnapshots(snapshots, headerFontSize)
        const displayData = this.setDisplay(dataSnapshots)
        return (
            <div onClick={this.onClick}>
                <Box type="outline" backgroundColor={backgroundColor}>
                    {displayData}
                </Box>
                <br/>
            </div>

        )
    }
}

export default RouteDetailCardView;