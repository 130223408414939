import React, {createContext, useReducer} from "react";
import {PlayerReducer} from "../reducer/PlayerReducer";
import {stateCheck} from "../PlayerState";

export const PlayerContext = createContext();

const PlayerContextProvider = (props) => {
    const initState = {
        isInitialized: false,
        isFirstPackage: true,
        isFirstCart: true,
        scope: undefined,
        helpType: undefined,
        backState: undefined,
        scanType: undefined,
        isValidPackageReScan: undefined
    };

    const [statePlayer, dispatchPlayer] = useReducer(PlayerReducer, initState);

    const initPlayerState = (scope, process_path) => {
        stateCheck(scope, process_path, statePlayer);
    }

    return (
        <PlayerContext.Provider value={{statePlayer, dispatchPlayer, initPlayerState}}>
            {props.children}
        </PlayerContext.Provider>
    )
}

export default PlayerContextProvider;
