import {HelpTypes, PlayerConstants} from "./PlayerConstant";
import {getRegion, getStage} from "../utils/network/BaseUrlUtils";
import {PlayerHelper} from "@amzn/dolphin-web-framework";
import {SCOPE} from "../../constants/Scope";
import {SCRUB_CONTAINER_STRINGS} from "../../constants/Strings";

export var stateCheck = function(scope, process_path, statePlayer) {
    statePlayer.scope = scope;
    statePlayer.helpType = (statePlayer.scope === SCOPE.AMZL) ? HelpTypes.CONTAINER_CLOSE : HelpTypes.DEFAULT
    if (!statePlayer.isInitialized) {
        if (scope === SCOPE.AMZL) {
            switch (process_path) {
                case PlayerConstants.PROCESS_PATH_STOW: {
                    PlayerHelper.init(PlayerConstants.PROCESS_PATH_STOW, PlayerConstants.VERSION, getStage(), getRegion())
                        .then(() => {
                            PlayerHelper.sendMessage(new window.DolphinPlayer.Stow.ProcessSelectedEvent(), new window.DolphinPlayer.Stow.TaskSelectState());
                        });
                    break;
                }
                case PlayerConstants.PROCESS_PATH_STOW_AREA_READINESS: {
                    PlayerHelper.init(PlayerConstants.PROCESS_PATH_STOW_AREA_READINESS, PlayerConstants.VERSION, getStage(), getRegion())
                        .then(() => {
                            PlayerHelper.sendMessage(new window.DolphinPlayer.Stow.ProcessSelectedEvent(), new window.DolphinPlayer.Stow.TaskSelectState());
                        });
                    break;
                }
                case SCRUB_CONTAINER_STRINGS.scrub_location_container: {
                    PlayerHelper.init(PlayerConstants.PROCESS_PATH_STOW_AREA_READINESS, PlayerConstants.VERSION, getStage(), getRegion())
                        .then(() => {
                            PlayerHelper.sendMessage(new window.DolphinPlayer.Stow.ProcessSelectedEvent(), new window.DolphinPlayer.Stow.TaskSelectState());
                        });
                    break;
                }
                default: {
                }
            }
        } else if (scope === SCOPE.SSD) {
            switch (process_path) {
                case PlayerConstants.PROCESS_PATH_STOW_SSD: {
                    PlayerHelper.init(PlayerConstants.PROCESS_PATH_STOW_SSD, PlayerConstants.VERSION, getStage(), getRegion())
                        .then(() => {
                            PlayerHelper.sendMessage(new window.DolphinPlayer.DrsStow.ProcessSelectedEvent(), new window.DolphinPlayer.DrsStow.TaskSelectState());
                        });
                    break;
                }
                case PlayerConstants.PROCESS_PATH_STOW: {
                    PlayerHelper.init(PlayerConstants.PROCESS_PATH_STOW_SSD, PlayerConstants.VERSION, getStage(), getRegion())
                        .then(() => {
                            PlayerHelper.sendMessage(new window.DolphinPlayer.DrsStow.ProcessSelectedEvent(), new window.DolphinPlayer.DrsStow.TaskSelectState());
                        });
                    break;
                }
                default: {
                }
            }
        }
        statePlayer.isInitialized = true;
    }
}