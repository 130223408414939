import {
    dev_debug,
    NO_INTERNET_RESPONSE, UNAUTHORIZED_ERROR_RESPONSE,
    UNKNOWN_ERROR_OCCURRED_RESPONSE
} from "../../utils/Util";
import {GET_SCOPE_FOR_NODE_OUTPUT} from "../../../tst/components/config/test-data/ConfigTestData";
import {API_PATH, axiosGET} from "../../utils/network/NetworkUtils";
import {AnalyticEventKeys, Constants, MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {UNAUTHORIZED_ERROR_CODE} from "../../../constants/Constants";

export const getScopeForNodeApi = async () => {
    const startTime = Date.now();
    try {
        const response = dev_debug === true
            ? test_response
            : await axiosGET(AnalyticEventKeys.Modules.GET_SCOPE_FOR_NODE, API_PATH.GET_SCOPE_FOR_NODE);
        if (response.status === 200) {
            MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_SCOPE_FOR_NODE_OUTPUT, startTime);
            return response.data
        }
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_SCOPE_FOR_NODE_OUTPUT, startTime, true);
    } catch (error) {
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_SCOPE_FOR_NODE_OUTPUT, startTime, true);
        if (error.message === Constants.ErrorCode.DEVICE_OFFLINE) {
            return NO_INTERNET_RESPONSE
        }
        if (error?.response?.status === UNAUTHORIZED_ERROR_CODE) {
            return UNAUTHORIZED_ERROR_RESPONSE
        }
        return UNKNOWN_ERROR_OCCURRED_RESPONSE
    }
}

const test_response = {
    status: 200,
    data: GET_SCOPE_FOR_NODE_OUTPUT
}
