import {getThinClientUrl} from "../utils/network/BaseUrlUtils";
import {Logger} from "@amzn/dolphin-web-framework";
import {FeatureManager} from "@amzn/dolphin-web-framework";

const messagePromise = {};
let connectionPromise, webSocketConnection = null;
const thinClientBus = new BroadcastChannel('THIN_CLIENT_BUS');

function init() {
    if(!webSocketConnection) {
        Logger.log.warn('Attempting WS connection from Dolphin Stow to Thin Client')
        webSocketConnection = new WebSocket(getThinClientUrl());
    }
    webSocketConnection.onopen = async function () {
        Logger.log.warn('Connected to Thin Client!');
        messagePromise.resolve();
    };
    // Log errors
    webSocketConnection.onerror = function (error) {
        Logger.log.error('WebSocket Error ' + JSON.stringify(error));
        messagePromise.resolve();
        webSocketConnection = null;
    };

    // Log messages from the server
    webSocketConnection.onmessage = function (event) {
        Logger.log.info('Message from Server: ' + event.data);
        try {
            thinClientBus.postMessage(event.data);
        } catch (e) {
            Logger.log.error(e);
        }
    };

    webSocketConnection.onclose = function (event) {
        if(event !== 1000) {
            Logger.log.error('Thin client connection closed with code: ' + event);
        } else {
            Logger.log.error('Thin client connection closed');
        }
        webSocketConnection = null;
        // Set time out is important, it will let existing connection to cool off and then reconnect.
        // Invoking init directly might not reconnect the web socket
        setTimeout(init, 3000);
    };
}

export default {
    getWebSocket() {
        return webSocketConnection;
    },
    async connectWebSocket() {
        Logger.log.warn('Connect to TC Local server via wss!');
        connectionPromise = new Promise((resolve, reject) => {
            messagePromise.resolve = resolve;
            messagePromise.reject = reject
        });
        if(!FeatureManager.isFeatureEnabled(FeatureManager.Features.LIDAR_STOW_ENABLED)) {
            messagePromise.resolve();
        } else {
            //initialise a Web Socket Connection
            Logger.log.warn('Open websocket connection to TC initiated');
            init();
        }
        return connectionPromise;
    },
    closeWebSocketConnection() {
        if (webSocketConnection && webSocketConnection.readyState === WebSocket.OPEN) {
            Logger.log.warn('Close websocket connection to TC initiated');
            webSocketConnection.close();
            thinClientBus.close();
        }
    },
    sendMessage(message) {
        if (webSocketConnection && webSocketConnection.readyState === WebSocket.OPEN) {
            webSocketConnection.send(JSON.stringify(message));
        }
    }
}
