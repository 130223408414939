import * as StowScanLocationTestData
    from "../../../tst/components/stow/view/scan-location/test-data/StowScanLocationTestData";
import * as StowScanPackageTestData
    from "../../../tst/components/stow/view/scan-package/test-data/StowScanPackageTestData";
import {
    chainWalk,
    dev_debug,
    NO_INTERNET_RESPONSE,
    UNAUTHORIZED_ERROR_RESPONSE,
    UNKNOWN_ERROR_OCCURRED_RESPONSE
} from "../../utils/Util";
import {API_PATH, axiosGET, axiosPOST, publishErrorMetric} from "../../utils/network/NetworkUtils";
import {TEST_RESPONSE_200} from "../../../tst/components/stow/flow-mode/view/scan-location-flow/StowScanLocationFlowTestData";
import {
    AnalyticEventKeys,
    Constants,
    FeatureManager,
    DataHandler,
    Logger,
    MobileAnalyticsHelper,
    NativeMeshInteractor
} from "@amzn/dolphin-web-framework";
import {OPERATIONAL_MODE, UNAUTHORIZED_ERROR_CODE} from "../../../constants/Constants";
import {getMetricResponseCode} from "../../../constants/MetricConstants";
import {RESPONSE} from "../../../constants/ResponseCodes";
import {postSblDestinationScan, postSblSourceScan} from "../../utils/StowByLightUtils";
import {SCAN_CONTENT_TYPE} from "../../../constants/ScanContentType";
import ThinClientCommunicator from "../../scanless-communicator/ThinClientCommunicator";
import {WORKFLOW} from "../../workflow/Workflow";

export const getSortInfoForPackage = async (packageScannableId, scope) => {
    const startTime = Date.now();
    const params = {
        params: {
            scannableId: packageScannableId,
            // TODO: Do we need scope ?
            scope: scope
        }
    };

    try {
        const response = dev_debug === true
            ? StowScanPackageTestData.TEST_RESPONSE_200(scope)
            : await axiosGET(AnalyticEventKeys.Modules.GET_SORT_INFO_FOR_PACKAGE, API_PATH.GET_SORT_INFO_FOR_PACKAGE, params);
        const responseCode =  getMetricResponseCode(response.data.responseCode);
        if (response.status === 200) {
            MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_SORT_INFO_FOR_PACKAGE, startTime);
            let locationLabel = null;
            if (response.data.responseCode === RESPONSE.BAG_WEIGHT_LIMIT_REACHED) {
                locationLabel = chainWalk(() => response.data.sortLocations[0].locationInfo.locationLabel, null);
            }
            MobileAnalyticsHelper.processAnalytics(startTime, responseCode, response,
                AnalyticEventKeys.Modules.STOW, response.data.packageTrackingId, locationLabel,
                AnalyticEventKeys.Events.USER_SCANNED_PACKAGE, false, AnalyticEventKeys.Modules.STOW_PACKAGE_SCAN);
            return response.data
        }
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_SORT_INFO_FOR_PACKAGE, startTime, true);
        MobileAnalyticsHelper.processAnalytics(startTime, responseCode, response,
            AnalyticEventKeys.Modules.STOW, response.data.packageTrackingId, null,
            AnalyticEventKeys.Events.USER_SCANNED_PACKAGE, true, AnalyticEventKeys.Modules.STOW_PACKAGE_SCAN);
    } catch (error) {
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_SORT_INFO_FOR_PACKAGE, startTime, true);
        if (error.message === Constants.ErrorCode.DEVICE_OFFLINE) {
            return NO_INTERNET_RESPONSE
        }
        if (error?.response?.status === UNAUTHORIZED_ERROR_CODE) {
            return UNAUTHORIZED_ERROR_RESPONSE
        }
        return UNKNOWN_ERROR_OCCURRED_RESPONSE
    }
}

export const sblSourceScan = (sblEndpoint, packageScannableId) => {
    const startTime = Date.now();
    Logger.log.info("sblSourceScan request"+ packageScannableId);
    try {
        if (FeatureManager.isFeatureEnabled(FeatureManager.Features.PLUG_AND_PLAY_ENABLED)) {
            const data = {
                eventName: 'SourceScan',
                processPath: 'Stow',
                payload: {
                    nodeId: DataHandler.getStationCode(),
                    userId: DataHandler.getUserEmployeeLogin(),
                    scanContentType: 'PACKAGE',
                    scanContent: packageScannableId,
                    eventTime: new Date().getTime()
                }
            };
            ThinClientCommunicator.sendMessage(data);
        } else if (FeatureManager.isFeatureEnabled(FeatureManager.Features.SBL_LOCAL_ENDPOINT_ENABLED)) {
            postSblSourceScan(sblEndpoint, packageScannableId, SCAN_CONTENT_TYPE.PACKAGE);
        } else {
            NativeMeshInteractor.sblSourceScan(packageScannableId, SCAN_CONTENT_TYPE.PACKAGE);
        }
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.SBL_SOURCE_SCAN, startTime);
        return true;
    } catch(error) {
        Logger.log.info("sblSourceScan error response"+JSON.stringify(error));
        publishErrorMetric(AnalyticEventKeys.Modules.SBL_SOURCE_SCAN, startTime, error)
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.SBL_SOURCE_SCAN, startTime, true)
        return false;
    }
};

export const sblDestinationScan = (sblEndpoint, packageScannableId, destinationScannableId) => {
    const startTime = Date.now();
    Logger.log.info("sblDestinationScan request " + destinationScannableId + " " + packageScannableId);
    try {
        if (FeatureManager.isFeatureEnabled(FeatureManager.Features.PLUG_AND_PLAY_ENABLED)) {
            const data = {
                eventName: 'DestinationScan',
                processPath: 'Stow',
                payload: {
                    nodeId: DataHandler.getStationCode(),
                    userId: DataHandler.getUserEmployeeLogin(),
                    scanContentType: 'LOCATION',
                    scanContent: destinationScannableId,
                    containerId: packageScannableId,
                    eventTime: new Date().getTime()
                }
            };
            ThinClientCommunicator.sendMessage(data);
        } else if (FeatureManager.isFeatureEnabled(FeatureManager.Features.SBL_LOCAL_ENDPOINT_ENABLED)) {
            postSblDestinationScan(sblEndpoint, destinationScannableId, packageScannableId, SCAN_CONTENT_TYPE.LOCATION);
        } else {
            NativeMeshInteractor.sblDestinationScan(destinationScannableId, packageScannableId, SCAN_CONTENT_TYPE.LOCATION);
        }
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.SBL_DESTINATION_SCAN, startTime);
        return true;
    } catch(error) {
        Logger.log.info("sblDestinationScan error response"+JSON.stringify(error));
        publishErrorMetric(AnalyticEventKeys.Modules.SBL_DESTINATION_SCAN, startTime, error)
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.SBL_DESTINATION_SCAN, startTime, true)
        return false;
    }
};

export const processStow = async (packageDestinationInfo) => {
    const startTime = Date.now();
    const request = {
        packageScannableId: packageDestinationInfo.packageTrackingId,
        destinationScannableId: packageDestinationInfo.scannedDestination,
        destinationAttributes: {
            correctLocationScanned: packageDestinationInfo.correctLocationScanned
        },
        packageInfo: packageDestinationInfo.packageInfo,
        // TODO: Fix this field, make it more specific, will require backend changes
        getSortInfoForPackageOutput: packageDestinationInfo.getSortInfoForPackageOutput,
        scope: packageDestinationInfo.scope,
        operationalContext: {
            operationalMode: packageDestinationInfo.operationalMode
        },
        // TODO: Do we need this?
    }
    try {
        const response = dev_debug === true
            ? StowScanLocationTestData.TEST_RESPONSE_200()
            : await axiosPOST(AnalyticEventKeys.Modules.PROCESS_STOW, API_PATH.PROCESS_STOW, request);
        const responseCode =  getMetricResponseCode(response.data.responseCode);
        if (response.status === 200) {
                MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.PROCESS_STOW, startTime);
            MobileAnalyticsHelper.processAnalytics(startTime, responseCode, response,
                AnalyticEventKeys.Modules.STOW, packageDestinationInfo.packageTrackingId, response.data.destinationLabel,
                AnalyticEventKeys.Events.USER_SCANNED_PACKAGE, false, AnalyticEventKeys.Modules.PACKAGE_STOW)
                .then(er => {
                    Logger.log.warn("ProcessStow USER_SCANNED_PACKAGE: " + er);
                })
                .catch(ex =>
                    Logger.log.warn("Exception occurred in processStow USER_SCANNED_PACKAGE: " + ex));
            return response.data
        }
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.PROCESS_STOW, startTime, true);
        MobileAnalyticsHelper.processAnalytics(startTime, responseCode, response,
            AnalyticEventKeys.Modules.STOW, packageDestinationInfo.packageTrackingId, response.data.destinationLabel,
            AnalyticEventKeys.Events.USER_SCANNED_PACKAGE, true, AnalyticEventKeys.Modules.PACKAGE_STOW)
            .then(er => {
                Logger.log.warn("ProcessStow USER_SCANNED_PACKAGE: " + er);
            })
            .catch(ex =>
                Logger.log.warn("Exception occurred in processStow USER_SCANNED_PACKAGE: " + ex));
    } catch (error) {
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.PROCESS_STOW, startTime, true);
        MobileAnalyticsHelper.processAnalytics(startTime, "", "",
            AnalyticEventKeys.Modules.STOW, packageDestinationInfo.packageTrackingId, "",
            AnalyticEventKeys.Events.USER_SCANNED_PACKAGE, true, AnalyticEventKeys.Modules.PACKAGE_STOW)
            .then(er => {
                Logger.log.warn("ProcessStow USER_SCANNED_PACKAGE: " + er);
            })
            .catch(ex =>
                Logger.log.warn("Exception occurred in processStow USER_SCANNED_PACKAGE: " + ex));
        if (error.message === Constants.ErrorCode.DEVICE_OFFLINE) {
            return NO_INTERNET_RESPONSE
        }
        if (error?.response?.status === UNAUTHORIZED_ERROR_CODE) {
            return UNAUTHORIZED_ERROR_RESPONSE
        }
        return UNKNOWN_ERROR_OCCURRED_RESPONSE
    }
}

export const getValidateLocationBeforeStow = async (packageScannableId, workflow) => {
    const startTime = Date.now();
    let operationalMode = null ;
    if (workflow === WORKFLOW.STOW_FLOW) {
        operationalMode = OPERATIONAL_MODE.FLOW_STOW_TO_ROUTE;
    }
    const request = {
        scannableId: packageScannableId,
        operationalMode: operationalMode
    };
    try {
        const response = dev_debug === true
            ? TEST_RESPONSE_200()
            : await axiosPOST(AnalyticEventKeys.Modules.VALIDATE_LOCATION_BEFORE_STOW,
                API_PATH.VALIDATE_LOCATION_BEFORE_STOW, request);

        if (response.status === 200) {
            MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.VALIDATE_LOCATION_BEFORE_STOW, startTime);
            return response.data
        }
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.VALIDATE_LOCATION_BEFORE_STOW, startTime, true);
    } catch (error) {
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.VALIDATE_LOCATION_BEFORE_STOW, startTime, true);
        if (error.message === Constants.ErrorCode.DEVICE_OFFLINE) {
            return NO_INTERNET_RESPONSE
        }
        if (error?.response?.status === UNAUTHORIZED_ERROR_CODE) {
            return UNAUTHORIZED_ERROR_RESPONSE
        }
        return UNKNOWN_ERROR_OCCURRED_RESPONSE
    }
}

export const getActiveRoutesForStow = async (sectorScannableId) => {
    const params = {
        params: {
            scannableId: sectorScannableId,
            includeRoutesWithAttachedContainers: false
        }
    };
    try {
        const response = dev_debug === true
            ? TEST_RESPONSE_200()
            : await axiosGET(AnalyticEventKeys.Modules.STOW,
                API_PATH.GET_ACTIVE_ROUTES_FOR_STOW, params);
        if (response.status === 200) {
            return response.data;
        }

    } catch (error) {
        if (error.message === Constants.ErrorCode.DEVICE_OFFLINE) {
            return NO_INTERNET_RESPONSE
        }
        if (error?.response?.status === UNAUTHORIZED_ERROR_CODE) {
            return UNAUTHORIZED_ERROR_RESPONSE
        }

        return UNKNOWN_ERROR_OCCURRED_RESPONSE
    }
}

export const getSectorsForNode = async () => {

    try {
        const response = dev_debug === true
            ? TEST_RESPONSE_200()
            : await axiosGET(AnalyticEventKeys.Modules.STOW,
                API_PATH.GET_SECTORS);
        if (response.status === 200) {
            return response.data;
        }

        return {
            sectorList : []
        }

    } catch (error) {
        if (error.message === Constants.ErrorCode.DEVICE_OFFLINE) {
            return NO_INTERNET_RESPONSE;
        }
        if (error?.response?.status === UNAUTHORIZED_ERROR_CODE) {
            return UNAUTHORIZED_ERROR_RESPONSE;
        }

        return UNKNOWN_ERROR_OCCURRED_RESPONSE;
    }


}

export const generateContainerLabels = async (containerList) => {
    const request = {
        containerList : containerList
    }
    try {
        const response = dev_debug === true
            ? TEST_RESPONSE_200()
            : await axiosPOST(AnalyticEventKeys.Modules.STOW,
                API_PATH.GENERATE_CONTAINER_LABELS, request);
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error.message === Constants.ErrorCode.DEVICE_OFFLINE) {
            return NO_INTERNET_RESPONSE
        }
        if (error?.response?.status === UNAUTHORIZED_ERROR_CODE) {
            return UNAUTHORIZED_ERROR_RESPONSE
        }

        return UNKNOWN_ERROR_OCCURRED_RESPONSE
    }
}
