import React, {Component} from "react";
import {Route, Switch, withRouter} from 'react-router-dom';
import "@amzn/dolphin-uiweb-framework/dist/index.css";
import ManageStowArea from "./manage-stow-area/ManageStowArea";
import {ROUTING} from "../constants/Routing";
import ConfigContextProvider from "./config/context/ConfigContext";
import Landing from "./landing/Landing";
import ProblemSolve from "./problem-solve/ProblemSolve";
import MoveToBuffer from "./move-to-buffer/MoveToBuffer";
import SelectContainer from "./manage-stow-area/select-container/SelectContainer";
import ScrubLocation from "./scrub/scan-location/ScrubLocation";
import ScrubContainer from "./scrub/scan-container/ScrubContainer";
import NotificationContextProvider from "./notification/context/NotificationContext";
import NotificationView from "./notification/view/NotificationView";
import AppNavigation from "./app-navigation/AppNavigation";
import PrepareContainer from "./manage-stow-area/prepare-container/PrepareContainer";
import AppNavigationContextProvider from "./app-navigation/context/AppNavigationContext";
import StowPackage from "./stow/StowPackage";
import CloseContainer from "./manage-stow-area/close-container/CloseContainer";
import ScrubTask from "./scrub/ScrubTask";
import PrepareStowLocation from "./manage-stow-area/prepare-stow-location/PrepareStowLocation";
import PrepareCart from "./manage-stow-area/prepare-cart/PrepareCart";
import CartHotSwap from "./cart-hot-swap/CartHotSwap";
import StowPrinterView from "./stow-conveyable-selection/view/con-package/StowPrinterView";
import StowConveyableSelection from "./stow-conveyable-selection/StowConveyableSelection";
import ReplaceContainer from "./manage-stow-area/replace-container/ReplaceContainer";
import StowProgress from "./stow-progress/StowProgress";
import StowFlowMode from "./stow/flow-mode/StowFlowMode";
import PrepareRoute from "./manage-stow-area/prepare-route/PrepareRoute";
import StowRouteCode from "./stow/route-code/StowRouteCode";
import ScanPrinter from "./stow/route-code/input-route-code/ScanPrinter";
import Column from "@amzn/meridian/column";
import {
    PUMAMetricHelper,
    MeshClient,
    PlayerHelper,
    FeatureManager,
    DataHandler
} from "@amzn/dolphin-web-framework";
import {NoInternetView} from "@amzn/dolphin-uiweb-framework";
import noInternetImage from "../images/no_internet.png";
import Loading from "./loading/Loading";
import PlayerContextProvider from "./player/context/PlayerContext";
import {getRegion, getStage} from "./utils/network/BaseUrlUtils";
import {STOW_PACKAGES, AREA_READINESS, TRAINING_STATUS} from "./utils/Util";
import ThinClientCommunicator from "./scanless-communicator/ThinClientCommunicator";
import StowReadinessTask from "./stow/stow-readiness-task/StowReadinessTask";
import ConfirmCartIssue from "./confirm-cart-issue/ConfirmCartIssue";

function publishRoutePath(location) {
    const metricName = location.pathname;
    PUMAMetricHelper.publishCountToDolphinCSM(metricName);
}

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {loading: true, trainingBlockerStow: false, trainingBlockerArea: false};
    }
    async componentWillMount() {
        await MeshClient.connectWebSocket();
        await FeatureManager.getFeatures(getStage(), getRegion());
        await ThinClientCommunicator.connectWebSocket();
        this.setState({loading: false,
            trainingBlockerStow: TRAINING_STATUS.EXPIRED === DataHandler.getTrainingStatus(STOW_PACKAGES),
            trainingBlockerArea: TRAINING_STATUS.EXPIRED === DataHandler.getTrainingStatus(AREA_READINESS)});
        publishRoutePath(this.props.location);
        this.onRouteSwitch = this.props.history.listen(() => {
            publishRoutePath(this.props.location);
        });
    }
    componentWillUnmount() {
        MeshClient.closeWebSocketConnection();
        ThinClientCommunicator.closeWebSocketConnection();
        PlayerHelper.terminate();
        this.onRouteSwitch();
    }

    render() {
        return (
            <Switch>
                <ConfigContextProvider>
                    <PlayerContextProvider>
                        <AppNavigationContextProvider>
                            <Switch>
                                <Route exact path={ROUTING.NO_INTERNET}>
                                    <NoInternetView
                                        title={"no_internet"}
                                        openSettingsButtonText={"device_setting"}
                                        noInternetImage={noInternetImage}
                                        history={this.props.history}/>
                                </Route>
                            </Switch>
                                <Column height="100%" spacing="none" width="100%">
                                    <AppNavigation history={this.props.history}/>
                                    <NotificationContextProvider>
                                        <NotificationView/>
                                        {this.state.loading ? <Loading/> :
                                            <Column height="100%" spacing="none" heights="fill">
                                                <Route exact path={ROUTING.ENTRY} component={Landing}/>
                                                <Route exact
                                                       path={ROUTING.PREPARE_CONTAINER + "/:containerScannableType"}
                                                       component={PrepareContainer}/>
                                                <Route exact path={ROUTING.MANAGE_STOW_AREA}
                                                       render={(props) =>
                                                           <ManageStowArea {...props} trainingBlockerArea=
                                                               {this.state.trainingBlockerArea} />} />
                                                <Route exact path={ROUTING.SELECT_CONTAINER + "/:stowAreaOperation"}
                                                       component={SelectContainer}/>
                                                <Route exact path={ROUTING.MOVE_TO_BUFFER} component={MoveToBuffer}/>
                                                <Route exact path={ROUTING.PROBLEM_SOLVE + "/:problemSolveReasonCode"}
                                                       component={ProblemSolve}/>
                                                <Route exact path={ROUTING.CLOSE_CONTAINER + "/:containerScannableType"}
                                                       component={CloseContainer}/>
                                                <Route exact path={ROUTING.STOW_PACKAGE}
                                                       render={(props) =>
                                                           <StowPackage {...props} trainingBlockerStow=
                                                               {this.state.trainingBlockerStow} />} />
                                                <Route exact path={ROUTING.STOW_PACKAGE_WITH_PROPS}
                                                       component={StowPackage}/>
                                                <Route exact path={ROUTING.SCAN_PRINTER} component={ScanPrinter}/>
                                                <Route exact path={ROUTING.INPUT_ROUTE} component={StowRouteCode}/>
                                                <Route exact path={ROUTING.SCRUB_SELECT_TASK}
                                                       render={(props) =>
                                                           <ScrubTask {...props} trainingBlockerStow=
                                                               {this.state.trainingBlockerStow} />} />
                                                <Route exact path={ROUTING.SCRUB_CONTAINER} component={ScrubContainer}/>
                                                <Route exact path={ROUTING.SCRUB_LOCATION} component={ScrubLocation}/>
                                                <Route exact path={ROUTING.PREPARE_CART} component={PrepareCart}/>
                                                <Route exact path={ROUTING.CART_HOT_SWAP} component={CartHotSwap}/>
                                                <Route exact path={ROUTING.PREPARE_STOW_LOCATION}
                                                       component={PrepareStowLocation}/>
                                                <Route exact path={ROUTING.STOW_READINESS_TASK} component={StowReadinessTask}/>
                                                <Route exact path={ROUTING.PREPARE_ROUTE} component={PrepareRoute}/>
                                                <Route exact path={ROUTING.STOW_CONVEYABLE_SELECTION}
                                                       component={StowConveyableSelection}/>
                                                <Route exact path={ROUTING.STOW_CON_PACKAGE}
                                                       component={StowPrinterView}/>
                                                <Route exact
                                                       path={ROUTING.REPLACE_CONTAINER + "/:containerScannableType"}
                                                       component={ReplaceContainer}/>
                                                <Route exact path={ROUTING.STOW_PROGRESS} component={StowProgress}/>
                                                <Route exact path={ROUTING.STOW_FLOW_MODE} component={StowFlowMode}/>
                                                <Route exact path={ROUTING.CART_ATTACH_ISSUE_CONFIRM} component={ConfirmCartIssue}/>
                                            </Column>}
                                    </NotificationContextProvider>
                                </Column>
                        </AppNavigationContextProvider>
                    </PlayerContextProvider>
                </ConfigContextProvider>
            </Switch>
        )
    }
}

export default withRouter(App);
