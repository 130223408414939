import {VIEW} from "../../../../workflow/Workflow";
import {RESPONSE} from "../../../../../constants/ResponseCodes";
import {NOTIFICATION_TYPE} from "../../../../../constants/NotificationType";
import {COMMON_STRINGS, NOTIFICATION_STRING} from "../../../../../constants/Strings";

export const getStowRescanPackageData = (input) => {

    if (input.expectedPackageId !== input.packageTrackingId) {
        return {
            view: VIEW.STOW_RESCAN_PACKAGE,
            notification: {
                type: NOTIFICATION_TYPE.ERROR,
                message: NOTIFICATION_STRING.ALERT_ERROR_INCORRECT_PACKAGE_SCANNED,
                values: {displayPackageId: input.displayPackageId}
            }
        }
    }
    switch (input.responseCode) {
        case RESPONSE.UNSUPPORTED_BARCODE:
            return {
                view: VIEW.STOW_LOCATION,
                notification: {
                    type: NOTIFICATION_TYPE.ERROR,
                    message: NOTIFICATION_STRING.ALERT_ERROR_INCORRECT_BARCODE_SCANNED
                }
            }
        case RESPONSE.UNKNOWN_ERROR_OCCURRED:
            return {
                view: VIEW.STOW_LOCATION,
                notification: {
                    type: NOTIFICATION_TYPE.ERROR,
                    message: COMMON_STRINGS.default_error
                }
            }
        case RESPONSE.UNAUTHORIZED:
            return {
                view: VIEW.STOW_LOCATION,
                notification: {
                    type: NOTIFICATION_TYPE.ERROR,
                    message: COMMON_STRINGS.auth_error
                }
            }
        default:
            return {
                view: VIEW.STOW_LOCATION
            }
    }
}
