import React, {useContext, useEffect} from 'react';
import {
    HeaderView, TaskView, GridView
} from "@amzn/dolphin-uiweb-framework";
import {COMMON_STRINGS} from "../../../../constants/Strings";
import Button from "@amzn/meridian/button";
import {injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import {StowPackageContext} from "../../context/StowPackageContext";
import {
    getActiveRoutesForStow, getSectors,
    printContainerLabel
} from "../../action/StowPackageAction";
import {getFormattedString, isNonNull} from "../../../utils/Util";
import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {
    clearBackPressedEventListener,
    interceptBackButton,
    interceptBackButtonStop,
    setBackPressedEventListener
} from "../../../bridge/BackButton";
import {ROUTING} from "../../../../constants/Routing";
import {NotificationContext} from "../../../notification/context/NotificationContext";
import {ConfigContext} from "../../../config/context/ConfigContext";
import {setGlobalContext} from "../../../config/action/ConfigAction";
import {CONTAINER_TYPE} from "../../../../constants/ContainerType";
import {GET_ACTIVE_ROUTES_PAGE, GET_CONTAINER_VIEW} from "../../reducer/StowPackageReducer";

const StowInputRouteCodeView = (props) => {
    const {configViewDispatch} = useContext(ConfigContext);
    const {stowPackageModel, stowPackageDispatch} = useContext(StowPackageContext);
    const {notificationActions: { setNotification, clearNotification}} = useContext(NotificationContext)
    const sectorMap = new Map();

    const headerData = (containerType) => {
        switch(containerType){
            case CONTAINER_TYPE.ROUTE:
                return {
                    primaryTitle: {
                        title: "Routes belonging to sector : " + stowPackageModel.sectorId
                    }
                }
            default:
                return {
                    primaryTitle: {
                        title: "Containers belonging to route : " + stowPackageModel.routeCode
                    }
                }
        }
    }

    const onScan = (input) => {
        getActiveRoutesForStow(sectorMap.get(input), input, stowPackageDispatch, props.history);
    }

    const onBack = () => {
        stowPackageDispatch({
            type: GET_ACTIVE_ROUTES_PAGE,
            data: {}
        });
    }

    async function onSubmit () {
        await printContainerLabel(stowPackageModel.containerList,stowPackageDispatch);
    }

    const onBackPressListener = () => {
        this.props.history.push(ROUTING.STOW_READINESS_TASK);
    };

    const onCancel = () => {
        clearNotification()
        props.history.push(ROUTING.STOW_READINESS_TASK);
    }

    useEffect(() => {
        setBackPressedEventListener(onBackPressListener);
        interceptBackButton("Back Button is now being intercepted");

        return () => {
            NativeMeshInteractor.closeConnectionZebra();
            interceptBackButtonStop();
            clearBackPressedEventListener();
        }
    }, []);

    useEffect( () => {
        if (isNonNull(stowPackageModel.notification)) {
            setNotification(stowPackageModel.notification)
        }
        if (isNonNull(stowPackageModel.nextRoute)) {
            props.history.push(stowPackageModel.nextRoute)
        }

        setGlobalContext(configViewDispatch, stowPackageModel)
    },[stowPackageModel])

    useEffect( () => {

            getSectors(stowPackageDispatch);

    },[])

    function processSector (){

        let dataSnapshots= []

        if(typeof(stowPackageModel.dataSnapshot) !== "undefined")
        {
            let sectors = stowPackageModel.dataSnapshot;
            let sortedSectorsByLabel = sectors.sort((a, b) => {
                if (a.label < b.label) {
                    return -1;
                }
                return 1;
            });

            for(let i=0; i<sortedSectorsByLabel.length; i++) {
                dataSnapshots.push({
                    title : sortedSectorsByLabel[i].label,
                    description: i+1
                })
                sectorMap.set(sortedSectorsByLabel[i].label, sortedSectorsByLabel[i].scannableId);
            }
        }

        return dataSnapshots;

    }

    const getSectorGridProps = {
        title: getFormattedString(props, COMMON_STRINGS.ENTER_SECTOR_ID_TITLE),
        description:  getFormattedString(props, COMMON_STRINGS.SELECT_SECTOR),
        gridTitle: "Sector grid" ,
        dataSnapshots: processSector(),
        onClick: onScan,
        dimension: 3
    }

    const handleClick = (routeId, routeCode) => {
        stowPackageDispatch({type: GET_CONTAINER_VIEW,
            data :{
                routeId: routeId,
                routeCode: routeCode
            }})
    }

    const getTasks = (containerType) => {
        let tasks = []
        switch(containerType) {
            case CONTAINER_TYPE.ROUTE:

                for(let i=0;i<stowPackageModel.routeIds.length;i++){
                    tasks.push({
                        title: stowPackageModel.routeCodes[i],
                        onClick: () => handleClick(stowPackageModel.routeIds[i], stowPackageModel.routeCodes[i])
                    })
                }
                return tasks
            default:
                for(let i=0;i<(stowPackageModel.routeMap.get(stowPackageModel.routeId)).length;i++){
                    tasks.push({
                        title: stowPackageModel.routeMap.get(stowPackageModel.routeId)[i],
                        onClick: async () => {
                            const containerList = [[{
                                    containerId : stowPackageModel.routeMap.get(stowPackageModel.routeId)[i],
                                    routeId: stowPackageModel.routeId
                                }]]
                            ;
                            await printContainerLabel(containerList, stowPackageDispatch);
                        }
                    })
                }
                return tasks
        }

    }

    const renderView = () => {

        if (!(stowPackageModel.hasOwnProperty('correct_input') ? stowPackageModel.correct_input : false))
        {
            return (
            <div className="scrollList">
                <Column spacingInset="medium" alignmentHorizontal="center" height="100%">
                    {<GridView {...getSectorGridProps}/>}
                    {<Button onClick={onCancel} size="large" type="secondary">{getFormattedString(props, COMMON_STRINGS.CANCEL)}</Button>}
                </Column>
            </div>)
        }
        else if(stowPackageModel.helpFlow && !stowPackageModel.containerView)
        {
            return (
                <div className="scrollList">
                    <div>
                        <HeaderView {...headerData(CONTAINER_TYPE.ROUTE)} />
                        <TaskView pageTitle={"Select Route"}
                                  tasks={getTasks(CONTAINER_TYPE.ROUTE)}/>
                    </div>
                    <div>
                        <Row alignmentHorizontal="center">
                            <Button name={"Back"} onClick={onBack} size="large" type="secondary">Back</Button>
                        </Row>
                    </div>
                </div> )
        }
        else if(stowPackageModel.helpFlow && stowPackageModel.containerView)
        {
            return (
                <div className="scrollList">
                    <div>
                        <HeaderView {...headerData("CONTAINER")} />
                        <TaskView pageTitle={"Select Container"}
                                  tasks={getTasks("CONTAINER")}/>
                    </div>
                    <div>
                        <Row alignmentHorizontal="center">
                            <Button name={"Back"} onClick={onBack} size="large" type="secondary">Back</Button>
                        </Row>
                    </div>
                </div> )
        }
        else{
            return (
                <div className="scrollList">
                    <HeaderView {...headerData(CONTAINER_TYPE.ROUTE)} />
                        {stowPackageModel.routeCodes.map(wave => (
                            <Row alignmentHorizontal="center"><li>{wave}</li></Row>
                        ))}
                    <Row alignmentHorizontal="center">
                        <Button name={"Back"} onClick={onBack} size="large" type="secondary">Back</Button>
                        <Button name={"Print"} onClick={onSubmit} size="large" type="primary">Print</Button>
                    </Row>


                </div>)
        }

    }

    return renderView()
}

export default injectIntl(StowInputRouteCodeView)
