import {dev_debug, UNAUTHORIZED_ERROR_RESPONSE, UNKNOWN_ERROR_OCCURRED_RESPONSE} from "../../utils/Util";
import {
    TEST_SCRUB_CONTAINER_RESPONSE_200,
    TEST_VALIDATE_RESPONSE_200
} from "../../../tst/components/scrub/scan-container/test-data/ScrubScanContainerTestData";
import {axiosPOST} from "../../utils/network/NetworkUtils";
import {AnalyticEventKeys, MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {UNAUTHORIZED_ERROR_CODE} from "../../../constants/Constants";


export const scrubContainer = async (containerScannableId, containerType, destinationScannableId) => {
    const startTime = Date.now();
    const request = {
        containerScannableId: containerScannableId,
        containerType: containerType,
        destinationScannableId: destinationScannableId
    }
    try {
        const response = dev_debug === true
            ? TEST_SCRUB_CONTAINER_RESPONSE_200()
            : await axiosPOST(AnalyticEventKeys.Modules.SCRUB_CONTAINER, "nss/scrub/container", request);

        if (response.status === 200) {
            MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.SCRUB_CONTAINER, startTime);
            return response.data
        }
    } catch (error) {
        if (error?.response?.status === UNAUTHORIZED_ERROR_CODE) {
            return UNAUTHORIZED_ERROR_RESPONSE;
        }
        return UNKNOWN_ERROR_OCCURRED_RESPONSE;
    }

    MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.SCRUB_CONTAINER, startTime, true);
}

export const validateContainerBeforeScrub = async (containerScannableId) => {
    const startTime = Date.now();
    const request = {
        containerScannableId: containerScannableId
    }
    try {
        const response = dev_debug === true
            ? TEST_VALIDATE_RESPONSE_200()
            : await axiosPOST(AnalyticEventKeys.Modules.VALIDATE_CONTAINER_BEFORE_SCRUB, "nss/scrub/validateContainer", request);

        if (response.status === 200) {
            MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.VALIDATE_CONTAINER_BEFORE_SCRUB, startTime);
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === UNAUTHORIZED_ERROR_CODE) {
            return UNAUTHORIZED_ERROR_RESPONSE;
        }
        return UNKNOWN_ERROR_OCCURRED_RESPONSE;
    }

    MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.VALIDATE_CONTAINER_BEFORE_SCRUB, startTime, true);
}
