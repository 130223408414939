import axios from "axios";
import {getRegion, getStage} from "./network/BaseUrlUtils";
import {Constants, DataHandler} from "@amzn/dolphin-web-framework";
import {sblInterceptorBuilder} from "../../interceptors/SBLInterceptor";
import ThinClientEvents from "../scanless-communicator/constants/ThinClientEvents";

const SBLAPIClient = axios;
const interceptor = sblInterceptorBuilder(axios);
SBLAPIClient.interceptors.request.use(interceptor.onBeforeRequest, interceptor.onRequestError);
SBLAPIClient.interceptors.response.use(interceptor.onResponseSuccess, interceptor.onResponseError);

export const sblInitialize = () => {
    return getSblThinClientUrl();
};

export const postSblSourceScan = async (endpoint, scanContent, scanContentType) => {
    const sblPayload = {
        scanContent: scanContent,
        scanContentType: scanContentType
    };
    const sblSourceScanRequest = {
        eventType: ThinClientEvents.SCAN_EVENT.SOURCE_SCAN,
        sblPayload: sblPayload,
        nodeId: DataHandler.getStationCode(),
        userId: DataHandler.getUserEmployeeLogin(),
        eventTime: Math.trunc(Date.now() / 1000)
    };
    SBLAPIClient.post(endpoint, sblSourceScanRequest, {headers: {"Content-Type": "application/json"}});
}

export const postSblDestinationScan = async (endpoint, scanContent, containerId, scanContentType) => {
    const sblPayload = {
        scanContent: scanContent,
        containerId: containerId,
        scanContentType: scanContentType
    };
    const sblDestinationScanRequest = {
        eventType: ThinClientEvents.SCAN_EVENT.DESTINATION_SCAN,
        sblPayload: sblPayload,
        nodeId: DataHandler.getStationCode(),
        userId: DataHandler.getUserEmployeeLogin(),
        eventTime: Math.trunc(Date.now() / 1000)
    };
    SBLAPIClient.post(endpoint, sblDestinationScanRequest, {headers: {"Content-Type": "application/json"}});
}

function getSblThinClientUrl() {
    const region = getRegion().toLowerCase();
    const stationCode = DataHandler.getStationCode().toLowerCase();
    switch (getStage().toLowerCase()) {
        case Constants.Stage.BETA:
        case Constants.Stage.GAMMA:
            return `https://sbl-local-${stationCode}.devo.${region}.spider.last-mile.a2z.com:8080/mcgateway`;
        case Constants.Stage.PROD:
            return `https://sbl-local-${stationCode}.prod.${region}.spider.last-mile.a2z.com:8080/mcgateway`;
        // no default
    }
}
