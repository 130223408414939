import React, {Component} from 'react';
import StowPackageView from "./view/StowPackageView";
import StowPackageContextProvider from "./context/StowPackageContext";
import {COMMON_STRINGS} from "../../constants/Strings";
import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {TrainingBlockerView} from "@amzn/dolphin-uiweb-framework";

class StowPackage extends Component {

    constructor(props) {
        super(props);
        this.state = {trainingBlockerStow: props.trainingBlockerStow};
    }

    renderTrainingBlockerView = () => {
        return <TrainingBlockerView
            title={COMMON_STRINGS.TRAINING_BLOCKER_TITLE}
            description={COMMON_STRINGS.TRAINING_BLOCKER_DESC}
            buttonText={COMMON_STRINGS.GOT_IT}
            buttonOnClick={() => NativeMeshInteractor.exitModule()}/>
    }

    render() {

        return this.state.trainingBlockerStow ? this.renderTrainingBlockerView() :
            <div>
                <StowPackageContextProvider>
                    <StowPackageView history={this.props.history}
                                     stowFlow={this.props.match.params.flow}/>
                </StowPackageContextProvider>
            </div>
    }
}

export default StowPackage
