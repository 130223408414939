import React, {createContext, useReducer} from 'react'
import {CloseContainerReducer} from "../reducer/CloseContainerReducer";
import {VIEW} from "../../../workflow/Workflow";

export const CloseContainerContext = createContext();

const model = {
    view: VIEW.CLOSE_SCAN_BAG
}

const CloseContainerContextProvider = (props) => {

    const [closeContainerModel, closeContainerDispatch] = useReducer(CloseContainerReducer, model)
    return (
        <CloseContainerContext.Provider value={{closeContainerModel: closeContainerModel, closeContainerDispatch: closeContainerDispatch}}>
            {props.children}
        </CloseContainerContext.Provider>
    )
}

export default CloseContainerContextProvider
