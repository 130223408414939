import {VIEW} from "../../../../../workflow/Workflow";
import {getNotificationFormat} from "../../../../../utils/Util";
import {NOTIFICATION_TYPE} from "../../../../../../constants/NotificationType";
import {COMMON_STRINGS, NOTIFICATION_STRING} from "../../../../../../constants/Strings";
import {RESPONSE} from "../../../../../../constants/ResponseCodes";
import {ROUTING} from "../../../../../../constants/Routing";


export const getStowScanLocationFlowViewData = (input) => {

    const values = {
        locationLabel: input.locationScannable
    }

    switch (input.responseCode) {
        case RESPONSE.SUCCESS:
            return {
                view: VIEW.STOW_FLOW
            }
        case RESPONSE.INVALID_SCAN_DONE:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_INVALID_LOCATION_SCANNED)
            }
        case RESPONSE.CONTAINER_DOES_NOT_EXIST:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_CONTAINER_NOT_OPEN, values)
            }
        case RESPONSE.BAG_NOT_OPEN:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_WARNING_BAG_NOT_OPEN, values)
            }
        case RESPONSE.CART_NOT_ATTACHED:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_CART_NOT_ATTACHED, values)
            }
        case RESPONSE.NO_INTERNET:
            return {
                nextRoute: ROUTING.NO_INTERNET
            }
        case RESPONSE.UNAUTHORIZED:
            return {
                view: VIEW.STOW_PACKAGE,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    COMMON_STRINGS.auth_error)
            }
        default:
            return {
                view: VIEW.STOW_PACKAGE,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    COMMON_STRINGS.default_error)
            }
    }
}