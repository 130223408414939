import ThinClientEvents from "../constants/ThinClientEvents";
import {Logger} from "@amzn/dolphin-web-framework";
import {AnalyticEventKeys, DataHandler, MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {getAisleRowAndColumnFromLocationLabel, isLiDAREventAllowed} from "../../utils/Util";
import {LiDAREventDescription} from "../../../constants/Constants";

const thinClientBus = new BroadcastChannel('THIN_CLIENT_BUS');

let messageBroker, obstructionMessageBroker;
let obstructingBins = new Set();
let manualScanDisabledDestinationsForPackage = {};


thinClientBus.addEventListener('message', function (event) {
    try {
        const eventData = JSON.parse(event.data);
        if(eventData.type && ThinClientEvents.LIDAR_EVENTS.hasOwnProperty(eventData.type)) {
            handleLidarEvent(eventData);
        } else if (eventData.type && ThinClientEvents.CONFIG.hasOwnProperty(eventData.type)) {
            handleConfigUpdateEvent(eventData);
        } else if (eventData.type && ThinClientEvents.OBSTRUCTION_LIST_UPDATE_EVENT.hasOwnProperty(eventData.type)) {
            handleObstructionListUpdateEvent(eventData);
        }  else {
            Logger.log.warn("Event received is not a valid thin client event : " + JSON.stringify(eventData));
        }
    } catch (JSONParseException) {
        Logger.log.warn("Unable to parse Thin Client event : " + event.data);
    }
});

function handleLidarEvent(data) {
    switch(data.type) {
        case ThinClientEvents.LIDAR_EVENTS.Obstruction:
            obstructingBins.add(data.locationInfo.locationLabel);
            MobileAnalyticsHelper.processAnalytics(Date.now(), AnalyticEventKeys.Modules.PWA, "", AnalyticEventKeys.Modules.STOW,
                data.locationInfo.locationLabel, LiDAREventDescription.RECEIVED, AnalyticEventKeys.Events.LIDAR_OBSTRUCTION);
            break;
        case ThinClientEvents.LIDAR_EVENTS.ClearObstruction:
            obstructingBins.delete(data.locationInfo.locationLabel);
            if (obstructionMessageBroker) {
                obstructionMessageBroker();
            }
            MobileAnalyticsHelper.processAnalytics(Date.now(), AnalyticEventKeys.Modules.PWA, "", AnalyticEventKeys.Modules.STOW,
                data.locationInfo.locationLabel, LiDAREventDescription.CLEAR, AnalyticEventKeys.Events.LIDAR_OBSTRUCTION);
            break;
        case ThinClientEvents.LIDAR_EVENTS.DestinationDetection:
            if (messageBroker && isLiDAREventAllowed() && (!data.userId || data.userId === DataHandler.getUserEmployeeLogin())) {
                messageBroker(data.locationInfo, data.detectionTimestamp, data.missortStatus);
            }
            break;
        default :
            Logger.log.warn("Not a valid Lidar event " + JSON.stringify(data));
            //no-op
    }
}

function handleConfigUpdateEvent(data) {
    switch(data.type) {
        case ThinClientEvents.CONFIG.ManualScanDisabledDestinationsForPackage:
            manualScanDisabledDestinationsForPackage = {
                packageScannableId: data.packageScannableId,
                manualScanDisabledDestinations: data.manualScanDisabledDestinations
            }
            break;
        default :
            Logger.log.warn("No handler present for config update event " + JSON.stringify(data));
    }
}

function handleObstructionListUpdateEvent(data) {
    if (data.obstructionList && Array.isArray(data.obstructionList)) {
        obstructingBins.clear();
        data.obstructionList.forEach((item) => obstructingBins.add(item));
        Logger.log.info("Updated Obstruction list: " + obstructingBins);
    } else {
        Logger.log.warn("ObstructionList is not of valid type : " + JSON.stringify(data));
    }
}

function getObstructingBinListForTesting() {
    return obstructingBins;
}

export default {
    setMessageBroker(broker) {
        messageBroker = broker;
    },
    clearMessageBroker() {
        messageBroker = null;
    },
    setObstructionMessageBroker(broker) {
        obstructionMessageBroker = broker;
    },
    clearObstructionMessageBroker() {
        obstructionMessageBroker = null;
    },
    getObstructingBin(destinationBin) {
        if(destinationBin && destinationBin.length > 0) {
            let bin = getAisleRowAndColumnFromLocationLabel(destinationBin);
            for (let item of obstructingBins.values()) {
                const obstructingBin = getAisleRowAndColumnFromLocationLabel(item);
                if(obstructingBin.aisleLabel === bin.aisleLabel && obstructingBin.columnLabel === bin.columnLabel && obstructingBin.rowLabel >= bin.rowLabel) {
                    return item;
                }
            }
        }
        return null;
    },
    getManualScanDisabledDestinationsForPackage(packageIdentifiers) {
        let manualScanDisabledDestinations = [];
        const configData = manualScanDisabledDestinationsForPackage;
        if (packageIdentifiers.includes(configData.packageScannableId)) {
            manualScanDisabledDestinations = configData.manualScanDisabledDestinations
            Logger.log.info("Found ManualScanDisabledDestinations config: " + manualScanDisabledDestinations + " for PackageScannabledId : " + JSON.stringify(packageIdentifiers));
        } else {
            Logger.log.warn("Not found ManualScanDisabledDestinations config for PackageScannabledId : " + JSON.stringify(packageIdentifiers));
        }
        return manualScanDisabledDestinations;
    }
}

export const exportedForTesting = {
    handleLidarEvent,
    handleConfigUpdateEvent,
    handleObstructionListUpdateEvent,
    getObstructingBinListForTesting
};
