import {RESPONSE} from "../../../../constants/ResponseCodes";
import {getNotificationFormat} from "../../../utils/Util";
import {NOTIFICATION_TYPE} from "../../../../constants/NotificationType";
import {COMMON_STRINGS, NOTIFICATION_STRING} from "../../../../constants/Strings";
import {ROUTING} from "../../../../constants/Routing";

export const getStowInputRouteCodeViewData = (input) => {
    switch (input.responseCode){
        case RESPONSE.SUCCESS:
            return {
                correct_input: true,
                notification: null
            }
        case RESPONSE.UNKNOWN_ERROR_OCCURRED:
            if(input.printEnabled){
                return {
                    notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                        NOTIFICATION_STRING.ALERT_ERROR_COULDNT_FETCH_CONTAINER_LABELS)
                }
            }else {
                return{
                    notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                        NOTIFICATION_STRING.ALERT_ERROR_INVALID_SECTOR_ID)
                }
            }
        case RESPONSE.UNAUTHORIZED:
            return {
                notification: {
                    type: NOTIFICATION_TYPE.ERROR,
                    message: COMMON_STRINGS.auth_error
                }
            }
        case RESPONSE.NO_INTERNET:
            return {
                nextRoute: ROUTING.NO_INTERNET
            }
        case RESPONSE.PARTIAL_SUCCESS:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.WARNING,
                    NOTIFICATION_STRING.ALERT_WARNING_PARTIAL_PRINT)
            }
        case RESPONSE.PRINT_SUCCESS:
            return {
                correct_input: true,
                notification: getNotificationFormat(
                    NOTIFICATION_TYPE.SUCCESS,
                NOTIFICATION_STRING.ALERT_SUCCESS_PRINT_SUCCESSFUL)
            }
        default:
            return {};

    }

}