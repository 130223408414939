import { RedirectHandler} from "@amzn/dolphin-web-framework";
import {getStageExecutionURL} from "../utils/network/BaseUrlUtils";
import {Modules} from "../../constants/Modules";
import {REDIRECT_HASH} from "../../constants/RedirectConstants";

async function stageContainer(props) {

    const invokeStaging = async () => {

        const redirectData = {
        };

        // Get Stage Execution Website Host
        const stageURL = getStageExecutionURL();

        // Get current URL for callback
        const currentURL = new URL(window.location.href);

        // Invoke Stage Module
        RedirectHandler.invokeModule(Modules.STOW, Modules.STAGE, stageURL.host, REDIRECT_HASH.STAGE.SCAN_CONTAINER,
            redirectData, currentURL.host, REDIRECT_HASH.STOW.CLOSE_CONTAINER, stageURL.pathname, currentURL.pathname);
    };

    await invokeStaging();
}

export default stageContainer;