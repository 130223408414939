import {dev_debug, UNAUTHORIZED_ERROR_RESPONSE, UNKNOWN_ERROR_OCCURRED_RESPONSE} from "../../utils/Util";
import {API_PATH, axiosGET} from "../../utils/network/NetworkUtils";
import {TEST_RESPONSE_200} from "../../../tst/components/stow-progress/view/scan-location/test-data/ScanLocationForStowProgressTestData"
import {RESPONSE} from "../../../constants/ResponseCodes";
import {AnalyticEventKeys, MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {UNAUTHORIZED_ERROR_CODE} from "../../../constants/Constants";

export const getRoutesStowProgressForSortLocation = async (scannableId) => {
    const startTime = Date.now();
    const params = {
        params: {
            scannableId: scannableId
        }
    };

    try {
        const response = dev_debug === true
            ? TEST_RESPONSE_200()
            : await axiosGET(AnalyticEventKeys.Modules.GET_STOW_PROGRESS_ROUTES_FOR_SORT_LOCATION, API_PATH.GET_STOW_PROGRESS_ROUTES_FOR_SORT_LOCATION, params);

        if (response.status === 200) {
            MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_ROUTES_STOW_PROGRESS_FOR_SORT_LOCATION, startTime);
            return response.data
        }
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_ROUTES_STOW_PROGRESS_FOR_SORT_LOCATION, startTime, true);
    } catch (error) {
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_ROUTES_STOW_PROGRESS_FOR_SORT_LOCATION, startTime, true);
        if (error?.response?.status === UNAUTHORIZED_ERROR_CODE) {
            return UNAUTHORIZED_ERROR_RESPONSE
        }
        return UNKNOWN_ERROR_OCCURRED_RESPONSE
    }
    return {
        responseCode: RESPONSE.UNKNOWN_ERROR_OCCURRED
    }
}
