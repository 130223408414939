import {RESPONSE} from "../../../../constants/ResponseCodes";
import {
    attachContainerToDestination,
    getRoutesForSortLocation,
    openBag,
    validateBagForOpen,
    validateContainerBeforeAttach
} from "../network/PrepareContainerNetwork";
import {EMPTY_ARRAY, getDisplayContent, isNonNull} from "../../../utils/Util";
import {CONTAINER_TYPE} from "../../../../constants/ContainerType";
import {SCOPE} from "../../../../constants/Scope";

export const getScanBagResource = async (bagLabel, scope) => {
    const response = await validateBagForOpen(bagLabel, scope)
    return {
        responseCode: RESPONSE[response.responseCode],
        bagLabel: bagLabel,
        displayBagId: getDisplayContent(bagLabel, false),
        existingDestinationLabel: response.existingDestinationLabel
    }
}

export const getScanLocationResource = async (bagScannableId, destinationScannableId, scope, workflow) => {
    const response = await openBag(bagScannableId, destinationScannableId, scope, workflow)
    return {
        responseCode: RESPONSE[response.responseCode],
        bagLabel: response.bagLabel,
        displayBagId: getDisplayContent(response.bagLabel, false),
        destinationLabel: response.destinationLabel,
        destinationScannableId: destinationScannableId,
        bagScannableId: bagScannableId
    }
}

export const getValidateContainerBeforeAttachResource = async (request) => {
    const response = await validateContainerBeforeAttach(request)
    switch (request.scope) {
        case SCOPE.AR:
        case SCOPE.AR_NEXT_GEN:
        case SCOPE.SSD:
            switch (request.containerScannableType) {
                case CONTAINER_TYPE.CART:
                    return transformForCartResponse(request, response)
                case CONTAINER_TYPE.BAG:
                default:
                    return transformForBagResponse(request, response)
            }
        default:
            return {
                responseCode: RESPONSE[response.responseCode],
                scope: request.scope,
                containerLabel: response.containerLabel,
                destinationLabel: response.destinationLabel,
                containerScannableType: request.containerScannableType,
                destinationScannableId: request.destinationScannableId,
                activeCartId: request.containerScannableId,
                existingLocationLabel: response.existingLocationLabel
            }
    }
}

export const getAttachContainerToDestinationResource = async (request) => {
    const response = await attachContainerToDestination(request)
    return {
        responseCode: RESPONSE[response.responseCode],
        containerLabel: response.containerLabel,
        destinationLabel: response.destinationLabel,
        containerScannableType: request.containerScannableType,
        destinationScannableId: request.destinationScannableId,
        containerScannableId: request.containerScannableId,
        scope: request.scope,
        workflow: request.workflow,
        existingContainerParentLabel: response.existingContainerParentLabel
    }
}

export const getRoutesForSortLocationResource = async (request) => {
    const response = await getRoutesForSortLocation(request)
    return {
        responseCode: RESPONSE[response.responseCode],
        sortLocationLabel: response.sortLocationLabel,
        activeSortLocationScannableId: request.scannableId,
        routes: transformRoutes(response.routes)
    }
}

const transformForCartResponse = (request, response) => {
    return {
        responseCode: RESPONSE[response.responseCode],
        binList: isNonNull(response.cartAttributes) ? transformBins(response.cartAttributes.bins) : EMPTY_ARRAY,
        activeCartId: request.containerScannableId,
        existingLocationLabel: response.existingLocationLabel,
        scope: request.scope,
        workflow: request.workflow,
        containerScannableType: request.containerScannableType
    }
}

const transformForBagResponse = (request, response) => {
    return {
        responseCode: RESPONSE[response.responseCode],
        existingLocationLabel: response.existingLocationLabel,
        nodeId: response.nodeId,
        activeBagId: request.containerScannableId,
        scope: request.scope,
        workflow: request.workflow
    }
}

const transformBins = (bins) => {
    return bins ? bins.map(bin => ({
        bin: transformBin(bin)
    })) : null
}

const transformBin = (bin) => {
    return {
        binId: bin.binId,
        binLabel: bin.binLabel,
        binScannableId: bin.binScannableId,
        binShelf: bin.binShelf,
        binStatus: bin.binStatus,
        binType: bin.binType
    }
}

const transformRoutes = (routes) => {
    return routes ? routes.map(route => ({
            route: transformRoute(route)
        })) : EMPTY_ARRAY
}

const transformRoute = (route) => {
    return {
        routeId: route.routeId,
        routeCode: route.routeCode,
        targetContainerType: route.targetContainerType
    }
}
