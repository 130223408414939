import {getScopeForNodeApi} from "../network/ConfigNetworkAPI";
import {sblInitialize} from "../../utils/StowByLightUtils";


export const getScopeForNodeResource = async () => {
    const response = await getScopeForNodeApi()
    return {
        scope: response.scope,
        responseCode: response.responseCode
    }
}

export const sblInitializeResource = async () => {
    const response = sblInitialize()
    return {
        sblEndpoint : response
    }
}
