import React, {useContext, useState} from 'react'
import {injectIntl} from "react-intl";
import {StowProgressContext} from "../../context/StowProgressContext";
import RouteDetailCardView from "./RouteDetailCardView";
import Column from "@amzn/meridian/column";
import {COMMON_STRINGS} from "../../../../constants/Strings";
import Text from "@amzn/meridian/text";
import {getFormattedString} from "../../../utils/Util";
import {Colors} from "../../../utils/Colors";
import TrackingIdsDialogView from "./TrackingIdsDialogView";
import {constructViewData, modalData} from "./data/RouteViewData";
import {
    interceptBackButton,
    interceptBackButtonStop
} from "../../../bridge/BackButton";

const RouteForStowProgressView = (props) => {

    const initialState = {
        open: false,
        index: undefined
    }

    const {stowProgressModel} = useContext(StowProgressContext)
    const [trackingIdsDialog, setTrackingIdsDialog] = useState({...initialState})
    const [backgroundColor, setBackgroundColor] = useState(Colors.WHITE_COL)

    const closeTrackingIdsDialog = () => {
        //removing the pushed state of tracking ids dialog view.
        interceptBackButtonStop()
        setTrackingIdsDialog({...initialState})
        setBackgroundColor(Colors.WHITE_COL)
    }

    const handleTrackingIdsDialog = (index) => {
        // if open then close the dialog
        if (trackingIdsDialog.open) {
            closeTrackingIdsDialog()
        } else {
            interceptBackButton('Tracking ids dialog view')
            setTrackingIdsDialog({
                open: true,
                index: index
            })
            setBackgroundColor(Colors.GREY_COL_100)
        }
    }

    const renderView = () => {
        return stowProgressModel.routesStowProgress.map((data, index) =>
            <RouteDetailCardView key={index}
                                 snapshots={constructViewData(props, data.routeStowProgress)}
                                 index={index}
                                 headerFontSize="Large"
                                 handleTrackingIdsDialog={handleTrackingIdsDialog}
                                 backgroundColor= {backgroundColor}/>
        )
    }

    return <div onClick={() => trackingIdsDialog.open && closeTrackingIdsDialog()} style={{height: "100%"}}>
            <Column height="100%" spacingInset="medium" backgroundColor={backgroundColor}>
                <Column>
                    <div className="left-top-medium-spacing">
                        <Text type="h200">{getFormattedString(props,COMMON_STRINGS.STOW_PROGRESS)}</Text>
                    </div>
                </Column>
                <div className="scrollList">
                    {renderView()}
                </div>
            </Column>
            {trackingIdsDialog.open && <TrackingIdsDialogView
                closeTrackingIdsDialog={closeTrackingIdsDialog}
                modalData={modalData(stowProgressModel.routesStowProgress[trackingIdsDialog.index].routeStowProgress)}/>}
        </div>
}

export default injectIntl(RouteForStowProgressView)