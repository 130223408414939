import {CONTAINER_TYPE} from "../../../../../../constants/ContainerType";
import {getFormattedString, getNotificationFormat} from "../../../../../utils/Util";
import {COMMON_STRINGS, INDIA_LANDING_VIEW_STRINGS, NOTIFICATION_STRING} from "../../../../../../constants/Strings";
import {RESPONSE} from "../../../../../../constants/ResponseCodes";
import {NOTIFICATION_TYPE} from "../../../../../../constants/NotificationType";
import {VIEW} from "../../../../../workflow/Workflow";

export const getHeaderData = (props, currentRoute) => {

    let primaryTitle, secondaryTitle
    if (CONTAINER_TYPE.CART === currentRoute.targetContainerType) {
        primaryTitle = getFormattedString(props, INDIA_LANDING_VIEW_STRINGS.scan_cart_primary_title)
        secondaryTitle = getFormattedString(props, INDIA_LANDING_VIEW_STRINGS.scan_empty_cart)

    } else {
        primaryTitle = getFormattedString(props, INDIA_LANDING_VIEW_STRINGS.scan_bag_primary_title)
        secondaryTitle = getFormattedString(props, INDIA_LANDING_VIEW_STRINGS.scan_empty_bag)
    }
    return {
        primaryTitle: {
            title: primaryTitle
        },
        secondaryTitle: {
            title: secondaryTitle,
            type: "Bold"
        }
    }
}

export const getCardData = (props, currentRoute) => {
    const containerTypeValue = CONTAINER_TYPE.CART === currentRoute.targetContainerType
        ? COMMON_STRINGS.cart : COMMON_STRINGS.bag

    return [{
        description: getFormattedString(props, COMMON_STRINGS.route_code),
        title: currentRoute.routeCode
    }, {
        description: getFormattedString(props, COMMON_STRINGS.container_type),
        title: getFormattedString(props, containerTypeValue)
    }]
}

export const getScanContainerViewData = (input) => {

    let message
    const values = {
        containerScannableId: input.containerScannableId,
        routeCode: input.activeRoute.routeCode,
        sortLocationLabel: input.sortLocationLabel,
        scannedBagId: input.containerScannableId,
        scannedCartId: input.containerScannableId,
        existingContainerParentLabel: input.existingContainerParentLabel
    }
    switch (input.responseCode) {
        case RESPONSE.SUCCESS:
            if (input.routeIndexToBeAttached < input.routes.length) {
                message = CONTAINER_TYPE.CART === input.containerScannableType
                    ? NOTIFICATION_STRING.ALERT_SUCCESS_CART_READY_FOR_ROUTE
                    : NOTIFICATION_STRING.ALERT_SUCCESS_BAG_READY_FOR_ROUTE
                return {
                    notification: getNotificationFormat(NOTIFICATION_TYPE.SUCCESS, message, values)
                }
            } else {
                return {
                    view: VIEW.SCAN_CONTAINER_LOCATION,
                    notification: getNotificationFormat(NOTIFICATION_TYPE.SUCCESS,
                        NOTIFICATION_STRING.ALERT_SUCCESS_ROUTE_CONTAINERS_READY_AT_LOCATION, values)
                }
            }
        case RESPONSE.INVALID_SCAN_DONE:
            message = CONTAINER_TYPE.CART === input.containerScannableType
                ? NOTIFICATION_STRING.ALERT_ERROR_INVALID_CART_SCAN
                : NOTIFICATION_STRING.ALERT_ERROR_INVALID_BAG_SCAN
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, message, values)
            }
        case RESPONSE.NON_EMPTY_BAG:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_NON_EMPTY_BAG, {displayBagId: input.containerScannableId})
            }
        case RESPONSE.NON_EMPTY_CART:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_NON_EMPTY_CART, values)
            }
        case RESPONSE.BAG_ALREADY_OPEN:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_NON_BAG_ALREADY_OPEN, values)
            }
        case RESPONSE.CART_ALREADY_ATTACHED:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_CART_ALREADY_ATTACHED, values)
            }
        case RESPONSE.UNAUTHORIZED:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    COMMON_STRINGS.auth_error)
            }
        default:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    COMMON_STRINGS.default_error)
            }

    }
}