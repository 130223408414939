import {CONTAINER_TYPE} from "../../../../constants/ContainerType";
import {getBinIndexToBeAttached} from "../../../utils/BinUtils";
import {isNonNull} from "../../../utils/Util";

/**
 * This method validate whether the container is valid for attachment or not. Currently, it validates only cart and
 * by default all other containers are considered as valid.
 * @param request
 * @param response
 */
export const isContainerValidToAttach = (request, response) => {
    switch (request.containerScannableType) {
        case CONTAINER_TYPE.CART:
            return validateCart(response);
        default:
            return true;
    }
}

const validateCart = (response) => {
    let binList = response.binList;

    if (isNonNull(binList)) {
        let binIndexToBeAttached = getBinIndexToBeAttached(0, binList);
        return binIndexToBeAttached >= binList.length;
    }
    return false;
}