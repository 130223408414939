import React, {useContext, useEffect} from 'react';
import {COMMON_STRINGS, CONFIRM_CART_ISSUE_VIEW_STRINGS} from "../../../constants/Strings";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import {NotificationContext} from "../../notification/context/NotificationContext";
import {FormattedMessage} from "react-intl";
import {ROUTING} from "../../../constants/Routing";
import {blockBackPress, isNonNull, resetBlockedBackPress, SLASH} from "../../utils/Util";
import {CONTAINER_TYPE} from "../../../constants/ContainerType";
import HeadingView, {HEADING_TYPE} from "../../heading-view/HeadingView";
import {AppNavigationContext} from "../../app-navigation/context/AppNavigationContext";
import {PrepareContainerContext} from "../../manage-stow-area/prepare-container/context/PrepareContainerContext";
import {
    CLEAR_CONTEXT,
    VALIDATE_CONTAINER_BEFORE_ATTACH_CONFIRM_CART_ISSUE
} from "../../manage-stow-area/prepare-container/action/PrepareContainerAction";

const ConfirmCartIssueView = (props) => {

    const {prepareContainerModel,prepareContainerDispatch} = useContext(PrepareContainerContext)
    const {notificationActions: {setNotification, clearNotification}} = useContext(NotificationContext);
    const {navActions: {closeBackEnabled, initContext, openBackEnabled, openBackDrop}} = useContext(AppNavigationContext)

    const onClick = () => {
        if (isNonNull(prepareContainerModel.notification)) {
            setNotification(prepareContainerModel.notification)
        }
        // Send scrubContainerBeforeAttach as true to trigger scrub container before attach flow
        prepareContainerModel.scrubContainerBeforeAttach = true;
        const data = prepareContainerModel
        prepareContainerDispatch({
            type: VALIDATE_CONTAINER_BEFORE_ATTACH_CONFIRM_CART_ISSUE,
            data
        })

    }

    const onCancelClick = () => {
        clearNotification()
        props.history.push(ROUTING.PREPARE_CONTAINER + SLASH + CONTAINER_TYPE.CART)
        prepareContainerDispatch({
            type: CLEAR_CONTEXT
        })

    }

    useEffect(() => {
        closeBackEnabled()
        blockBackPress(initContext, closeBackEnabled);
        return () => {
            resetBlockedBackPress(openBackEnabled, initContext, openBackDrop);
        };
    }, []);

    const headerData = {
        primaryTitle: CONFIRM_CART_ISSUE_VIEW_STRINGS.confirm_empty_cart,
        secondaryTitle: CONFIRM_CART_ISSUE_VIEW_STRINGS.check_empty_cart
    }

    return (
        <div>
            <Column height="100%" spacingInset="medium">
                <Column alignmentHorizontal="center">
                    <HeadingView {...headerData} type={HEADING_TYPE.PRIMARY}/>
                </Column>
                <Column className={"footer"} spacingInset={"small"}>
                    <Button onClick={onClick} size="large"
                            type="primary">{<FormattedMessage
                        id={COMMON_STRINGS.CONFIRM}/>}</Button>
                    <Button onClick={onCancelClick} size="large"
                            type="secondary">{<FormattedMessage
                        id={COMMON_STRINGS.CANCEL}/>}</Button>
                </Column>
            </Column>
        </div>
    )
}

export default ConfirmCartIssueView
