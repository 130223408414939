import {getNotificationFormat, SLASH} from "../../../../../utils/Util";
import {RESPONSE} from "../../../../../../constants/ResponseCodes";
import {NOTIFICATION_TYPE} from "../../../../../../constants/NotificationType";
import {COMMON_STRINGS, NOTIFICATION_STRING} from "../../../../../../constants/Strings";
import {ROUTING} from "../../../../../../constants/Routing";
import {PROBLEM_SOLVE} from "../../../../../../constants/ProblemSolveCodes";

export const getStowScanPackageFlowViewData = (input) => {

    const values = {
        locationLabel: input.locationLabel,
        displayPackageId: input.displayPackageId,
        bagLabel: input.locationLabel
    }

    switch (input.responseCode) {
        case RESPONSE.SUCCESS:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.SUCCESS,
                    NOTIFICATION_STRING.ALERT_SUCCESS_PACKAGE_STOWED, values
                )
            }
        case RESPONSE.NO_INTERNET:
            return {
                nextRoute: ROUTING.NO_INTERNET
            }

        case RESPONSE.BAG_WEIGHT_LIMIT_REACHED:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.WARNING,
                    NOTIFICATION_STRING.ALERT_WARNING_BAG_WEIGHT_LIMIT_REACHED, values)
            }

        case RESPONSE.NO_PLAN_PRESENT:
            return {
                nextRoute: ROUTING.MOVE_TO_BUFFER,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_NO_PLAN_PRESENT, values)
            }

        case RESPONSE.INVALID_MOVE:
            return {
                nextRoute: ROUTING.MOVE_TO_BUFFER,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_INCORRECT_LOCATION, values)
            }

        case RESPONSE.INVALID_SCAN_DONE:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_INVALID_PACKAGE_SCANNED, values)
            }
        case RESPONSE.UNSUPPORTED_BARCODE:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_INCORRECT_BARCODE_SCANNED)
            }

        case RESPONSE.INVALID_PACKAGE_STATE:
            return {
                nextRoute: ROUTING.PROBLEM_SOLVE + SLASH + PROBLEM_SOLVE.STOW_FAILURE_INVALID_PACKAGE_STATE,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_PACKAGE_CANNOT_BE_STOWED, values)
            }

        case RESPONSE.STATION_MISMATCH:
            return {
                nextRoute: ROUTING.PROBLEM_SOLVE + SLASH + PROBLEM_SOLVE.WRONG_AISLE_CLUSTER,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_PACKAGE_WRONG_STATION, values)
            }
        case RESPONSE.UNAUTHORIZED:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    COMMON_STRINGS.auth_error)
            }
        default:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    COMMON_STRINGS.default_error)
            }
    }
}