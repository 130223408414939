import {Colors} from "../../utils/Colors";
import {COMMON_ACTIONS} from "../../../constants/ActionType";

export const INIT_CONTEXT = "INIT_CONTEXT"
export const SET_NAVIGATION_ERROR_COL = "SET_NAVIGATION_ERROR_COL"
export const SET_NAVIGATION_WARNING_COL = "SET_NAVIGATION_WARNING_COL"
export const SET_NAVIGATION_TITLE = "SET_NAVIGATION_TITLE"
export const RESET_NAVIGATION_COL = "RESET_NAVIGATION_COL"
export const SET_HELP_OPTION = "SET_HELP_OPTION"
export const OPEN_HELP_OPTION = "OPEN_HELP_OPTION"
export const CLOSE_HELP_OPTION = "CLOSE_HELP_OPTION"
export const OPEN_BACK_DROP = "OPEN_BACK_DROP"
export const CLOSE_BACK_DROP = "CLOSE_BACK_DROP"
export const OPEN_CUSTOM_HELP_OPTION = "OPEN_CUSTOM_HELP_OPTION"
export const CLOSE_CUSTOM_HELP_OPTION = "CLOSE_CUSTOM_HELP_OPTION"
export const CLOSE_BACK_ENABLED = "CLOSE_BACK_ENABLED"
export const OPEN_BACK_ENABLED = "OPEN_BACK_ENABLED"

export const AppNavigationReducer = (state, action) => {
    switch (action.type) {
        case INIT_CONTEXT:
            return {
                ...state,
                ...action.data
            }
        case COMMON_ACTIONS.CLEAR_CONTEXT:
            return {
                ...state,
                onBackPressed: undefined
            }
        case SET_NAVIGATION_ERROR_COL:
            return {...state, navColor: Colors.NAV_ERROR_COL};
        case SET_NAVIGATION_WARNING_COL:
            return {...state, navColor: Colors.NAV_WARN_COL};
        case RESET_NAVIGATION_COL:
            return {...state, navColor: Colors.NAV_COL};
        case SET_NAVIGATION_TITLE:
            return {...state, navTitle: action.data.title};
        case SET_HELP_OPTION:
            return {
                ...state, ...{
                    helpEnabled: action.data.enabled,
                    helpOptions: {...state.helpOptions, ...action.data.options}
                }
            };
        case OPEN_HELP_OPTION:
            return {...state, helpOptions: {...state.helpOptions, open: true}};
        case OPEN_CUSTOM_HELP_OPTION:
            return {
                ...state,
                helpOptions: {...state.helpOptions, open: false},
                customHelpOption: {open: true, type: action.data.type}
            };
        case CLOSE_HELP_OPTION:
            return {...state, helpOptions: {...state.helpOptions, open: false}};
        case OPEN_BACK_DROP:
            return {...state, backDropOpen: true};
        case CLOSE_BACK_DROP:
            return {...state, backDropOpen: false};
        case CLOSE_CUSTOM_HELP_OPTION:
            return {...state, customHelpOption: {...state.customHelpOption, open: false}};
        case CLOSE_BACK_ENABLED:
            return {...state, backEnabled: false};
        case OPEN_BACK_ENABLED:
            return {...state, backEnabled: true};
        default:
            return state;
    }
}
