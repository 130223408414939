import React, {Component} from "react";
import PropTypes from "prop-types";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

class SnapshotWithImageView extends Component {

    getHeaderFontSize = (fontSize) => {
        switch (fontSize) {
            case "Small" :
                return "h200";
            case "Medium" :
                return "h300";
            case "Large" :
                return "h500";
            default :
                return "h200";
        }
    }


    render() {
        const {title, description, headerFontSize, image} = this.props
        const fontSize = this.getHeaderFontSize(headerFontSize)
        return (
            <Row alignmentHorizontal="left" spacingInset="small">
                <div tabIndex="0" align="left">
                    <Text type={fontSize}>{title} <img src={image} alt=""/></Text>
                    <Text type="b200">{description}</Text>
                </div>
            </Row>
        )
    }
}

SnapshotWithImageView.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    headerFontSize: PropTypes.string
}

export default SnapshotWithImageView;