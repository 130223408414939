import {
    ATTACH_CONTAINER_TO_DESTINATION,
    CHANGE_ACTIVE_VIEW,
    CLEAR_CONTEXT,
    GET_ROUTES_FOR_SORT_LOCATION,
    INVALID_LOCATION_SCANNED,
    OPEN_BAG_AFTER_SCRUB_RESPONSE,
    OPEN_BAG_RESPONSE,
    SCRUB_BAG_RESPONSE,
    SCRUB_LOCATION_RESPONSE,
    VALIDATE_BAG_FOR_OPEN_RESPONSE,
    VALIDATE_CONTAINER_BEFORE_ATTACH,
    VALIDATE_CONTAINER_BEFORE_ATTACH_CONFIRM_CART_ISSUE
} from "../action/PrepareContainerAction";
import {model} from "../context/PrepareContainerContext";
import {getScanBagLocationViewData} from "../view/scan-bag-location/data/ScanBagLocationViewData";
import {getScanBagViewData} from "../view/scan-bag/data/ScanBagViewData";
import {CONTAINER_TYPE} from "../../../../constants/ContainerType";
import {getScanCartLocationViewData} from "../view/scan-cart-location/data/ScanCartLocationViewData";
import {getBagScanViewData} from "../../prepare-cart/view/bag-scan/data/BagScanViewData";
import {getBagScanLocationViewData} from "../../prepare-cart/view/bag-scan-location/data/BagScanLocationViewData";
import {getActiveBin, getBinIndexToBeAttached} from "../../../utils/BinUtils";
import {RESPONSE} from "../../../../constants/ResponseCodes";
import {SCOPE} from "../../../../constants/Scope";
import {getScanContainerLocationViewData} from "../../prepare-stow-location/view/scan-container-location/data/ScanContainerLocationViewData";
import {getInvalidLocationScannedData} from "../../prepare-cart/data/ViewData";
import {getScanOvCartViewData} from "../view/scan-cart/data/ScanOvCartViewData";
import {getScanPalletLocationViewData} from "../view/scan-pallet-location/data/ScanPalletLocationViewData";
import {getScanBagCartViewData} from "../view/scan-cart/data/ScanBagCartViewData";
import {chainWalk} from "../../../utils/Util";
import {COMMON_ACTIONS} from "../../../../constants/ActionType";
import {getRoutesContainerLocationViewData} from "../../prepare-route/view/scan-container-location/data/ScanContainerLocationViewData";
import {getActiveRoute} from "../../../utils/RouteUtils";
import {getScanContainerViewData} from "../../prepare-route/view/scan-container/data/ScanContainerViewData";
import {
    getScrubLocationViewData,
    getScrubNonEmptyLocationViewData
} from "../view/scrub-non-empty-location/data/ScrubNonEmptyLocationViewData";
import {getScrubBagViewData} from "../view/scrub-non-empty-bag/data/ScrubNonEmptyBagViewData";

export const PrepareContainerReducer = (state, action) => {
    let inputData
    switch (action.type) {
        case VALIDATE_CONTAINER_BEFORE_ATTACH:
            switch (action.data.containerScannableType) {
                case CONTAINER_TYPE.CART:
                    inputData = constructInputDataForCart(state, action.data)
                    const scanCartViewData = getCartData(inputData)
                    return {
                        ...state,
                        ...scanCartViewData,
                        ...constructCartData(state, action.data),
                        loading: false,
                    }
                case CONTAINER_TYPE.BAG:
                default:
                    const bagScanViewData = getBagScanViewData(action.data)
                    return {
                        ...state,
                        ...bagScanViewData,
                        activeBagId: action.data.activeBagId,
                        loading: false,
                    }
            }
        case VALIDATE_CONTAINER_BEFORE_ATTACH_CONFIRM_CART_ISSUE:
            /* This flow gets triggered when after validate container before attach we receives
            CART_ALREADY_ATTACHED or NON_EMPTY_CART and then a confirm screen is shown in case of
            AMZL stations as well as SCRUB_CONTAINER_BEFORE_ATTACH_ENABLED feature is enabled and
            Associate confirms to move forward to attach cart
            Send response code success to attachContainerToDestination
            when associate confirms that cart is clean to attach */
            if(action.data.scrubContainerBeforeAttach) {
                action.data.responseCode = RESPONSE.SUCCESS;
            }
            inputData = constructInputDataForCart(state, action.data)
            const scanCartViewData = getCartData(inputData)
            return {
                ...state,
                ...constructCartData(state, action.data),
                ...scanCartViewData,
                loading: false,
            }
        case ATTACH_CONTAINER_TO_DESTINATION:
            if (SCOPE.AMZL_IN === action.data.scope) {
                inputData = constructInputDataForScanContainer(state, action.data)
                const scanContainerViewData = getScanContainerViewData(inputData)
                const routeToBeAttached = getRouteToBeAttached(state, action.data)
                return {
                    ...state,
                    ...action.data,
                    ...scanContainerViewData,
                    ...routeToBeAttached,
                    loading: false
                }
            }
            switch (action.data.containerScannableType) {
                case CONTAINER_TYPE.PALLET:
                    const scanPalletLocationViewData = getScanPalletLocationViewData(action.data)
                    return {
                        ...state,
                        ...action.data,
                        ...scanPalletLocationViewData,
                        loading: false
                    }
                case CONTAINER_TYPE.CART:
                    inputData = constructInputDataForCartLocation(state, action.data)
                    const cartScanLocationViewData = getCartLocationData(inputData)
                    return {
                        ...state,
                        ...action.data,
                        ...cartScanLocationViewData,
                        loading: false
                    }
                case CONTAINER_TYPE.BAG:
                default:
                    inputData = constructInputDataForBagLocation(state, action.data)
                    const bagScanLocationViewData = getBagScanLocationViewData(inputData)
                    return {
                        ...state,
                        ...bagScanLocationViewData,
                        ...constructBagData(state, action.data),
                        loading: false
                    }
            }
        case VALIDATE_BAG_FOR_OPEN_RESPONSE:
            const scanBagViewData = getScanBagViewData(action.data)
            return {
                ...state,
                ...action.data,
                ...scanBagViewData,
                loading: false,
            }
        case OPEN_BAG_RESPONSE:
            const scanBagLocationViewData = getScanBagLocationViewData(action.data)
            return {
                ...state,
                ...action.data,
                ...scanBagLocationViewData,
                loading: false
            }
        case OPEN_BAG_AFTER_SCRUB_RESPONSE:
            const scrubNonEmptyLocationViewData = getScrubNonEmptyLocationViewData(action.data)
            return {
                ...state,
                ...action.data,
                ...scrubNonEmptyLocationViewData,
                loading: false
            }
        case SCRUB_LOCATION_RESPONSE:
            const scrubLocationViewData = getScrubLocationViewData(action.data)
            return {
                ...state,
                ...action.data,
                ...scrubLocationViewData
            }
        case SCRUB_BAG_RESPONSE:
            const scrubBagViewData = getScrubBagViewData(action.data)
            return {
                ...state,
                ...action.data,
                ...scrubBagViewData,
                loading: false
            }
        case INVALID_LOCATION_SCANNED:
            const invalidLocationScannedData = getInvalidLocationScannedData(action.data)
            return {
                ...state,
                ...invalidLocationScannedData,
                loading: false
            }
        case COMMON_ACTIONS.LOADING:
            return {
                ...state,
                loading: true,
                notification: null
            }
        case CLEAR_CONTEXT:
            return {...model}
        case CHANGE_ACTIVE_VIEW:
            return {
                ...state,
                view: action.data.view
            }
        case GET_ROUTES_FOR_SORT_LOCATION:
            inputData = constructInputDataForRoutesLocation(state, action.data)
            const routesLocationViewData = getRoutesContainerLocationViewData(inputData)
            return {
                ...state,
                ...inputData,
                ...routesLocationViewData,
                loading: false
            }
        default:
            return state;
    }
}

const getCartData = (inputData) => {
    switch (inputData.scope) {
        case SCOPE.AR:
        case SCOPE.AR_NEXT_GEN:
            return getScanBagCartViewData(inputData)
        case SCOPE.SSD:
        default:
            return getScanOvCartViewData(inputData)
    }
}

const getCartLocationData = (inputData) => {
    switch (inputData.scope) {
        case SCOPE.SSD:
        case SCOPE.AR:
        case SCOPE.AR_NEXT_GEN:
            return getScanContainerLocationViewData(inputData)
        default:
            return getScanCartLocationViewData(inputData)
    }
}

const constructInputDataForBagLocation = (currentState, newState) => {
    const binIndexToBeAttached = getBinIndexToBeAttached(currentState.activeBinIndex + 1, currentState.binList)
    return {
        view: currentState.view,
        responseCode: newState.responseCode,
        containerLabel: newState.containerLabel,
        destinationScannableId: newState.destinationScannableId,
        activeBinIndex: binIndexToBeAttached,
        activeBin: getActiveBin(binIndexToBeAttached, currentState.binList, newState.binList),
        binListSize: currentState.binList.length,
        activeCartId: currentState.activeCartId,
        activeBagId: currentState.activeBagId,
        workflow: newState.workflow,
        scope: newState.scope
    }
}

const constructInputDataForCart = (currentState, newState) => {

    const binIndexToBeAttached = getBinIndexToBeAttached(0, newState.binList)
    return {
        view: currentState.view,
        responseCode: newState.responseCode,
        activeBinIndex: binIndexToBeAttached,
        activeBin: getActiveBin(binIndexToBeAttached, currentState.binList, newState.binList),
        activeCartId: newState.activeCartId,
        existingLocationLabel: newState.existingLocationLabel,
        binListSize: chainWalk(() => newState.binList.length, 0),
        workflow: newState.workflow,
        scope: newState.scope
    }
}

const constructBagData = (state, data) => {

    const newBinIndex = data.responseCode === RESPONSE.SUCCESS ? state.activeBinIndex + 1 : state.activeBinIndex
    const binIndexToBeAttached = getBinIndexToBeAttached(newBinIndex, state.binList)
    return {
        activeBinIndex: binIndexToBeAttached,
        activeBin: getActiveBin(binIndexToBeAttached, state.binList, data.binList),
        ...data
    }
}

const constructCartData = (state, data) => {
    const binIndexToBeAttached = getBinIndexToBeAttached(0, data.binList)
    return {
        activeBinIndex: binIndexToBeAttached,
        activeBin: getActiveBin(binIndexToBeAttached, state.binList, data.binList),
        ...data
    }
}

const constructInputDataForCartLocation = (currentState, newState) => {
    return {
        view: currentState.view,
        responseCode: newState.responseCode,
        activeCartId: currentState.activeCartId,
        destinationScannableId: newState.destinationScannableId,
        workflow: newState.workflow,
        scope: newState.scope,
        destinationLabel: newState.destinationLabel
    }
}

const constructInputDataForRoutesLocation = (currentState, newState) => {
    const activeRouteIndex = 0
    return {
        activeRouteIndex: activeRouteIndex,
        ...newState,
        activeRoute: getActiveRoute(activeRouteIndex, currentState.routes, newState.routes),

    }
}

const constructInputDataForScanContainer = (currentState, newState) => {
    const routeIndexToBeAttached = currentState.activeRouteIndex + 1
    return {
        routeIndexToBeAttached: routeIndexToBeAttached,
        routes: currentState.routes,
        activeRoute: currentState.activeRoute,
        sortLocationLabel: currentState.sortLocationLabel,
        ...newState
    }
}
const getRouteToBeAttached = (currentState, newState) =>  {
    if (RESPONSE.SUCCESS === newState.responseCode) {
        const routeIndexToBeAttached = currentState.activeRouteIndex + 1
        return {
            activeRouteIndex: routeIndexToBeAttached,
            activeRoute: getActiveRoute(routeIndexToBeAttached, currentState.routes, newState.routes),
        }
    }
}
