export default {
    LIDAR_EVENTS : Object.freeze({
        Obstruction : "Obstruction",
        ClearObstruction: "ClearObstruction",
        DestinationDetection: "DestinationDetection"
    }),
    SCAN_EVENT : Object.freeze({
        SOURCE_SCAN: "SourceScan",
        DESTINATION_SCAN: "DestinationScan"
    }),
    CONFIG : Object.freeze({
        ManualScanDisabledDestinationsForPackage : "ManualScanDisabledDestinationsForPackage"
    }),
    OBSTRUCTION_LIST_UPDATE_EVENT: Object.freeze({
        UpdateObstructionRegistry: "UpdateObstructionRegistry"
    })
}
