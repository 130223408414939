import React, {useContext, useEffect} from 'react';
import {DetailsCardView} from "@amzn/dolphin-uiweb-framework";
import {FeatureManager, Logger} from "@amzn/dolphin-web-framework";
import Column from "@amzn/meridian/column";
import {
    getHeaderData,
    getLocationCardViewData,
    getLocationViewType,
    LOCATION_VIEW_TYPE
} from "./data/StowLocationViewData";
import DetailsCardWithShelfImageView from "../../details-card/DetailsCardWithShelfImageView";
import {processStow} from "../../action/StowPackageAction";
import {StowPackageContext} from "../../context/StowPackageContext";
import {
    blockBackPress,
    chainWalk,
    getFormattedString,
    isDestinationScanEventAllowed, isModuleLaunchedFromNative,
    resetBlockedBackPress, getNotificationFormat
} from "../../../utils/Util";
import {ConfigContext} from "../../../config/context/ConfigContext";
import DetailsCardWithCartView from "../../details-card/DetailsCardWithCartView";
import {injectIntl} from "react-intl";
import ScanView from "../../../scan-view/ScanView";
import HeadingView, {HEADING_TYPE} from "../../../heading-view/HeadingView";
import {AppNavigationContext} from "../../../app-navigation/context/AppNavigationContext";
import {setGlobalContext} from "../../../config/action/ConfigAction";
import {WORKFLOW} from "../../../workflow/Workflow";
import {getHelpData} from "./data/StowScanLocationViewData";
import {COMMON_ACTIONS} from "../../../../constants/ActionType";
import {MISSORT_PRESENT, OPERATIONAL_MODE} from "../../../../constants/Constants";
import {SCOPE} from "../../../../constants/Scope";
import {PlayerContext} from "../../../player/context/PlayerContext";
import {ScanTypes} from "../../../player/PlayerConstant";
import QrCode from "../../../../images/ic_qr_code.png";
import Row from "@amzn/meridian/row";
import {AnalyticEventKeys, MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {RESPONSE, RESPONSE as MetricConstants} from "../../../../constants/ResponseCodes";
import LidarInteractor from "../../../scanless-communicator/handler/LidarInteractor";
import {NOTIFICATION_TYPE} from "../../../../constants/NotificationType";
import {NOTIFICATION_STRING} from "../../../../constants/Strings";
import {NotificationContext} from "../../../notification/context/NotificationContext";

const StowScanLocationView = (props) => {

    const {navActions: {setHelpOption, closeHelpOption, openCustomHelpOption, initContext,
        closeBackEnabled, openBackEnabled, openBackDrop}} = useContext(AppNavigationContext)
    const {stowPackageModel, stowPackageDispatch} = useContext(StowPackageContext);
    const {configViewModel, configViewDispatch} = useContext(ConfigContext)
    const {statePlayer, dispatchPlayer} = useContext(PlayerContext);
    const {notificationActions: {setNotification}} = useContext(NotificationContext)

    const liDARLocationDetection = (locationInfo, detectionTimestamp, missortStatus) => {
        Logger.log.info("liDARLocationDetection Location Info : " + JSON.stringify(locationInfo) + " Detection time stamp : " + detectionTimestamp + "missortStatus : " + missortStatus + "for " + stowPackageModel.packageTrackingId);
        if (missortStatus && missortStatus === MISSORT_PRESENT) {
            setNotification(getNotificationFormat(NOTIFICATION_TYPE.WARNING, NOTIFICATION_STRING.ALERT_WARNING_MISSORT_LOCATION_DETECTED, {locationLabel: locationInfo.locationLabel}));
            return;
        }
        if (!detectionTimestamp) {
            detectionTimestamp = Date.now();
        }
        MobileAnalyticsHelper.processAnalytics(detectionTimestamp, MetricConstants[RESPONSE.SUCCESS], "",
            AnalyticEventKeys.Modules.STOW,
            stowPackageModel.packageTrackingId, locationInfo.locationLabel, AnalyticEventKeys.Events.USER_SCANNED_PACKAGE, false, AnalyticEventKeys.Modules.LIDAR);
        onScan(locationInfo.scannableId, false);
    }

    useEffect(() => {
        const setBagFullWorkflow = () => setGlobalContext(configViewDispatch, {workflow: WORKFLOW.BAG_FULL})
        setHelpOption(true, getHelpData(props, configViewModel.scope, closeHelpOption, openCustomHelpOption, setBagFullWorkflow, dispatchPlayer, stowPackageModel))
        if(FeatureManager.isFeatureEnabled(FeatureManager.Features.LIDAR_STOW_ENABLED)) {
            LidarInteractor.setMessageBroker(liDARLocationDetection);
        }
        blockBackPress(initContext, closeBackEnabled);
        return () => {
            setHelpOption(true, {list: []});
            LidarInteractor.clearMessageBroker();
            resetBlockedBackPress(openBackEnabled, initContext, openBackDrop);
        }
    }, []);

    const isCorrectLocationScanned = (scannedText) => {

        switch (configViewModel.scope) {
            case SCOPE.AMZL:
                const sortLocations = stowPackageModel.sortLocations;

                for (const sortLocation of sortLocations) {
                    const scannableId = chainWalk(() => sortLocation.locationInfo.scannableId, null)
                    const bagLabel = chainWalk(() => sortLocation.bagInfo.bagLabel, null)
                    if (scannedText === scannableId || scannedText === bagLabel) {
                        return true
                    }
                }
                return false;
            default:
                return true;
        }
    }

    const isManualScanDisabled = (scannedText) => {
        if (!isModuleLaunchedFromNative()) {
            return false;
        }

        const manualScanDisabledDestinations = LidarInteractor.getManualScanDisabledDestinationsForPackage(stowPackageModel.packageIdentifiers);
        switch (configViewModel.scope) {
            case SCOPE.AMZL:
                const sortLocations = stowPackageModel.sortLocations;
                for (const sortLocation of sortLocations) {
                    const scannableId = chainWalk(() => sortLocation.locationInfo.scannableId, null)
                    const locationLabel = chainWalk(() => sortLocation.locationInfo.locationLabel, null)
                    const bagLabel = chainWalk(() => sortLocation.bagInfo.label, null)
                    if ((scannedText === scannableId || scannedText === bagLabel) && manualScanDisabledDestinations.includes(locationLabel)) {
                        return true;
                    }
                }
                return false;
            default:
                return false;
        }
    }

    const getPackageDestinationInfo = (scannedDestination, operationalMode, sortInfoForPackageOutput) => {
        return {
            packageTrackingId: stowPackageModel.packageTrackingId,
            scannedDestination: scannedDestination,
            correctLocationScanned: isCorrectLocationScanned(scannedDestination),
            packageInfo: stowPackageModel.packageInfo,
            getSortInfoForPackageOutput: sortInfoForPackageOutput,
            operationalMode: operationalMode,
            scope: configViewModel.scope
        }
    }

    const onScan = (input, isManualScan = true) => {
        if (!isDestinationScanEventAllowed()) {
            Logger.log.warn("Throttling processStow requests under 500ms");
            return;
        }
        if (isManualScan && isManualScanDisabled(input)) {
            setNotification({
                type: NOTIFICATION_TYPE.WARNING,
                message: NOTIFICATION_STRING.ALERT_WARNING_MANUAL_SCAN_DISABLED
            });
            Logger.log.warn("Manual scan is disabled for: " + stowPackageModel.sortLocations[0]?.locationInfo?.locationLabel +
                " Scanned text: " + input + " pkg: " + JSON.stringify(stowPackageModel.packageIdentifiers));
            return;
        }
        if(stowPackageModel.loading) {
            Logger.log.info("Rescan while stowing in progress");
            return;
        }
        if (FeatureManager.isFeatureEnabled(FeatureManager.Features.LIDAR_STOW_ENABLED)) {
            if (isManualScan) {
                Logger.log.warn("locationScan(manual) : " + JSON.stringify(stowPackageModel.packageIdentifiers) +
                    " : " + stowPackageModel.sortLocations[0]?.locationInfo?.locationLabel + " : " + input);
            } else {
                Logger.log.info("locationScan(Lidar) : " + stowPackageModel.packageTrackingId);
            }
        }
        stowPackageDispatch({type: COMMON_ACTIONS.LOADING})
        let sortInfoForPackageOutput;
        if (WORKFLOW.STOW_TOPUP === configViewModel.workflow) {
            sortInfoForPackageOutput = getPackageDestinationInfo(input, OPERATIONAL_MODE.TOP_UP_STOW_TO_ROUTE,
                null)
        } else {
            sortInfoForPackageOutput = getPackageDestinationInfo(input, null,
                stowPackageModel.getSortInfoForPackageOutput)
        }
        statePlayer.scanType = ScanTypes.LOCATION_SCAN
        processStow(stowPackageDispatch, sortInfoForPackageOutput, configViewModel.workflow, configViewModel.sblEndpoint)
    }

    const locationViewType = getLocationViewType(stowPackageModel, configViewModel.scope)
    const headerData = getHeaderData(locationViewType, configViewModel.scope, configViewModel.workflow, props.intl)
    const locationData = getLocationCardViewData(locationViewType, stowPackageModel, props)
    const displayShelfImage = {
        isOVPackage: stowPackageModel.isOVPackage,
        rackSize: stowPackageModel.rackSize,
        stowCoordinates: stowPackageModel.stowCoordinates,
        isSidelinePackage: stowPackageModel.isSidelinePackage
    }

    const renderLocationView = () => {
        if (LOCATION_VIEW_TYPE.CORE === locationViewType) {
            return <DetailsCardWithShelfImageView snapshots={locationData} headerFontSize="Large" display={"Row"}
                                                  shelfImageData={displayShelfImage}/>
        }
        if (LOCATION_VIEW_TYPE.AR === locationViewType) {
            return <DetailsCardWithCartView snapshots={locationData} headerFontSize="Large"/>
        }
        if (LOCATION_VIEW_TYPE.SSD === locationViewType) {
            return <DetailsCardView snapshots={locationData} headerFontSize="Large" display={"Column"}/>
        }
        if (LOCATION_VIEW_TYPE.SIDELINE === locationViewType) {
            return <DetailsCardView snapshots={locationData} headerFontSize="Large" display={"Column"}/>
        }
        return <DetailsCardView snapshots={locationData} headerFontSize="Large" display={"Row"}/>
    }

    const renderHeadings = () => {
        return <Column spacingInset="medium" alignmentHorizontal="center">
            <HeadingView {...headerData} type={HEADING_TYPE.PRIMARY}/>
        </Column>
    };

    const renderView = () => {
            if (SCOPE.AMZL_IN === configViewModel.scope && stowPackageModel.topUpPackage === true) {
                return <Column>
                    {renderHeadings()}
                    <Column spacingInset="medium" alignmentHorizontal="center">
                        <Row spacing={"none"} width="50%">
                            <img src={QrCode} alt="Scan route container" height="fit-content" style={{width: '100%'}}/>
                        </Row>
                    </Column>
                </Column>
            }
            return <div>
                {renderHeadings()}
                <Column spacingInset="medium">
                    {renderLocationView()}
                </Column>
            </div>
    };

    return (
        <div>
            {renderView()}
            <ScanView onScan={onScan} ariaLabel={getFormattedString(props, headerData.primaryTitle)}/>
        </div>
    )
}

export default injectIntl(StowScanLocationView)
