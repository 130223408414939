import {RESPONSE} from "./ResponseCodes";

const METRIC_CONSTANTS = {
    [RESPONSE.SUCCESS]: "SUCCESSFUL",
    [RESPONSE.NO_PLAN_PRESENT] : "NO_PLAN_PRESENT",
    [RESPONSE.PACKAGE_ALREADY_STOWED]: "PACKAGE_ALREADY_STOWED",
    [RESPONSE.BAG_WEIGHT_LIMIT_REACHED]: "BAG_WEIGHT_LIMIT_REACHED",
    [RESPONSE.STATION_MISMATCH]: "PACKAGE_AT_WRONG_STATION",
    [RESPONSE.INVALID_PACKAGE_STATE]: "INVALID_PACKAGE_STATE",
    [RESPONSE.INVALID_SCAN_DONE]: "INVALID_SCAN_DONE",
    [RESPONSE.UNSUPPORTED_BARCODE]: "UNSUPPORTED_BARCODE",
    [RESPONSE.BAG_NOT_OPEN]: "BAG_NOT_OPEN",
    [RESPONSE.CART_NOT_ATTACHED]: "CART_NOT_ATTACHED",
    [RESPONSE.CONTAINER_DOES_NOT_EXIST]: "CONTAINER_DOES_NOT_EXIST",
    [RESPONSE.UNKNOWN_ERROR_OCCURRED]: "ERROR",
    [RESPONSE.NO_DESTINATION_FOUND]: "NO_DESTINATION_FOUND",
    [RESPONSE.BAG_STATION_MISMATCH]: "BAG_STATION_MISMATCH",
    [RESPONSE.NO_BAG_FOUND]: "NO_BAG_FOUND"

};

export function getMetricResponseCode(responseCode) {
    return METRIC_CONSTANTS[responseCode] ? METRIC_CONSTANTS[responseCode] : responseCode;
}