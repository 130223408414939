
export const CONSTANT = {
    CON_PACKAGE: "CON_PACKAGE",
    NON_CON_PACKAGE: "NON_CON_PACKAGE",
    TOPUP_PACKAGE: "TOPUP_PACKAGE"
}

export const OPERATIONAL_MODE = {
    TOP_UP_STOW_TO_ROUTE: "TOP_UP_STOW_TO_ROUTE",
    FLOW_STOW_TO_ROUTE: "FLOW_STOW_TO_ROUTE"
}

export const NotificationType = {
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error"
}

export const LiDAREventDescription = {
    RECEIVED: "Received",
    CLEAR: "Clear"
}

export const NO_OP = "NO_OP";
export const UNAUTHORIZED_ERROR_CODE = 401;
export const POLLER_TIMER = 20000;
export const OV = "OV";
export const ENDCP_IDENTIFIER = "END";
export const COMMA = ", ";
export const MISSORT_PRESENT = "PRESENT";
