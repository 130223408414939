import {chainWalk, EMPTY, getFormattedString, isOVPackage} from "../../../../utils/Util";
import {COMMON_STRINGS, SCAN_LOCATION_VIEW_STRINGS} from "../../../../../constants/Strings";
import {CYCLE_TYPE} from "../../../../../constants/CycleType";
import {SCOPE} from "../../../../../constants/Scope";
import {CONTAINER_TYPE} from "../../../../../constants/ContainerType";
import {WORKFLOW} from "../../../../workflow/Workflow";
import {OV} from "../../../../../constants/Constants";
import {DOMAIN} from "../../../../../constants/Domain";

export const LOCATION_VIEW_TYPE = {
    CORE: "CORE",
    DRS: "DRS",
    STACKING_AREA: "STACKING_AREA",
    AR: "AR",
    SSD: "SSD",
    SIDELINE: "SIDELINE"
}

export const getHeaderData = (locationViewType, scope, workflow) => {
    if (SCOPE.AMZL_IN === scope && WORKFLOW.STOW_TOPUP === workflow) {
        return {
            primaryTitle: SCAN_LOCATION_VIEW_STRINGS.scan_route_container,
            secondaryTitle: SCAN_LOCATION_VIEW_STRINGS.scan_route_container_desc
        }
    }
    return {
        primaryTitle: SCAN_LOCATION_VIEW_STRINGS.scan_location,
        secondaryTitle: LOCATION_VIEW_TYPE.STACKING_AREA === locationViewType ? "Place the package in container" :
            SCAN_LOCATION_VIEW_STRINGS.scan_location_guidance
    }
}

const getRack = (stowPackageViewModel, props) => {
    if (isOVPackage(stowPackageViewModel)) {
        return {
            description: getFormattedString(props, COMMON_STRINGS.ov_rack),
            title: OV
        }
    }
    return {
        description: getFormattedString(props, COMMON_STRINGS.shelf),
        title: stowPackageViewModel.rack
    }
};

export const getLocationCardViewData = (locationViewType, stowPackageViewModel, props) => {
    const sortLocation = stowPackageViewModel.targetSortLocation
    switch (locationViewType) {
        case LOCATION_VIEW_TYPE.CORE:
            return [{
                description: getFormattedString(props, COMMON_STRINGS.aisle),
                title: stowPackageViewModel.aisle
            },
            getRack(stowPackageViewModel, props)
            ]
        case LOCATION_VIEW_TYPE.DRS:
            return [{
                description: getFormattedString(props, COMMON_STRINGS.route_code),
                title: stowPackageViewModel.aisle
            }]
        case LOCATION_VIEW_TYPE.STACKING_AREA:
            return [{
                description: COMMON_STRINGS.stacking_area,
                title: stowPackageViewModel.packageLocationLabel
            }]
        case LOCATION_VIEW_TYPE.AR:
            return [{
                description: getFormattedString(props, COMMON_STRINGS.location),
                title: sortLocation.floorInfo ? sortLocation.floorInfo.label : EMPTY
            }, {
                description: getFormattedString(props, COMMON_STRINGS.shelf),
                title: stowPackageViewModel.rack
            }, {
                description: getFormattedString(props, COMMON_STRINGS.cart),
                title: sortLocation.cartInfo ? sortLocation.cartInfo.label : EMPTY
            }
            ]
        case LOCATION_VIEW_TYPE.SSD:
            return [{
                description: getFormattedString(props, COMMON_STRINGS.cart),
                title: sortLocation.cartInfo ? sortLocation.cartInfo.label : EMPTY
            }, {
                description: getFormattedString(props, COMMON_STRINGS.location),
                title: sortLocation.floorInfo ? sortLocation.floorInfo.label : EMPTY
            }
            ]
        case LOCATION_VIEW_TYPE.SIDELINE:
                return [{
                    description: getFormattedString(props, COMMON_STRINGS.cart),
                    title: sortLocation.cartInfo ? sortLocation.cartInfo.label : EMPTY
                }, {
                    description: getFormattedString(props, COMMON_STRINGS.location),
                    title: sortLocation.locationInfo ? sortLocation.locationInfo.locationLabel : EMPTY
                }
                ]
        default:
            return
    }
}

export const getLocationViewType = (stowPackageViewModel, scope) => {
    switch (scope) {
        case SCOPE.AR:
        case SCOPE.AR_NEXT_GEN:
            return LOCATION_VIEW_TYPE.AR
        case SCOPE.SSD:
            return LOCATION_VIEW_TYPE.SSD
        default:
            return getLocationViewTypeByProperty(stowPackageViewModel)
    }
}

const getLocationViewTypeByProperty = (stowPackageViewModel) => {

    const locationType = chainWalk(() => stowPackageViewModel.targetSortLocation.locationInfo.locationType, null)
    if (LOCATION_VIEW_TYPE.STACKING_AREA === locationType) {
        return LOCATION_VIEW_TYPE.STACKING_AREA
    } else if (DOMAIN.SIDELINE === chainWalk(() => stowPackageViewModel.packageInfo.packageAttributes.domain, null)) {
        return LOCATION_VIEW_TYPE.SIDELINE
    } else if (CYCLE_TYPE.DRS === chainWalk(() => stowPackageViewModel.packageInfo.packageAttributes.cycleType, null)) {
        return LOCATION_VIEW_TYPE.DRS
    } else if (CONTAINER_TYPE.ROUTE === locationType){
        return LOCATION_VIEW_TYPE.DRS
    } else {
        return LOCATION_VIEW_TYPE.CORE
    }
}
