import {RESPONSE} from "../../../../../../constants/ResponseCodes";
import {getNotificationFormat, isAttachCartWorkflow} from "../../../../../utils/Util";
import {VIEW} from "../../../../../workflow/Workflow";
import {NOTIFICATION_TYPE} from "../../../../../../constants/NotificationType";
import {COMMON_STRINGS, NOTIFICATION_STRING} from "../../../../../../constants/Strings";
import AppDataStore, {APP_DATA_KEYS} from "../../../../../app-data";
import CartStageReadinessPoller from "../../../../../../util/CartStageReadinessPoller";
import {RedirectHandler} from "@amzn/dolphin-web-framework";

export const getScanContainerLocationViewData = (input) => {
    const values = {
        activeCartId: input.activeCartId,
        destinationLabel: input.destinationLabel
    }
    switch (input.responseCode) {

        case RESPONSE.SUCCESS:
            let view;
            if (isAttachCartWorkflow(input.workflow)) {
                /*
                 If the hotswap flow is in progress and we have successfully attached an empty cart to the stow location then
                 the ready cart should be removed from the poller cartList as well as from the session storage so that hotswap
                 flow doesn't get trigger for it again and assigning next screen to be package scan screen to complete the
                 hotswap workflow.
                 */
                if (AppDataStore.isHotSwapInProgress() && AppDataStore.getCurrentCartInfo()) {
                    CartStageReadinessPoller.removeCart(AppDataStore.getCurrentCartInfo());
                    AppDataStore.removeCurrentCartInfo(APP_DATA_KEYS.CURRENT_CART_INFO);
                    view = VIEW.STOW_PACKAGE;
                } else if (AppDataStore.isAttachEmptyCartRedirectionInProgress()) {
                    AppDataStore.completeAttachEmptyCartRedirection();
                    return RedirectHandler.callback({});
                } else {
                    view = VIEW.SCAN_CART
                }
            } else {
                view = VIEW.STOW_PACKAGE;
            }
            return {
                view: view,
                notification: getNotificationFormat(NOTIFICATION_TYPE.SUCCESS,
                    NOTIFICATION_STRING.ALERT_SUCCESS_CART_READY_AT_LOCATION, values)
            }
        case RESPONSE.INVALID_MOVE:
        case RESPONSE.INVALID_SCAN_DONE:
            return {
                view: VIEW.SCAN_CART,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_INVALID_LOCATION_SCANNED)
            }

        case RESPONSE.NON_EMPTY_LOCATION:
            return {
                view: VIEW.SCAN_CART,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_CART_IS_ACTIVE_AT_LOCATION, values)
            }

        case RESPONSE.UNAUTHORIZED:
            return {
                view: VIEW.SCAN_CART,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    COMMON_STRINGS.auth_error)
            }

        default:
            return {
                view: VIEW.SCAN_CART,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    COMMON_STRINGS.default_error)
            }
    }
}