import React, {Component} from 'react';
import ConfirmCartIssueView from "./view/ConfirmCartIssueView";

class ConfirmCartIssue extends Component {

    render() {
        return (
            <div>
                <ConfirmCartIssueView history={this.props.history}/>
            </div>
        )
    }
}

export default ConfirmCartIssue
