import {SCOPE} from "../../../../../constants/Scope";
import {chainWalk, EMPTY, HASHTAG, HEX_CODE_LENGTH, isNonNull, SLASH} from "../../../../utils/Util";
import {ENDCP_IDENTIFIER, COMMA} from  "../../../../../constants/Constants";
import {HELP_CENTER_STRINGS} from "../../../../../constants/Strings";
import {ApplicationActions} from "../../../../player/action/ApplicationActions";
import {CUSTOM_HELP_OPTIONS} from "../../../../app-navigation/AppNavigation";
import {ROUTING} from "../../../../../constants/Routing";
import {PROBLEM_SOLVE} from "../../../../../constants/ProblemSolveCodes";

export const getRescanCardViewData = (scope, stowPackageModel) => {

    let defaultWrongLocationScannedTitle = isNonNull(stowPackageModel.destinationLabel) ? stowPackageModel.destinationLabel : EMPTY
    let defaultCorrectLocationScannedTitle = stowPackageModel.packageLocationLabel
    let salColor = validateSalColor(chainWalk(() => stowPackageModel.getSortInfoForPackageOutput.salColor, EMPTY))
    switch (scope) {
        case SCOPE.AR:
        case SCOPE.AR_NEXT_GEN:
            return {
                wrongLocationScannedTitle: defaultWrongLocationScannedTitle,
                correctLocationScannedTitle: isNonNull(stowPackageModel.floorLabel) ? defaultCorrectLocationScannedTitle : EMPTY,
                salColor: salColor
            }
        case SCOPE.SSD:
            return {
                wrongLocationScannedTitle: defaultWrongLocationScannedTitle,
                correctLocationScannedTitle: isNonNull(stowPackageModel.targetSortLocation.cartInfo)
                && isNonNull(stowPackageModel.targetSortLocation.cartInfo.label)
                    ? stowPackageModel.targetSortLocation.cartInfo.label : EMPTY,
                salColor: salColor
            }
        default:
            return {
                wrongLocationScannedTitle: stowPackageModel.incorrectDestinationScanned,
                correctLocationScannedTitle: defaultCorrectLocationScannedTitle,
                salColor: salColor
            }

    }
}

const validateSalColor = (salColor) => {
    if (salColor.startsWith(HASHTAG) && salColor.length === HEX_CODE_LENGTH) {
        return salColor;
    }
    return null;
}

export const getHelpData= (props, dispatchPlayer, closeHelpOption, openCustomHelpOption) => {
    return {
        pageTitle: HELP_CENTER_STRINGS.STOW_PROBLEMS_HELP,
        list: [
            {
                title: props.intl.formatMessage({id: HELP_CENTER_STRINGS.MARK_FOR_PROBLEM_SOLVE}),
                onClick: () => {
                    dispatchPlayer({type: ApplicationActions.HELP_OPTION_SELECTED, data: {operation: HELP_CENTER_STRINGS.MARK_FOR_PROBLEM_SOLVE}});
                    closeHelpOption()
                    openCustomHelpOption(CUSTOM_HELP_OPTIONS.PROBLEM_SOLVE)
                }
            },
            {
                title: props.intl.formatMessage({id: HELP_CENTER_STRINGS.SAL_COLOR_MISMATCH_TEXT}),
                onClick: () => {
                    props.history.push(ROUTING.PROBLEM_SOLVE + SLASH + PROBLEM_SOLVE.SAL_COLOR_MISMATCH);
                    closeHelpOption();
                }
            }
        ]
    }
}

export const getAisle = (stowPackageModel) => {
    if (isNonNull(stowPackageModel.targetSortLocation) && isNonNull(stowPackageModel.targetSortLocation.locationInfo)) {
        return stowPackageModel.targetSortLocation.locationInfo.aisle;
    }
    return EMPTY;
};

export const getRacks = (stowPackageModel) => {
    let racks = EMPTY;
    if (isNonNull(stowPackageModel.sortLocations)) {
        for (const location of stowPackageModel.sortLocations) {
            if (location.locationInfo && !location.locationInfo.rack.startsWith(ENDCP_IDENTIFIER)) {
                racks = racks.concat(location.locationInfo.rack.toString() + COMMA);
            }
        }
    }

    return racks.substring(0, racks.length-2)
};
