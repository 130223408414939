import React, {useContext, useEffect} from 'react';
import {ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import QrCode from "../../../../../images/ic_qr_code.png";
import {
    HELP_CENTER_STRINGS,
    MANAGE_STOW_AREA_VIEW_STRINGS,
    SCAN_BAG_VIEW_STRINGS
} from "../../../../../constants/Strings";
import {PrepareContainerContext} from "../../context/PrepareContainerContext";
import {ConfigContext} from "../../../../config/context/ConfigContext";
import {FormattedMessage} from "react-intl";
import {WORKFLOW} from "../../../../workflow/Workflow";
import ScanWithLocationView from "./sub/ScanWithLocationView";
import {validateBagForOpen} from "../../action/PrepareContainerAction";
import {getFormattedString, getHelpOptions, getSelectContainerRoutingPath} from "../../../../utils/Util";
import {STOW_AREA_OPERATION} from "../../../../../constants/StowAreaOperation";
import {AppNavigationContext} from "../../../../app-navigation/context/AppNavigationContext";
import Column from "@amzn/meridian/column";
import ScanView from "../../../../scan-view/ScanView";
import {COMMON_ACTIONS} from "../../../../../constants/ActionType";
import {NotificationContext} from "../../../../notification/context/NotificationContext";
import {PlayerContext} from "../../../../player/context/PlayerContext";
import {ScanTypes} from "../../../../player/PlayerConstant";
import {ApplicationActions} from "../../../../player/action/ApplicationActions";
import Loading from "../../../../loading/Loading";
import {injectIntl} from "react-intl";

const ScanBagView = (props) => {

    const {configViewModel} = useContext(ConfigContext)
    const {prepareContainerModel, prepareContainerDispatch} = useContext(PrepareContainerContext)
    const {navActions: {setHelpOption, closeHelpOption, openBackEnabled, setNavTitle}} = useContext(AppNavigationContext)
    const {notificationActions: {clearNotification}} = useContext(NotificationContext);
    const {statePlayer, dispatchPlayer} = useContext(PlayerContext);

    const onScan = (input) => {
        prepareContainerModel.notification = null;
        clearNotification();
        prepareContainerDispatch({type: COMMON_ACTIONS.LOADING})
        statePlayer.scanType = ScanTypes.PREP_BAG_SCAN
        validateBagForOpen(prepareContainerDispatch, input, configViewModel.scope)
    }

    const init = {
        primaryTitle: {
            title: getFormattedString(props, SCAN_BAG_VIEW_STRINGS.scan_empty_bag),
            type: "Bold",
            size: "Large"
        },
        image: QrCode
    }

    const renderView = () => {
        if (WORKFLOW.OPEN_BAG_AT_PACKAGE_SCAN === configViewModel.workflow) {
            return <ScanWithLocationView view={prepareContainerModel.view}
                                         location={configViewModel.locationLabel}/>
        }
        return <Column spacingInset="medium" alignmentHorizontal="center">
            <ScanWithCodeView {...init}/>
        </Column>
    }

    useEffect(() => {
        openBackEnabled()
    }, []);



    useEffect(() => {
        setNavTitle(<FormattedMessage id={MANAGE_STOW_AREA_VIEW_STRINGS.prepare_container}/>);
        const callbacks = [
            () => {
                dispatchPlayer({type: ApplicationActions.HELP_OPTION_SELECTED, data: {operation: HELP_CENTER_STRINGS.PREPARE_CONTAINER_HELP}});
                props.history.push(getSelectContainerRoutingPath(STOW_AREA_OPERATION.PREPARE_CONTAINER))
            },
            closeHelpOption
        ]
        setHelpOption(true, getHelpOptions(callbacks,
            <FormattedMessage id={HELP_CENTER_STRINGS.PREPARE_CONTAINER_HELP}/>,
            <FormattedMessage id={HELP_CENTER_STRINGS.PREPARE_CONTAINER_HELP_TEXT}/>));
        return () => setHelpOption(false, {});
    }, []);

    return (
        <div>
            {prepareContainerModel.loading && <Loading/>}
            {renderView()}
            <ScanView onScan={onScan}/>
        </div>
    )
}

export default injectIntl(ScanBagView)
