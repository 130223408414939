import {RESPONSE} from "../../../../../../constants/ResponseCodes";
import {NOTIFICATION_TYPE} from "../../../../../../constants/NotificationType";
import {COMMON_STRINGS, NOTIFICATION_STRING} from "../../../../../../constants/Strings";
import {ROUTING} from "../../../../../../constants/Routing";
import {getDisplayContent, getNotificationFormat, SLASH} from "../../../../../utils/Util";
import {WORKFLOW} from "../../../../../workflow/Workflow";
import {CONTAINER_TYPE} from "../../../../../../constants/ContainerType";

export const getCloseScanBagLocationViewData = (notificationInput) => {
    switch (notificationInput.responseCode) {
        case RESPONSE.SUCCESS:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.SUCCESS,
                    // Todo: Fix this
                    NOTIFICATION_STRING.ALERT_SUCCESS_BAG_MOVED_TO_OV_OPEN_NEW_BAG,
                    {
                        destinationLabel: notificationInput.destinationLabel,
                        bagLabel: getDisplayContent(notificationInput.bagLabel, false)
                    }
                ),
                workflow: WORKFLOW.OPEN_BAG_IN_SAME_LOCATION,
                nextRoute: ROUTING.PREPARE_CONTAINER + SLASH + CONTAINER_TYPE.BAG
            }
        case RESPONSE.OV_AREA_MISMATCH:
        case RESPONSE.INVALID_MOVE:
        case RESPONSE.NO_PLAN_PRESENT:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_INCORRECT_OV_SCANNED,
                    {destinationLabel: notificationInput.destinationLabel})
            }
        case RESPONSE.INVALID_SCAN_DONE:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_INVALID_SCAN)
            }
        case RESPONSE.NO_BAG_FOUND:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_NO_BAG_FOUND)
            }
        case RESPONSE.BAG_STATION_MISMATCH:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_BAG_STATION_MISMATCH)
            }
        case RESPONSE.UNAUTHORIZED:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.auth_error)
            }
        default:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.default_error)
            }
    }
}
