import React, {Component} from 'react';
import StowPackageContextProvider from "../context/StowPackageContext";
import StowReadinessTaskView from "../stow-readiness-task/StowReadinessTaskView"

class StowReadinessTask extends Component {

    render() {

        return (
            <div>
                <StowPackageContextProvider>
                    <StowReadinessTaskView history={this.props.history}/>
                </StowPackageContextProvider>
            </div>
        )
    }
}

export default StowReadinessTask
