import {RESPONSE} from "../../../../../constants/ResponseCodes";
import {VIEW, WORKFLOW} from "../../../../workflow/Workflow";
import {ROUTING} from "../../../../../constants/Routing";
import {PROBLEM_SOLVE} from "../../../../../constants/ProblemSolveCodes";
import {NOTIFICATION_TYPE} from "../../../../../constants/NotificationType";
import {
    COMMON_STRINGS,
    HELP_CENTER_STRINGS,
    NOTIFICATION_STRING,
    SCAN_PACKAGE_VIEW_STRINGS
} from "../../../../../constants/Strings";
import {chainWalk, getFormattedString, getSelectContainerRoutingPath, isArScope, SLASH} from "../../../../utils/Util";
import {STOW_AREA_OPERATION} from "../../../../../constants/StowAreaOperation";
import {SCOPE} from "../../../../../constants/Scope";
import {CUSTOM_HELP_OPTIONS} from "../../../../app-navigation/AppNavigation";
import {ApplicationActions} from "../../../../player/action/ApplicationActions";
import {FeatureManager} from "@amzn/dolphin-web-framework";
import BarCode from "../../../../../images/ic_package_scan.png";

export const getStowScanPackageData = (input) => {
    const locationLabel = chainWalk(() => input.targetSortLocation.locationInfo.locationLabel, undefined)
    switch (input.responseCode) {
        case RESPONSE.SUCCESS:
            return {
                view: VIEW.STOW_LOCATION
            }
        case RESPONSE.NO_INTERNET:
            return {
                nextRoute: ROUTING.NO_INTERNET
            }
        case RESPONSE.PACKAGE_ALREADY_STOWED:
            return {
                view: VIEW.STOW_LOCATION,
                notification: SCOPE.AMZL !== input.scope ? {
                    type: NOTIFICATION_TYPE.WARNING,
                    message: NOTIFICATION_STRING.ALERT_WARNING_PACKAGE_ALREADY_STOWED,
                    values: {displayPackageId: input.packageId}
                } : undefined
            }
        case RESPONSE.BAG_NOT_OPEN:
            if (isArScope(input.scope)) {
                return {
                    notification: {
                        type: NOTIFICATION_TYPE.ERROR,
                        message: NOTIFICATION_STRING.ALERT_ERROR_STOW_CART_NOT_SETUP,
                        values: undefined
                    },
                    nextRoute: ROUTING.MOVE_TO_BUFFER,
                    workflow: WORKFLOW.ATTACH_EMPTY_CART_AT_STOWING
                }
            } else if (SCOPE.AMZL_IN === input.scope) {
                return {
                    notification: {
                        type: NOTIFICATION_TYPE.ERROR,
                        message: NOTIFICATION_STRING.ALERT_ERROR_BAG_NOT_OPEN,
                        values: {locationLabel: locationLabel}
                    },
                    nextRoute: ROUTING.MOVE_TO_BUFFER,
                    workflow: chainWalk(() => input.workflow, WORKFLOW.STOW)
                }
            }
            return {
                notification: {
                    type: NOTIFICATION_TYPE.ERROR,
                    message: NOTIFICATION_STRING.ALERT_ERROR_BAG_NOT_OPEN,
                    values: {locationLabel: locationLabel}
                },
                nextRoute: ROUTING.MOVE_TO_BUFFER,
                workflow: WORKFLOW.OPEN_BAG_AT_PACKAGE_SCAN
            }
        case RESPONSE.CONTAINER_DOES_NOT_EXIST:
            return {
                notification: {
                    type: NOTIFICATION_TYPE.ERROR,
                    message: NOTIFICATION_STRING.ALERT_ERROR_CONTAINER_NOT_OPEN,
                    values: {locationLabel: locationLabel}
                },
                nextRoute: ROUTING.MOVE_TO_BUFFER,
                workflow: WORKFLOW.OPEN_BAG_AT_PACKAGE_SCAN
            }
        case RESPONSE.BAG_WEIGHT_LIMIT_REACHED:
            return {
                notification: {
                    type: NOTIFICATION_TYPE.ERROR,
                    message: NOTIFICATION_STRING.ALERT_ERROR_BAG_WEIGHT_LIMIT_REACHED,
                    values: {locationLabel: locationLabel}
                },
                nextRoute: ROUTING.MOVE_TO_BUFFER,
                workflow: WORKFLOW.BAG_WEIGHT_LIMIT_AT_PACKAGE_SCAN
            }
        case RESPONSE.CART_NOT_ATTACHED:
            if (SCOPE.AMZL_IN === input.scope) {
                return {
                    notification: {
                        type: NOTIFICATION_TYPE.ERROR,
                        message: NOTIFICATION_STRING.ALERT_ERROR_CART_NOT_ATTACHED,
                        values: {locationLabel: locationLabel}
                    },
                    nextRoute: ROUTING.MOVE_TO_BUFFER,
                    workflow: chainWalk(() => input.workflow, WORKFLOW.STOW)
                }
            }
            let locationLabelSidelineCheck = input.floorLabel
            if(input.isSidelinePackage === true){
                locationLabelSidelineCheck = locationLabel
            }
            return {
                notification: {
                    type: NOTIFICATION_TYPE.ERROR,
                    message: NOTIFICATION_STRING.ALERT_ERROR_CART_NOT_ATTACHED,
                    values: {locationLabel: locationLabelSidelineCheck}
                },
                nextRoute: ROUTING.MOVE_TO_BUFFER,
                workflow: WORKFLOW.ATTACH_EMPTY_CART_AT_STOWING
            }
        case RESPONSE.INVALID_PACKAGE_SORTGROUP:
            return {
                notification: {
                    type: NOTIFICATION_TYPE.ERROR,
                    message: NOTIFICATION_STRING.ALERT_ERROR_INVALID_PACKAGE_SORT_GROUP,
                    values: {locationLabel: null}
                },
                nextRoute: ROUTING.MOVE_TO_BUFFER,
                workflow: WORKFLOW.STOW
            }
        case RESPONSE.INVALID_PACKAGE_STATE:
            return {
                notification: {
                    type: NOTIFICATION_TYPE.ERROR,
                    message: NOTIFICATION_STRING.ALERT_ERROR_PACKAGE_CANNOT_BE_STOWED,
                    values: {displayPackageId: input.displayPackageId}
                },
                nextRoute: ROUTING.PROBLEM_SOLVE + SLASH + PROBLEM_SOLVE.STOW_FAILURE_INVALID_PACKAGE_STATE,
            }
        case RESPONSE.NO_PLAN_PRESENT:
            return handleNoPlanPresent(input)
        case RESPONSE.STATION_MISMATCH:
            const nextRoute = ROUTING.PROBLEM_SOLVE + SLASH + PROBLEM_SOLVE.WRONG_AISLE_CLUSTER
            if (SCOPE.AMZL_IN === input.scope) {
                return {
                    notification: {
                        type: NOTIFICATION_TYPE.ERROR,
                        message: NOTIFICATION_STRING.ALERT_ERROR_PACKAGE_WRONG_STATION,
                        values: {
                            displayPackageId: input.displayPackageId
                        }
                    },
                    nextRoute: nextRoute
                }
            }
            return {
                notification: {
                    type: NOTIFICATION_TYPE.ERROR,
                    message: NOTIFICATION_STRING.ALERT_ERROR_PACKAGE_WRONG_LOCATION,
                    values: {
                        displayPackageId: input.displayPackageId,
                        packageHolderStation: input.packageHolderStation
                    }
                },
                nextRoute: nextRoute
            }
        case RESPONSE.NO_DESTINATION_FOUND:
            return{
                notification: {
                    type: NOTIFICATION_TYPE.ERROR,
                    message:NOTIFICATION_STRING.ALERT_ERROR_NO_DESTINATION,
                    values: {displayPackageId: input.displayPackageId}
                }
            }
        case RESPONSE.CONTAINER_SORTKEY_MISMATCH:
            return {
                notification: {
                    type: NOTIFICATION_TYPE.ERROR,
                    message: NOTIFICATION_STRING.ALERT_ERROR_SORTKEY_MISMATCH,
                    values: {displayPackageId: input.displayPackageId}
                },
                nextRoute: ROUTING.PROBLEM_SOLVE + SLASH + PROBLEM_SOLVE.STOW_FAILURE,
            }
        case RESPONSE.INVALID_SCAN_DONE:
            return {
                notification: {
                    type: NOTIFICATION_TYPE.ERROR,
                    message: NOTIFICATION_STRING.ALERT_ERROR_INVALID_PACKAGE_SCANNED,
                    values: {displayPackageId: input.displayPackageId}
                }
            }
        case RESPONSE.UNSUPPORTED_BARCODE:
            return {
                notification: {
                    type: NOTIFICATION_TYPE.ERROR,
                    message: NOTIFICATION_STRING.ALERT_ERROR_INCORRECT_BARCODE_SCANNED
                }
            }
        case RESPONSE.UNAUTHORIZED:
            return {
                notification: {
                    type: NOTIFICATION_TYPE.ERROR,
                    message: COMMON_STRINGS.auth_error
                }
            }
        default:
            return {
                notification: {
                    type: NOTIFICATION_TYPE.ERROR,
                    message: COMMON_STRINGS.default_error
                }
            }
    }
}

const handleNoPlanPresent = (input) => {
    const notification =  {
        type: NOTIFICATION_TYPE.ERROR,
        message: NOTIFICATION_STRING.ALERT_ERROR_NO_PLAN_PRESENT,
        values: {displayPackageId: input.displayPackageId}
    }
    if (input.scope === SCOPE.AMZL_IN) {
        if (input.workflow === WORKFLOW.STOW_TOPUP) {
            return {
                topUpPackage: true,
                view: VIEW.STOW_LOCATION
            }
        } else {
            return {
                notification: notification,
                nextRoute: ROUTING.MOVE_TO_BUFFER,
                workflow: WORKFLOW.STOW
            }
        }
    }
    return {
        notification: notification,
        nextRoute: ROUTING.PROBLEM_SOLVE + SLASH + PROBLEM_SOLVE.STOW_FAILURE,
    }
}

export const getHelpData = (scope, props, closeHelpOption, openCustomHelpOption, dispatchPlayer) => {

    switch (scope) {
        case SCOPE.AMZL:
            return getAMZLHelpData(props, closeHelpOption, dispatchPlayer)
        case SCOPE.AMZL_IN:
            return getAMZLINHelpData(props, closeHelpOption, openCustomHelpOption)
        case SCOPE.AR:
        case SCOPE.AR_NEXT_GEN:
        case SCOPE.SSD:
        default:
            return {list: []}
    }
}

const getAMZLHelpData = (props, closeHelpOption, dispatchPlayer) => {
    return {
        pageTitle: HELP_CENTER_STRINGS.STOW_PROBLEMS_HELP,
        list: [
            {
                title: props.intl.formatMessage({id: HELP_CENTER_STRINGS.CLOSE_CONTAINER}),
                onClick: () => {
                    dispatchPlayer({type: ApplicationActions.HELP_OPTION_SELECTED, data: {operation: HELP_CENTER_STRINGS.CLOSE_CONTAINER_HELP}});
                    closeHelpOption()
                    props.history.push(getSelectContainerRoutingPath(STOW_AREA_OPERATION.CLOSE_CONTAINER))
                }
            }
        ]
    }
}

const getAMZLINHelpData = (props, closeHelpOption, openCustomHelpOption) => {
    return {
        pageTitle: HELP_CENTER_STRINGS.STOW_PROBLEMS_HELP,
        list: [
            {
                title: getFormattedString(props, HELP_CENTER_STRINGS.TRACKING_ID_MANUAL_INPUT),
                onClick: () => {
                    closeHelpOption()
                    openCustomHelpOption(CUSTOM_HELP_OPTIONS.STOW_MANUAL_SCAN_PACKAGE)
                }
            }
        ]
    }
}

export const getConfigForViewOfConPackages = (props, changePrinterClickCallback) => {
    let config = {
        primaryTitle: {
            title: getFormattedString(props, SCAN_PACKAGE_VIEW_STRINGS.scan_package),
            type: "Bold"
        },
        secondaryTitle: {
            title: getFormattedString(props, SCAN_PACKAGE_VIEW_STRINGS.attach_label),
        },
        image: BarCode
    };
    if (FeatureManager.isFeatureEnabled(FeatureManager.Features.AVERY_HOTSWAP_STOW)) {
        config.button = {
            title: getFormattedString(props, COMMON_STRINGS.change_printer),
            onClick: changePrinterClickCallback
        };
    }
    return config;
};

