import {RESPONSE} from "../../../../../../constants/ResponseCodes";
import {NOTIFICATION_TYPE} from "../../../../../../constants/NotificationType";
import {COMMON_STRINGS, NOTIFICATION_STRING} from "../../../../../../constants/Strings";
import {getNotificationFormat} from "../../../../../utils/Util";
import {ROUTING} from "../../../../../../constants/Routing";

export const getScanPalletLocationViewData = (input) => {
    const values = {
        destinationLabel: input.destinationLabel
    }

    switch (input.responseCode) {
        case RESPONSE.SUCCESS:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.SUCCESS, NOTIFICATION_STRING.ALERT_SUCCESS_PALLET_READY, values)
            }
        case RESPONSE.NO_INTERNET:
            return {
                nextRoute: ROUTING.NO_INTERNET
            }
        case RESPONSE.INVALID_SCAN_DONE:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_INVALID_BARCODE_SCANNED)
            }
        case RESPONSE.INVALID_MOVE:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_INVALID_LOCATION_SCANNED)
            }
        case RESPONSE.NON_EMPTY_LOCATION:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_NO_ACTIVE_CONTAINER_AT_LOCATION, values)
            }
        case RESPONSE.UNAUTHORIZED:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.auth_error)
            }
        default:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.default_error)
            }
    }
}
