import React, {useContext, useEffect} from 'react';
import {DetailsCardView, HeaderView, SnapshotView} from "@amzn/dolphin-uiweb-framework";
import {StowPackageContext} from "../../context/StowPackageContext";
import {getSortInfoForPackage} from "../../action/StowPackageAction";
import {ConfigContext} from "../../../config/context/ConfigContext";
import {getHelpData, getRescanCardViewData} from "./data/StowRescanViewData";
import {
    blockBackPress,
    EMPTY,
    getFormattedString,
    resetBlockedBackPress,
    isOVPackage
} from "../../../utils/Util";
import {injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";
import SnapshotWithImageView from "./SnapshotWithImageView";
import Tick from "../../../../images/tick.png";
import Cross from "../../../../images/cross.png";
import {SCOPE} from "../../../../constants/Scope";
import {COMMON_STRINGS} from "../../../../constants/Strings";
import Box from "@amzn/meridian/box";
import ScanView from "../../../scan-view/ScanView";
import {COMMON_ACTIONS} from "../../../../constants/ActionType";
import {PlayerContext} from "../../../player/context/PlayerContext";
import {ScanTypes} from "../../../player/PlayerConstant";
import {AppNavigationContext} from "../../../app-navigation/context/AppNavigationContext";
import {getAisle, getRacks} from "../rescan-package/data/StowRescanViewData";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import {FeatureManager} from "@amzn/dolphin-web-framework";

const StowRescanPackageView = (props) => {

    const {configViewModel} = useContext(ConfigContext)
    const {stowPackageModel, stowPackageDispatch} = useContext(StowPackageContext);
    const {statePlayer, dispatchPlayer} = useContext(PlayerContext);
    const {navActions: {setHelpOption, closeHelpOption, openCustomHelpOption, initContext, closeBackEnabled, openBackEnabled, openBackDrop}} = useContext(AppNavigationContext)

    const headerData = {
        title : "Rescan package"
    };

    useEffect(() => {
        blockBackPress(initContext, closeBackEnabled);

        if (FeatureManager.isFeatureEnabled(FeatureManager.Features.STOW_SAL_COLOR_MISMATCH)) {
            setHelpOption(true, getHelpData(props, dispatchPlayer, closeHelpOption, openCustomHelpOption));
        }

        return () => {
            resetBlockedBackPress(openBackEnabled, initContext, openBackDrop);
            if (FeatureManager.isFeatureEnabled(FeatureManager.Features.STOW_SAL_COLOR_MISMATCH)) {
                setHelpOption(false, {});
            }
        }
    },[]);

    const onScan = (input) => {
        stowPackageDispatch({type: COMMON_ACTIONS.LOADING})
        let shouldNotifySbl = true;
        if(!stowPackageModel.packageIdentifiers?.includes(input)) {
            shouldNotifySbl = false;
        }
        statePlayer.scanType = ScanTypes.PACKAGE_RESCAN;
        getSortInfoForPackage(stowPackageDispatch, input, configViewModel.scope, EMPTY, configViewModel.packageId, configViewModel.sblEndpoint, shouldNotifySbl, statePlayer)
    }

    const getCorrectLocationDetailsForOV = () => {
        const snapshot =  [{
                description: getFormattedString(props, COMMON_STRINGS.aisle),
                title: getAisle(stowPackageModel)
            },
            {
                description: getFormattedString(props, COMMON_STRINGS.ov_rack),
                title: getRacks(stowPackageModel)
            }];

        return snapshot.map((currentSnapshot, index) =>
            <SnapshotView key={index} title={currentSnapshot.title} description={currentSnapshot.description}
                          headerFontSize={"Large"}/>)
    };

    const renderOVViewForCorrectLocations = () => {
        const snapshots = getCorrectLocationDetailsForOV();
        return <Row>
            <div align="left">
                <Row>
                    {snapshots}
                    <img src={Tick} alt=""/>
                </Row>
                <Row spacingInset="small">
                    <Text type="b200">{props.intl.formatMessage({id: COMMON_STRINGS.correct_location})}</Text>
                </Row>
            </div>
        </Row>
    };

    const renderSalColorInformation = (salColor) => {
        return <div>
            <Row width="20%" widths={["fill"]} spacingInset="small">
                <DetailsCardView snapshots={[{}]} backgroundColor={salColor}/>
            </Row>
            <Row spacingInset="none none none small">
                <Text type="b200">{props.intl.formatMessage({
                    id: COMMON_STRINGS.package_sal_color,
                    defaultMessage: "Package SAL color"
                })}</Text>
            </Row>
        </div>
    }

    const renderView = () => {
        const data = getRescanCardViewData(configViewModel.scope, stowPackageModel)
        switch (configViewModel.scope) {
            case SCOPE.AR:
            case SCOPE.AR_NEXT_GEN:
            case SCOPE.SSD:
                return <Box type="outline">
                    <SnapshotView headerFontSize={"Large"} title={stowPackageModel.packageId}
                                  description={props.intl.formatMessage({id: COMMON_STRINGS.package_id})}/>

                    {data.salColor && renderSalColorInformation(data.salColor)}
                    <SnapshotWithImageView headerFontSize={"Large"} title={data.wrongLocationScannedTitle}
                                           description={props.intl.formatMessage({id: COMMON_STRINGS.wrong_location_scanned})}
                                           image={Cross}/>
                    <SnapshotWithImageView headerFontSize={"Large"} title={data.correctLocationScannedTitle}
                                           description={props.intl.formatMessage({id: COMMON_STRINGS.correct_location})}
                                           image={Tick}/>
                </Box>

            default:
                return <Box type="outline">
                    <SnapshotView headerFontSize={"Large"} title={stowPackageModel.packageId}
                                  description={props.intl.formatMessage({id: COMMON_STRINGS.package_id})}/>
                    {data.salColor && renderSalColorInformation(data.salColor)}
                    {stowPackageModel.incorrectDestinationScanned &&
                    <SnapshotWithImageView headerFontSize={"Large"} title={data.wrongLocationScannedTitle}
                                           description={props.intl.formatMessage({id: COMMON_STRINGS.wrong_location_scanned})}
                                           image={Cross}/>}
                    {isOVPackage(stowPackageModel) ? renderOVViewForCorrectLocations()
                        : <SnapshotWithImageView headerFontSize={"Large"} title={data.correctLocationScannedTitle}
                                                 description={props.intl.formatMessage({id: COMMON_STRINGS.correct_location})} image={Tick}/>}
                </Box>
        }
    }

    return (
        <div>
            <Column spacingInset="medium" alignmentHorizontal="center">
                <HeaderView primaryTitle={{
                    title: headerData.title,
                    type: "Bold",
                    size: "Large"
                }}/>
            </Column>
            <Column spacingInset="small">
                {renderView()}
            </Column>
            <ScanView onScan={onScan} ariaLabel={headerData.title}/>
        </div>
    )
}

export default injectIntl(StowRescanPackageView)
