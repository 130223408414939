import {RESPONSE} from "../../../constants/ResponseCodes";
import {VIEW} from "../../workflow/Workflow";
import {getNotificationFormat} from "../../utils/Util";
import {NOTIFICATION_TYPE} from "../../../constants/NotificationType";
import {COMMON_STRINGS, NOTIFICATION_STRING} from "../../../constants/Strings";

export const getRoutesForStowProgressViewData = (data) => {

    switch (data.responseCode) {
        case RESPONSE.SUCCESS:
            return {
                view: VIEW.STOW_PROGRESS_ROUTE
            }
        case RESPONSE.INVALID_SCAN_DONE:
            return {
            notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_INVALID_LOCATION_SCANNED)
        }
        case RESPONSE.UNAUTHORIZED:
            return {
            notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.auth_error)
        }
        default:
            return {
            notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.default_error)
        }

    }
}