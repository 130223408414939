import React, {Component} from 'react';
import ManageStowAreaView from "./view/ManageStowAreaView";
import {COMMON_STRINGS} from "../../constants/Strings";
import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {TrainingBlockerView} from "@amzn/dolphin-uiweb-framework";

class ManageStowArea extends Component {

    constructor(props) {
        super(props);
        this.state = {trainingBlockerArea: props.trainingBlockerArea};
    }

    renderTrainingBlockerView = () => {
        return <TrainingBlockerView
            title={COMMON_STRINGS.TRAINING_BLOCKER_TITLE}
            description={COMMON_STRINGS.TRAINING_BLOCKER_DESC}
            buttonText={COMMON_STRINGS.GOT_IT}
            buttonOnClick={() => NativeMeshInteractor.exitModule()}/>
    }

    render() {
        return this.state.trainingBlockerArea ? this.renderTrainingBlockerView() :
            <div>
                <ManageStowAreaView history={this.props.history}/>
            </div>
    }
}

export default ManageStowArea
