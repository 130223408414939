import React, {Component} from 'react';
import ScrubTaskView from "./view/ScrubTaskView";
import {COMMON_STRINGS} from "../../constants/Strings";
import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {TrainingBlockerView} from "@amzn/dolphin-uiweb-framework";

class ScrubTask extends Component {

    constructor(props) {
        super(props);
        this.state = {trainingBlockerStow: props.trainingBlockerStow};
    }

    renderTrainingBlockerView = () => {
        return <TrainingBlockerView
            title={COMMON_STRINGS.TRAINING_BLOCKER_TITLE}
            description={COMMON_STRINGS.TRAINING_BLOCKER_DESC}
            buttonText={COMMON_STRINGS.GOT_IT}
            buttonOnClick={() => NativeMeshInteractor.exitModule()}/>
    }

    render() {

        return this.state.trainingBlockerStow ? this.renderTrainingBlockerView() :
            <div>
                <ScrubTaskView history={this.props.history}/>
            </div>
    }
}

export default ScrubTask
