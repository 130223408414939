import React, {useContext} from 'react';
import {DetailsCardView, HeaderView} from "@amzn/dolphin-uiweb-framework";
import {AR_VIEW_STRINGS, COMMON_STRINGS} from "../../../../../constants/Strings";
import {FormattedMessage, injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";
import {CONTAINER_TYPE} from "../../../../../constants/ContainerType";
import {ConfigContext} from "../../../../config/context/ConfigContext";
import ScanView from "../../../../scan-view/ScanView";
import {validateContainerBeforeAttach} from "../../../prepare-container/action/PrepareContainerAction";
import {PrepareContainerContext} from "../../../prepare-container/context/PrepareContainerContext";
import {COMMON_ACTIONS} from "../../../../../constants/ActionType";
import Loading from "../../../../loading/Loading";
import {getFormattedString} from "../../../../utils/Util";

const BagScanView = (props) => {

    const {prepareContainerModel, prepareContainerDispatch} = useContext(PrepareContainerContext)
    const {configViewModel} = useContext(ConfigContext)

    const onScan = (input) => {
        const request = {
            workflow: configViewModel.workflow,
            containerScannableId: input,
            containerScannableType: CONTAINER_TYPE.BAG,
            scope: configViewModel.scope
        }
        prepareContainerDispatch({type: COMMON_ACTIONS.LOADING})
        validateContainerBeforeAttach(prepareContainerDispatch, request)
    }

    const init = {
        primaryTitle: {
            title: <FormattedMessage id={AR_VIEW_STRINGS.scan_bag_primary_title}/>,
        },
        secondaryTitle: {
            title: <FormattedMessage id={AR_VIEW_STRINGS.scan_empty_bag}/>,
            type: "Bold"
        },
        cartType: <FormattedMessage id={AR_VIEW_STRINGS.bin_config} values={{
            binSize: prepareContainerModel.binList.length,
            activeBin: prepareContainerModel.activeBinIndex + 1
        }}/>,
        onScan: onScan
    }

    const locationView = () => {
        return [{
            description: <FormattedMessage id={COMMON_STRINGS.shelf}/>,
            title: prepareContainerModel.activeBin.binLabel
        }, {
            description: <FormattedMessage id={COMMON_STRINGS.cart_type}/>,
            title: init.cartType
        }]
    }

    const renderLocationView = () => {
        return <DetailsCardView snapshots={locationView()} headerFontSize="Large" display={"Column"}/>
    }

    return (
        <div>
            {prepareContainerModel.loading && <Loading/>}
            <Column spacingInset="medium" alignmentHorizontal="center">
                <HeaderView primaryTitle={init.primaryTitle}
                            secondaryTitle={init.secondaryTitle}/>
            </Column>
            <Column spacingInset="medium" alignmentHorizontal="center">
                {renderLocationView()}
                <ScanView onScan={onScan} ariaLabel={getFormattedString(props, AR_VIEW_STRINGS.scan_empty_bag)}/>
            </Column>
        </div>
    )
}

export default injectIntl(BagScanView)
