import {NOTIFICATION_TYPE} from "../../../../constants/NotificationType";
import {COMMON_STRINGS, NOTIFICATION_STRING} from "../../../../constants/Strings";
import {RESPONSE} from "../../../../constants/ResponseCodes";
import {ROUTING} from "../../../../constants/Routing";
import {getNotificationFormat, SLASH} from "../../../utils/Util";
import {WORKFLOW} from "../../../workflow/Workflow";
import {CONSTANT} from "../../../../constants/Constants";

export const getProblemSolveViewData = (inputData) => {

    const values = {
        displayPackageId: inputData.displayPackageId
    }
    switch (inputData.responseCode) {
        case RESPONSE.SUCCESS:
            const nextRoute = inputData.workflow === WORKFLOW.STOW_TOPUP ? ROUTING.STOW_PACKAGE + SLASH + CONSTANT.TOPUP_PACKAGE : ROUTING.STOW_PACKAGE
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.SUCCESS, NOTIFICATION_STRING.ALERT_SUCCESS_MOVED_TO_PROBLEM_SOLVE, values),
                nextRoute: nextRoute
            }
        case RESPONSE.NO_INTERNET:
            return {
                nextRoute: ROUTING.NO_INTERNET

            }
        case RESPONSE.INVALID_SCAN_DONE:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_INVALID_LOCATION_SCANNED)
            }
        case RESPONSE.INVALID_MOVE:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_MOVED_TO_PROBLEM_SOLVE_FAILURE, values)
            }
        case RESPONSE.UNAUTHORIZED:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.auth_error)
            }
        default:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.default_error)
            }
    }
}
