import {
    CLOSE_BAG,
    CLOSE_CONTAINER,
    GET_BAG_SORT_INFO_FOR_CLOSE,
    REDIRECT_TO_STAGE
} from "../action/CloseContainerAction"
import {getCloseScanBagViewData} from "../view/scan-bag/data/CloseScanBagViewData";
import {getCloseScanBagLocationViewData} from "../view/scan-bag-location/data/CloseScanBagLocationViewData";
import {getCloseContainerViewData} from "../view/data/CloseDefaultContainerViewData";
import {COMMON_ACTIONS} from "../../../../constants/ActionType";
import stageContainer from "../../../handler/StageContainerHandler";

export const CloseContainerReducer = (state, action) => {
    switch (action.type) {
        case GET_BAG_SORT_INFO_FOR_CLOSE:
            const closeScanBagViewData = getCloseScanBagViewData(action.data)
            return {
                ...state,
                ...action.data,
                ...closeScanBagViewData,
                loading: false,
            }
        case CLOSE_BAG:
            const closeScanBagViewLocationData = getCloseScanBagLocationViewData(action.data)
            return {
                ...state,
                ...action.data,
                ...closeScanBagViewLocationData,
                loading: false,
            }
        case CLOSE_CONTAINER:
            const closeContainerViewData = getCloseContainerViewData(action.data)
            return {
                ...state,
                ...action.data,
                ...closeContainerViewData,
                loading: false,
            }
        case COMMON_ACTIONS.LOADING:
            return {
                ...state,
                loading: true,
            }
        case REDIRECT_TO_STAGE:
            stageContainer(action.data.props);
            return {
                state,
                loading: false
            }
        default:
            return state
    }
}
