import {RESPONSE} from "../../../../../../constants/ResponseCodes";
import {VIEW} from "../../../../../workflow/Workflow";
import {getNotificationFormat} from "../../../../../utils/Util";
import {NOTIFICATION_TYPE} from "../../../../../../constants/NotificationType";
import {COMMON_STRINGS, NOTIFICATION_STRING, SCRUB_CONTAINER_STRINGS} from "../../../../../../constants/Strings";
import {getContainerRoutingPath, ROUTING} from "../../../../../../constants/Routing";
import {CONTAINER_TYPE} from "../../../../../../constants/ContainerType";

export const getScrubBagViewData = (input) => {
    const values = {
        bagLabel: input.displayBagId,
    }

    switch (input.responseCode) {
        case RESPONSE.SUCCESS:
            return {
                view: VIEW.SCAN_BAG_LOCATION,
                notification: getNotificationFormat(NOTIFICATION_TYPE.SUCCESS,
                    SCRUB_CONTAINER_STRINGS.scrub_bag_successful, values)
            }
        case RESPONSE.NO_INTERNET:
            return {
                nextRoute: ROUTING.NO_INTERNET
            }
        case RESPONSE.INVALID_MOVE:
        case RESPONSE.INVALID_SCAN_DONE:
            return {
                nextRoute: getContainerRoutingPath(ROUTING.REPLACE_CONTAINER, CONTAINER_TYPE.BAG),
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_INCORRECT_BAG_SCANNED, values)
            }
        case RESPONSE.UNAUTHORIZED:
            return {
                nextRoute: getContainerRoutingPath(ROUTING.REPLACE_CONTAINER, CONTAINER_TYPE.BAG),
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.auth_error)
            }
        default:
            return {
                nextRoute: getContainerRoutingPath(ROUTING.REPLACE_CONTAINER, CONTAINER_TYPE.BAG),
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.default_error)
            }
    }
}
