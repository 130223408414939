import React, {Component} from 'react';
import StowPackageContextProvider from "../context/StowPackageContext";
import StowInputRouteCodeView from "../route-code/input-route-code/StowInputRouteCodeView";

class StowRouteCode extends Component {

    render() {

        return (
            <div>
                <StowPackageContextProvider>
                    <StowInputRouteCodeView history={this.props.history}/>
                </StowPackageContextProvider>
            </div>
        )
    }
}

export default StowRouteCode