import React, {useContext, useEffect} from 'react';
import {TaskView} from "@amzn/dolphin-uiweb-framework";
import {injectIntl} from "react-intl";
import {ConfigContext} from "../../config/context/ConfigContext";
import {AppNavigationContext} from "../../app-navigation/context/AppNavigationContext";
import {clearGlobalContext} from "../../config/action/ConfigAction";
import {ROUTING} from "../../../constants/Routing";

const StowReadinessTaskView = (props) => {

    const {configViewDispatch} = useContext(ConfigContext)
    const {navActions: {setHelpOption}} = useContext(AppNavigationContext)

    useEffect(() => {
        clearGlobalContext(configViewDispatch)
        setHelpOption(true, {list: []});
    }, []);

    const handleClick = () => {
        props.history.push(
            {
                pathname: ROUTING.SCAN_PRINTER,
                state: { helpFlow: false }
            }
        )
    }

    const getTasks = () => {
        return [{
            title: "Stow task",
            description: "Stow task",
            href: ROUTING.PREPARE_ROUTE
        },
            {
                title : "Print container label",
                description:  "Print container label for a sector",
                onClick: () => handleClick()
            }]
    }

    const renderView = () => {
        return (
        <div className="scrollList">
            <TaskView pageTitle={"Select Stow Readiness Task"}
                      tasks={getTasks()}/>
        </div> )

    }

    return renderView()
}

export default injectIntl(StowReadinessTaskView)
