import {RESPONSE} from "../../../../../../constants/ResponseCodes";
import {NOTIFICATION_TYPE} from "../../../../../../constants/NotificationType";
import {COMMON_STRINGS, NOTIFICATION_STRING} from "../../../../../../constants/Strings";
import {VIEW} from "../../../../../workflow/Workflow";
import {ROUTING} from "../../../../../../constants/Routing";
import {getNotificationFormat} from "../../../../../utils/Util";

export const getScanBagViewData = (input) => {
    const values = {
        displayBagId: input.displayBagId,
        existingDestinationLabel: input.existingDestinationLabel
    }

    switch (input.responseCode) {
        case RESPONSE.SUCCESS: {
            return {
                view: VIEW.SCAN_BAG_LOCATION
            }
        }
        case RESPONSE.NO_INTERNET:
            return {
                nextRoute: ROUTING.NO_INTERNET
            }
        case RESPONSE.NON_EMPTY_BAG:
            return {
                view: VIEW.SCRUB_NON_EMPTY_BAG,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_NON_EMPTY_BAG, values)
            }
        case RESPONSE.BAG_ALREADY_OPEN:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.WARNING, NOTIFICATION_STRING.ALERT_WARNING_NON_BAG_ALREADY_OPEN, values)
            }
        case RESPONSE.INVALID_SCAN_DONE:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_INVALID_BARCODE_SCANNED)
            }
        case RESPONSE.UNAUTHORIZED:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.auth_error)
            }
        default:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.default_error)
            }
    }
}
