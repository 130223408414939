import React, {useContext, useEffect} from 'react';
import {ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import {ScrubContainerContext} from "../../context/ScrubContainerContext";
import {HELP_CENTER_STRINGS, SCRUB_CONTAINER_STRINGS} from "../../../../constants/Strings";
import {getFormattedString, getHelpOptions, getSelectContainerRoutingPath} from "../../../utils/Util";
import {FormattedMessage} from "react-intl";
import {AppNavigationContext} from "../../../app-navigation/context/AppNavigationContext";
import {STOW_AREA_OPERATION} from "../../../../constants/StowAreaOperation";
import {validateContainerBeforeScrubAction} from "../../action/ScrubContainerAction";
import Column from "@amzn/meridian/column";
import ScanView from "../../../scan-view/ScanView";
import QrCode from "../../../../images/ic_qr_code.png";
import {ApplicationActions} from "../../../player/action/ApplicationActions";
import {ScanTypes} from "../../../player/PlayerConstant";
import {PlayerContext} from "../../../player/context/PlayerContext";
import {injectIntl} from "react-intl";

const ScanLocationView = (props) => {
    const {scrubContainerViewModel, scrubContainerDispatch} = useContext(ScrubContainerContext)
    const {navActions: {setHelpOption, closeHelpOption}} = useContext(AppNavigationContext)
    const {statePlayer, dispatchPlayer} = useContext(PlayerContext)

    const onScan = (input) => {
        scrubContainerViewModel.containerScannableId = input;
        scrubContainerViewModel.containerType = SCRUB_CONTAINER_STRINGS.STACKING_AREA;
        statePlayer.scanType = ScanTypes.SCRUB_LOCATION;
        validateContainerBeforeScrubAction(scrubContainerDispatch, scrubContainerViewModel.containerScannableId);
    }

    useEffect(() => {
        const callbacks = [
            () => {
                dispatchPlayer({type: ApplicationActions.HELP_OPTION_SELECTED, data: {operation: HELP_CENTER_STRINGS.CLOSE_CONTAINER_HELP}});
                props.history.push(getSelectContainerRoutingPath(STOW_AREA_OPERATION.CLOSE_CONTAINER))
            },
            closeHelpOption
        ]
        setHelpOption(true, getHelpOptions(callbacks,
            <FormattedMessage id={HELP_CENTER_STRINGS.STOW_PROBLEMS_HELP}/>,
            <FormattedMessage id={HELP_CENTER_STRINGS.CLOSE_CONTAINER}/>));
        return () => setHelpOption(false, {});
    }, []);

    const init = {
        primaryTitle: {
            title: getFormattedString(props, SCRUB_CONTAINER_STRINGS.scrub_location_scan_title),
            type: "Bold"
        },
        secondaryTitle: {
            title: getFormattedString(props, SCRUB_CONTAINER_STRINGS.scrub_location_scan_subtitle)
        },
        image: QrCode,
    }

    return (
        <Column spacingInset="medium" alignmentHorizontal="center">
            {<ScanWithCodeView {...init} />}
            <ScanView onScan={onScan}/>
        </Column>
    )
}

export default injectIntl(ScanLocationView)


