import {dev_debug, UNAUTHORIZED_ERROR_RESPONSE, UNKNOWN_ERROR_OCCURRED_RESPONSE} from "../../utils/Util";
import {RESPONSE} from "../../../constants/ResponseCodes";
import {API_PATH, axiosGET} from "../../utils/network/NetworkUtils";
import {AnalyticEventKeys, MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {UNAUTHORIZED_ERROR_CODE} from "../../../constants/Constants";

export const getSALTemplateForPrinter = async (printerType) => {
    const startTime = Date.now();
    const params = {
        params:{
            printerType: printerType
        }
    };

    try {
        const response = dev_debug === true ? TestData :
            await axiosGET(AnalyticEventKeys.Modules.GET_SAL_TEMPLATE, API_PATH.SAL_TEMPLATE, params)

        if (response.status === 200) {
            MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.STOW_GET_SAL_TEMPLATE_FOR_PRINTER, startTime);
            return response.data
        }
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.STOW_GET_SAL_TEMPLATE_FOR_PRINTER, startTime, true);
    } catch (error) {
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.STOW_GET_SAL_TEMPLATE_FOR_PRINTER, startTime, true);
        if (error?.response?.status === UNAUTHORIZED_ERROR_CODE) {
            return UNAUTHORIZED_ERROR_RESPONSE
        }
        return UNKNOWN_ERROR_OCCURRED_RESPONSE
    }
    return {
        responseCode: RESPONSE.UNKNOWN_ERROR_OCCURRED
    }
}

const TestData = {
    status: 200,
    data: {
        responseCode: "SUCCESS",
        template: ""
    }
}