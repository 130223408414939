import React, {useContext, useEffect} from 'react';
import {SnapshotView} from "@amzn/dolphin-uiweb-framework";
import {MOVE_TO_BUFFER_VIEW_STRINGS, NOTIFICATION_STRING} from "../../../constants/Strings";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import Box from "@amzn/meridian/box";
import {WORKFLOW} from "../../workflow/Workflow";
import {NOTIFICATION_TYPE} from "../../../constants/NotificationType";
import {NotificationContext} from "../../notification/context/NotificationContext";
import {FormattedMessage, injectIntl} from "react-intl";
import {ConfigContext} from "../../config/context/ConfigContext";
import {ROUTING} from "../../../constants/Routing";
import {blockBackPress, resetBlockedBackPress, SLASH} from "../../utils/Util";
import {CONTAINER_TYPE} from "../../../constants/ContainerType";
import HeadingView, {HEADING_TYPE} from "../../heading-view/HeadingView";
import {AppNavigationContext} from "../../app-navigation/context/AppNavigationContext";
import {PlayerContext} from "../../player/context/PlayerContext";
import {ApplicationActions} from "../../player/action/ApplicationActions";

const MoveToBufferView = (props) => {

    const {configViewModel} = useContext(ConfigContext)
    const {notificationActions: {setNotification}} = useContext(NotificationContext);
    const {navActions: {closeBackEnabled, initContext, openBackEnabled, openBackDrop}} = useContext(AppNavigationContext)
    const {dispatchPlayer} = useContext(PlayerContext);

    const onClick = () => {
        const locationLabel = configViewModel.locationLabel
        let notification = {
            type: NOTIFICATION_TYPE.SUCCESS,
            message: NOTIFICATION_STRING.ALERT_SUCCESS_PACKAGE_MOVED_TO_BUFFER,
            values: {packageId: configViewModel.packageId}
        }
        dispatchPlayer({type: ApplicationActions.MOVE_TO_BUFFER, data: {workflow: configViewModel.workflow}});
        switch (configViewModel.workflow) {

            case WORKFLOW.BAG_WEIGHT_LIMIT_AT_PACKAGE_SCAN:
                notification = {
                    type: NOTIFICATION_TYPE.WARNING,
                    message: NOTIFICATION_STRING.ALERT_WARNING_BAG_HEAVY,
                    values: {locationLabel: locationLabel}
                }
                setNotification(notification)
                props.history.push(ROUTING.CLOSE_CONTAINER + SLASH + CONTAINER_TYPE.BAG)
                break

            case WORKFLOW.BAG_FULL:
                notification = {
                    type: NOTIFICATION_TYPE.WARNING,
                    message: NOTIFICATION_STRING.ALERT_WARNING_BAG_FULL,
                    values: {locationLabel: locationLabel}
                }
                setNotification(notification)
                props.history.push(ROUTING.CLOSE_CONTAINER + SLASH + CONTAINER_TYPE.BAG)
                break

            case WORKFLOW.OPEN_BAG_AT_PACKAGE_SCAN:
                notification = {
                    type: NOTIFICATION_TYPE.SUCCESS,
                    message: NOTIFICATION_STRING.ALERT_SUCCESS_PACKAGE_MOVED_TO_BUFFER,
                    values: {packageId: configViewModel.packageId}
                }
                setNotification(notification)
                props.history.push(ROUTING.PREPARE_CONTAINER + SLASH + CONTAINER_TYPE.BAG)
                break

            case WORKFLOW.ATTACH_EMPTY_CART_AT_STOWING:
                setNotification(notification)
                props.history.push(ROUTING.PREPARE_STOW_LOCATION)
                break

            case WORKFLOW.STOW:
            case WORKFLOW.STOW_TOPUP:
            case WORKFLOW.STOW_FLOW:
                setNotification(notification);
                props.history.push(ROUTING.STOW_PACKAGE);
                break;

            default:
                setNotification(notification)
                props.history.push(ROUTING.PREPARE_CONTAINER + SLASH + CONTAINER_TYPE.BAG)
                break
        }
    }

    useEffect(() => {
        if (WORKFLOW.BAG_FULL === configViewModel.workflow) {
            const notification = {
                type: NOTIFICATION_TYPE.ERROR,
                message: NOTIFICATION_STRING.ALERT_WARNING_BAG_FULL,
                values: {locationLabel: configViewModel.locationLabel}
            }
            setNotification(notification)
        }
        closeBackEnabled()
        blockBackPress(initContext, closeBackEnabled);
        return () => {
            resetBlockedBackPress(openBackEnabled, initContext, openBackDrop);
        };
    }, []);

    const headerData = {
        primaryTitle: MOVE_TO_BUFFER_VIEW_STRINGS.move_to_buffer,
        secondaryTitle: MOVE_TO_BUFFER_VIEW_STRINGS.move_to_buffer_guidance
    }

    return (
        <div>
            <Column height="100%" spacingInset="medium">
                <Column alignmentHorizontal="center">
                    <HeadingView {...headerData} type={HEADING_TYPE.PRIMARY}/>
                </Column>
                <Box type="outline">
                    <SnapshotView title={configViewModel.packageId} headerFontSize="Medium"
                                  description={props.intl.formatMessage({id: MOVE_TO_BUFFER_VIEW_STRINGS.move_to_buffer_package_id})}/>
                </Box>
                <Column className={"footer"} spacingInset={"small"}>
                    <Button onClick={onClick} size="large"
                            type="primary">{<FormattedMessage
                        id={MOVE_TO_BUFFER_VIEW_STRINGS.move_to_buffer_ack}/>}</Button>
                </Column>
            </Column>
        </div>
    )
}

export default injectIntl(MoveToBufferView)
