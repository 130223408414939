import {RESPONSE} from "../../../../../../constants/ResponseCodes";
import {NOTIFICATION_TYPE} from "../../../../../../constants/NotificationType";
import {COMMON_STRINGS, NOTIFICATION_STRING} from "../../../../../../constants/Strings";
import {VIEW, WORKFLOW} from "../../../../../workflow/Workflow";
import {getContainerRoutingPath, ROUTING} from "../../../../../../constants/Routing";
import {CONTAINER_TYPE} from "../../../../../../constants/ContainerType";
import {getNotificationFormat} from "../../../../../utils/Util";

export const getScanBagLocationViewData = (input) => {
    const values = {
        bagLabel: input.bagLabel,
        displayBagId: input.displayBagId,
        destinationLabel: input.destinationLabel
    }

    switch (input.responseCode) {
        case RESPONSE.SUCCESS:
            return {
                nextRoute: getNextRouteForScanBagLocation(input.workflow),
                view: VIEW.SCAN_BAG,
                notification: getNotificationFormat(NOTIFICATION_TYPE.SUCCESS, input.workflow === WORKFLOW.OPEN_BAG_IN_SAME_LOCATION
                    ? NOTIFICATION_STRING.ALERT_SUCCESS_BAG_READY_FOR_STOWING
                    : NOTIFICATION_STRING.ALERT_SUCCESS_BAG_READY, values),
            }
        case RESPONSE.NO_INTERNET:
            return {
                nextRoute: ROUTING.NO_INTERNET
            }
        case RESPONSE.NON_EMPTY_BAG:
            return {
                nextRoute: getContainerRoutingPath(ROUTING.REPLACE_CONTAINER, CONTAINER_TYPE.BAG),
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_BAG_CANNOT_BE_USED, values)
            }
        case RESPONSE.INVALID_SCAN_DONE:
            return {
                view: VIEW.SCAN_BAG,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_INVALID_LOCATION_SCANNED)
            }
        case RESPONSE.INVALID_MOVE:
            return {
                view: VIEW.SCAN_BAG,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_BAG_CANNOT_BE_OPEN, values)
            }
        case RESPONSE.NON_EMPTY_LOCATION:
            return {
                view: VIEW.SCRUB_NON_EMPTY_LOCATION,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_SUCCESS_LOCATION_HAS_BAG, values)
            }
        case RESPONSE.UNAUTHORIZED:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.auth_error)
            }
        default:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.default_error)
            }
    }
}

export const getNextRouteForScanBagLocation = (workflow) => {
    if (WORKFLOW.OPEN_BAG_IN_SAME_LOCATION === workflow || WORKFLOW.OPEN_BAG_AT_PACKAGE_SCAN === workflow) {
        return ROUTING.STOW_PACKAGE
    }
}
