export const COMMON_STRINGS = {
    select_task: "select_task",
    select_sortation_task: "select_sortation_task",
    select_scrub_task: "select_scrub_task",
    scan_bag: "scan_bag",
    SCAN_CONTAINER_OR_LOCATION: "scan_container_or_location",
    SCAN_CLOSE_CART_DESC: "scan_close_cart_desc",
    SCAN_CART: "scan_cart",
    scan_location: "scan_location",
    default_error: "default_error",
    auth_error: "auth_error",
    shelf: "shelf",
    ov_rack: "ov_rack",
    cart: "cart",
    bag: "bag",
    cart_type: "cart_type",
    location: "location",
    station_pair: "station_pair",
    package_id: "package_id",
    correct_location: "correct_location",
    wrong_location_scanned: "wrong_location_scanned",
    scan_printer: "scan_printer",
    grab_new_printer: "grab_new_printer",
    change_printer: "change_printer",
    scan_scrub_location: "scan_scrub_location",
    route_code: "route_code",
    container_type: "container_type",
    stacking_area: "stacking_area",
    package_sal_color: "package_sal_color",
    aisle: "aisle",
    CONFIRM: "confirm",
    CANCEL: "cancel",
    WRONG_CLUSTER_AISLE: "wrong_cluster_aisle",
    DAMAGED: "damaged",
    BACK_BUTTON_PRESSED: "back_button_pressed",
    BACK_BUTTON_NOTIFICATION: "back_button_notification_message",
    CONTINUE_STOWING: "continue_stowing",
    END_STOWING: "end_stowing",
    STOW_PROGRESS: "stow_progress",
    YES: "yes",
    NO: "no",
    GOT_IT: "got_it",
    TRAINING_BLOCKER_TITLE: "training_blocker_title",
    TRAINING_BLOCKER_DESC: "training_blocker_desc",
    HOT_SWAP: "hot_swap",
    SCAN_SECTOR_ID_DESC: "scan_sector_id_desc",
    ENTER_SECTOR_ID_TITLE: "enter_sector_id",
    PRINT_CONTAINER_LABEL: "Print Container Label",
    SELECT_SECTOR: "select_sector"
}

export const LANDING_VIEW_STRINGS = {
    manage_stow_area: "manage_stow_area",
    manage_stow_area_desc: "manage_stow_area_desc",
    stow_packages: "stow_packages",
    stow_packages_desc: "stow_packages_desc",
    prepare_carts: "prepare_carts",
    prepare_carts_desc: "prepare_carts_desc",
    prepare_stow_location: "prepare_stow_location",
    scan_and_prepare_carts: "scan_and_prepare_carts",
    scan_and_prepare_carts_ar: "scan_and_prepare_carts_ar",
    scan_and_prepare_carts_near_gravity_conveyor: "scan_and_prepare_carts_near_gravity_conveyor",
    prepare_stow_location_desc: "prepare_stow_location_desc"
}

export const INDIA_LANDING_VIEW_STRINGS = {
    open_route_containers: "open_route_containers",
    open_containers_before_stow_to_route: "open_containers_before_stow_to_route",
    stow_flow: "stow_flow_option",
    stow_flow_desc: "stow_flow_desc",
    topup_packages: "topup_packages",
    topup_packages_desc: "topup_packages_desc",
    scrub_container_desc: "scrub_container_desc",
    stow_to_route_readiness: "stow_to_route_readiness",
    scan_route_location: "scan_route_location",
    scan_bag_primary_title: "scan_bag_primary_title",
    scan_cart_primary_title: "scan_cart_primary_title",
    scan_empty_cart: "scan_empty_cart",
    scan_empty_bag: "scan_empty_bag",
}

export const MANAGE_STOW_AREA_VIEW_STRINGS = {
    prepare_container: "prepare_container",
    prepare_container_desc: "prepare_container_desc",
    close_container: "close_container",
    close_container_desc: "close_container_desc",
}

export const SELECT_CONTAINER_VIEW_STRINGS = {
    select_container: "select_container",
    bags: "bags",
    carts: "carts",
    pallets: "pallets",
    select_bags_guidance: "select_bags_guidance",
    select_carts_guidance: "select_carts_guidance",
    select_pallets_guidance: "select_pallets_guidance",
}

export const SCAN_PACKAGE_VIEW_STRINGS = {
    scan_package: "scan_package",
    attach_label: "attach_label"
}

export const SCAN_PALLET_LOCATION_VIEW_STRINGS = {
    scan_pallet_location: "scan_pallet_location",
    scan_pallet_location_guidance: "scan_pallet_location_guidance"
}

export const SCAN_BAG_VIEW_STRINGS = {
    scan_empty_bag: "scan_empty_bag"
}

export const CLOSE_SCAN_BAG_VIEW_STRINGS = {
    close_bag_guidance: "close_bag_guidance"
}

export const CLOSE_SCAN_BAG_LOCATION_VIEW_STRINGS = {
    close_bag_location_guidance: "close_bag_location_guidance"
}

export const SCAN_BAG_LOCATION_VIEW_STRINGS = {
    scan_bag_location_guidance: "scan_bag_location_guidance",
}

export const MOVE_TO_BUFFER_VIEW_STRINGS = {
    move_to_buffer: "move_to_buffer",
    move_to_buffer_guidance: "move_to_buffer_guidance",
    move_to_buffer_ack: "move_to_buffer_ack",
    move_to_buffer_package_id: "move_to_buffer_package_id",
}

export const PROBLEM_SOLVE_VIEW_STRINGS = {
    problem_solve: "problem_solve",
    scan_bin: "scan_bin",
    confirm_reason: "confirm_reason",
    scan_package: "scan_package"
}

export const SCAN_LOCATION_VIEW_STRINGS = {
    scan_location: "scan_location",
    scan_location_guidance: "scan_location_guidance",
    scan_route_container: "scan_route_container",
    scan_route_container_desc: "scan_route_container_desc"
}

export const SCRUB_CONTAINER_STRINGS = {
    scrub_container_guidance: "scrub_container_guidance",
    scrub_location_guidance: "scrub_location_guidance",
    DUMMY_DESTINATION: "DUMMY_DESTINATION",
    STACKING_AREA: "STACKING_AREA",
    scrub_container_scan_subtitle: "scrub_container_scan_subtitle",
    scrub_location_scan_subtitle: "scrub_location_scan_subtitle",
    scrub_container_confirmation: "scrub_container_confirmation",
    scrub_location_confirmation: "scrub_location_confirmation",
    scrub_container_scan_title: "scrub_container_scan_title",
    scrub_cancel_button: "scrub_cancel_button",
    scrub_confirm_button: "scrub_confirm_button",
    scrub_location_container: "scrub_location_container",
    scrub_location_container_desc: "scrub_location_container_desc",
    scrub_location_title: "scrub_location_title",
    scrub_location_task_desc: "scrub_location_task_desc",
    scrub_container_title: "scrub_container_title",
    scrub_container_task_desc: "scrub_container_task_desc",
    scrub_location_scan_title: "scrub_location_scan_title",
    scrub_container_successful: "scrub_container_successful",
    scrub_location_successful: "scrub_location_successful",
    scrub_container_invalid_scan: "scrub_container_invalid_scan",
    invalid_container_scan: "invalid_container_scan",
    scrub_location_invalid_scan: "scrub_location_invalid_scan",
    incorrect_location_scan: "incorrect_location_scan",
    api_error: "api_error",
    open_bag_error_scrub_bag: "open_bag_error_scrub_bag",
    open_bag_error_scrub_bag_desc: "open_bag_error_scrub_bag_desc",
    open_bag_error_cancel_option: "open_bag_error_cancel_option",
    scrub_bag_title: "scrub_bag_title",
    scrub_bag_guidance: "scrub_bag_guidance",
    scrub_bag_confirmation: "scrub_bag_confirmation",
    move_bag_to_problem_solve: "move_bag_to_problem_solve",
    move_bag_guidance: "move_bag_guidance",
    btn_confirm_bag_moved: "btn_confirm_bag_moved",
    open_bag_error_scrub_location: "open_bag_error_scrub_location",
    open_bag_error_scrub_location_desc: "open_bag_error_scrub_location_desc",
    open_bag_location_error_cancel_option: "open_bag_location_error_cancel_option",
    location_scrub_success_open_bag_success: "location_scrub_success_open_bag_success",
    scrub_bag_successful: "scrub_bag_successful"
}

export const APP_NAVIGATION_STRINGS = {
    stow: "stow",
    manage_stow_area: "manage_stow_area",
    prepare_container: "prepare_container",
    close_container: "close_container",
    scrub_bags: "scrub_bags",
    scrub_location_container: "scrub_location_container",
    help_center: "help_center",
    prepare_carts: "prepare_carts",
    prepare_stow_location: "prepare_stow_location",
    scrub_location: "scrub_location",
    stow_flow: "stow_flow",
    topup: "topup",
    continue_to_stage: "continue_to_stage"
}

export const NOTIFICATION_STRING = {
    ALERT_ERROR_BAG_NOT_OPEN: "alert.error.bag_not_open",
    ALERT_ERROR_CONTAINER_NOT_OPEN: "alert.error.container_not_open",
    ALERT_WARNING_BAG_FULL: "alert.warning.bag_full",
    ALERT_ERROR_BAG_WEIGHT_LIMIT_REACHED: "alert.error.bag_weight_limit_reached",
    ALERT_WARNING_BAG_WEIGHT_LIMIT_REACHED: "alert.warning.bag_weight_limit_reached",
    ALERT_ERROR_PACKAGE_CANNOT_BE_STOWED: "alert.error.package_cannot_be_stowed",
    ALERT_ERROR_NO_PLAN_PRESENT: "alert.error.no_plan_present",
    ALERT_ERROR_INVALID_PACKAGE_SCANNED: "alert.error.invalid_package_scanned",
    ALERT_ERROR_INCORRECT_PACKAGE_SCANNED: "alert.error.incorrect_package_scanned",
    ALERT_ERROR_INVALID_BARCODE_SCANNED: "alert.error.invalid_barcode_scanned",
    ALERT_ERROR_INCORRECT_BARCODE_SCANNED: "alert.error.incorrect_barcode_scanned",
    ALERT_ERROR_PACKAGE_WRONG_LOCATION: "alert.error.package_wrong_location",
    ALERT_ERROR_PACKAGE_WRONG_STATION: "alert.error.package_wrong_station",
    ALERT_ERROR_INVALID_LOCATION_SCANNED: "alert.error.invalid_location_scanned",
    ALERT_ERROR_INVALID_LOCATION_SCANNED_WITH_LOCATION: "alert.error.invalid_location_scanned_with_location",
    ALERT_SUCCESS_PACKAGE_STOWED: "alert.success.package_stowed",
    ALERT_ERROR_PACKAGE_CANNOT_STOWED: "alert.error.package_cannot_stowed",
    ALERT_ERROR_SOMETHING_HAPPENED_WITH_PACKAGE: "alert.error.something_happened_with_package",
    ALERT_ERROR_NON_EMPTY_BAG: "alert.error.non_empty_bag",
    ALERT_ERROR_NON_EMPTY_CART: "alert.error.non_empty_cart",
    ALERT_ERROR_NO_DESTINATION: "alert.error.no_destination",
    ALERT_ERROR_NO_BAG_FOUND: "alert.error.no_bag_found",
    ALERT_ERROR_BAG_STATION_MISMATCH: "alert.error.bag_station_mismatch",
    ALERT_ERROR_BAG_CANNOT_BE_USED: "alert.error.bag_cannot_be_used",
    ALERT_ERROR_BAG_CANNOT_BE_OPEN: "alert.error.bag_cannot_be_open",
    ALERT_WARNING_NON_BAG_ALREADY_OPEN: "alert.warning.bag_already_open",
    ALERT_SUCCESS_LOCATION_HAS_BAG: "alert.error.location_has_bag",
    ALERT_SUCCESS_BAG_READY: "alert.success.bag_ready",
    ALERT_SUCCESS_MOVED_TO_PROBLEM_SOLVE: "alert.success.moved_to_problem_solve",
    ALERT_ERROR_MOVED_TO_PROBLEM_SOLVE_FAILURE: "alert.error.move_to_problem_solve_failure",
    ALERT_ERROR_INVALID_SCAN: "alert.error.invalid_scan",
    ALERT_WARNING_BAG_ALREADY_CLOSED: "alert.warning.bag_already_closed",
    ALERT_ERROR_BAG_EMPTY: "alert.error.bag_empty",
    ALERT_ERROR_INCORRECT_BAG_SCANNED: "alert.error.incorrect_bag_scanned",
    ALERT_ERROR_INCORRECT_OV_SCANNED: "alert.error.incorrect_ov_scanned",
    ALERT_SUCCESS_BAG_MOVED_TO_OV_OPEN_NEW_BAG: "alert.success.bag_moved_to_ov_open_new_bag",
    ALERT_SUCCESS_BAG_READY_FOR_STOWING: "alert.success.bag_ready_for_stowing",
    ALERT_ERROR_INVALID_CART_SCAN: "alert.error.invalid_cart_scan",
    ALERT_ERROR_CART_CANT_BE_USED: "alert.error.cart_cant_be_used",
    ALERT_WARNING_CART_ALREADY_ATTACHED: "alert.warning.cart_already_attached",
    ALERT_SUCCESS_BINS_ALREADY_IN_CART: "alert.success.bins_ready_at_cart",
    ALERT_ERROR_INVALID_BAG_SCAN: "alert.error.invalid_bag_scan",
    ALERT_ERROR_BAG_MOVED_TO_PS: "alert.error.bag_moved_to_PS",
    ALERT_ERROR_ACTIVE_AT_LOCATION: "alert.error.has_active_at_location",
    ALERT_SUCCESS_BAG_READY_AT_CART: "alert.success.bag_ready_at_cart",
    ALERT_WARNING_CART_NOT_SETUP: "alert.warning.warning_cart_not_setup",
    ALERT_SUCCESS_CART_READY_AT_LOCATION: "alert.success.cart_ready_at_location",
    ALERT_ERROR_CART_IS_ACTIVE_AT_LOCATION: "alert.error.cart_is_active_at_location",
    ALERT_WARNING_PACKAGE_ALREADY_STOWED: "alert.warning.package_already_stowed",
    ALERT_ERROR_STOW_CART_NOT_SETUP: "alert.error.stow_cart_not_setup",
    ALERT_ERROR_CART_NOT_ATTACHED: "alert.error.cart_not_attached",
    ALERT_ERROR_PRINTER_NOT_CONNECTED: "alert.error.printer_not_connected",
    ALERT_ERROR_CONNECTION_FAILED: "alert.error.connection_failed",
    ALERT_ERROR_CONNECTION_FAILED_UNPAIRED_DEVICE: "alert.error.connection_failed_unpaired_device",
    ALERT_ERROR_CONNECTION_FAILED_PRINTER_OFF: "alert.error.connection_failed_printer_off",
    ALERT_SUCCESS_PRINT_SUCCESSFUL: "alert.success.print_success",
    ALERT_SUCCESS_PRINTER_CONNECTED: "alert.success.connected_device_success",
    ALERT_WARNING_PARTIAL_PRINT: "alert.warning.partial_print",
    ALERT_WARNING_BAG_HEAVY: "alert.warning.bag_heavy",
    ALERT_WARNING_BAG_NOT_OPEN: "alert.warning.bag_not_open",
    ALERT_SUCCESS_PACKAGE_MOVED_TO_BUFFER: "alert.success.package_moved_to_buffer",
    ALERT_SUCCESS_BAG_MOVED_TO_PS: "alert.success.bag_moved_to_ps",
    ALERT_SUCCESS_PALLET_READY: "alert.success.pallet_ready",
    ALERT_ERROR_NO_ACTIVE_CONTAINER_AT_LOCATION: "alert.error.no_active_container_at_location",
    ALERT_ERROR_CART_MOVED_TO_PS: "alert.error.cart_moved_to_PS",
    ALERT_ERROR_INVALID_PACKAGE_SORT_GROUP: "alert.error.invalid_package_sort_group",
    ALERT_ERROR_INCORRECT_LOCATION: "alert.error.incorrect_location",
    ALERT_ERROR_INCORRECT_LOCATION_SCANNED : "alert.error.incorrect_location_scanned",
    ALERT_SUCCESS_CONTAINER_CLOSED: "alert.success.container_closed",
    ALERT_ERROR_NO_OPEN_CONTAINER: "alert.error.no_open_container",
    ALERT_ERROR_TOO_MANY_CONTAINERS: "alert.error.too_many_containers",
    ALERT_ERROR_EMPTY_CONTAINER: "alert.error.empty_container",
    ALERT_ERROR_ROUTE_CONTAINERS_READY_AT_LOCATION: "alert.error.route_containers_ready_at_location",
    ALERT_SUCCESS_CART_READY_FOR_ROUTE: "alert.success.cart_ready_for_route",
    ALERT_SUCCESS_BAG_READY_FOR_ROUTE: "alert.success.bag_ready_for_route",
    ALERT_SUCCESS_ROUTE_CONTAINERS_READY_AT_LOCATION: "alert.success.route_containers_ready_at_location",
    ALERT_ERROR_CART_ALREADY_ATTACHED: "alert.error.cart_already_attached",
    ALERT_ERROR_NON_BAG_ALREADY_OPEN: "alert.error.bag_already_open",
    ALERT_WARNING_OBSTRUCTION_CLEARED: "alert.warning.obstruction_cleared",
    ALERT_ERROR_INVALID_SECTOR_ID: "alert.error.invalid_sector_id",
    ALERT_ERROR_COULDNT_FETCH_CONTAINER_LABELS: "alert.error.couldnt_fetch_container_labels",
    ALERT_WARNING_MANUAL_SCAN_DISABLED: "alert.warning.manual_scan_disabled",
    ALERT_WARNING_MISSORT_LOCATION_DETECTED: "alert.warning.missort_location_detected",
    ALERT_WARNING_VIRTUAL_NON_EMPTY_CART: "alert.warning.virtual_non_empty_cart",
    ALERT_ERROR_SORTKEY_MISMATCH: "alert.error.sort_key_mismatch"
}

export const HELP_CENTER_STRINGS = {
    BAG_FULL: "bag_full",
    CLOSE_CONTAINER: "close_container_help",
    CLOSE_CONTAINER_HELP: "close_container_help_2",
    CLOSE_CONTAINER_HELP_TEXT: "close_container_help_text",
    STOW_PROBLEMS_HELP: "stow_problems_help",
    PREPARE_CONTAINER_HELP: "prepare_container_help",
    PREPARE_CONTAINER_HELP_TEXT: "prepare_container_help_text",
    MARK_FOR_PROBLEM_SOLVE: "mark_for_problem_solve",
    PACKAGE_DOESNT_FIT: "package_doesnt_fit",
    OVERFLOW_CART_FULL: "overflow_cart_full",
    CART_NOT_AVAILABLE: "cart_not_available",
    DAMAGED_PACKAGE: "damaged_package",
    CANT_FIND_LOCATION: "cant_find_stow_location",
    BAG_IS_FULL: "bag_is_full",
    CART_IS_FULL: "cart_full_close_cart_help",
    TRACKING_ID_MANUAL_INPUT: "tracking_id_manual_input",
    PACKAGE_ID: "package_id",
    CANT_FIND_PACKAGE: "cant_find_package",
    HOT_SWAP_HELP: "hot_swap_help",
    EXIT_HOT_SWAP: "exit_hot_swap",
    SAL_COLOR_MISMATCH_TEXT: "sal_color_mismatch_text"
}

export const AR_VIEW_STRINGS = {
    select_sortation_type: "select_sortation_type",
    conveyable_packages: "conveyable_packages",
    conveyable_packages_desc: "conveyable_packages_desc",
    non_conveyable_packages: "non_conveyable_packages",
    non_conveyable_packages_desc: "non_conveyable_packages_desc",
    scan_empty_cart: "scan_empty_cart",
    scan_bag_primary_title: "scan_bag_primary_title",
    scan_empty_bag: "scan_empty_bag",
    scan_location: "scan_location",
    confirm_bag_location: "confirm_bag_location",
    bin_config: "bin_config",
    confirm_cart_location: "confirm_cart_location",
    select_stow_type_ar_field: "select_stow_type_ar_field",
    select_stow_type_ar_field_desc: "select_stow_type_ar_field_desc",
    select_stow_type_drs: "select_stow_type_drs",
    select_stow_type_drs_desc: "select_stow_type_drs_desc"
}

export const LIDAR_OBSTRUCTION_VIEW_STRINGS = {
    lidar_obstruction_message: "lidar_obstruction_message"
}

export const REPLACE_CONTAINER_VIEW_STRINGS = {
    replace_cart: "replace_cart",
    move_cart_to_PS: "move_cart_to_PS",
    confirm_cart_replaced: "confirm_cart_replaced",
    replace_bag: "replace_bag",
    move_bag_to_PS: "move_bag_to_PS",
    confirm_bag_moved: "confirm_bag_moved"
}

export const STOW_PROGRESS_VIEW_STRINGS = {
    scan_location_desc: "scan_location_desc",
    yet_to_be_stowed: "yet_to_be_stowed",
    ROUTE_CODE: "route_code",
    PLANNED: "planned",
    STOWED: "stowed",
    STAGED: "staged",
    NOT_STOWED: "not_stowed",
    TOPUP: "topup"
}

export const CONFIRM_CART_ISSUE_VIEW_STRINGS = {
    confirm_empty_cart: "confirm_empty_cart",
    check_empty_cart: "check_empty_cart"
}
