import {ROUTING} from "../../constants/Routing";
import {SCOPE} from "../../constants/Scope";
import {WORKFLOW} from "../workflow/Workflow";
import {RESPONSE} from "../../constants/ResponseCodes";
import {interceptBackButton} from "../bridge/BackButton";
import {NO_OP} from "../../constants/Constants";
import {DataHandler, FeatureManager} from "@amzn/dolphin-web-framework";
import {getRegion} from "./network/BaseUrlUtils";
import {Constants} from "@amzn/dolphin-web-framework";

export const EMPTY = ''
export const SLASH = '/'
export const HASHTAG = '#'
export const HEX_CODE_LENGTH = 7
export const EMPTY_ARRAY = []
const VIBRATION_TIME = 200;
const LIDAR_THROTTLE_TIME = 700;//milliseconds
let lastLiDAREventTime = 0;
let lastDestinationScanEventTime = 0;
const DESTINATION_SCAN_THROTTLE_TIME = 500;//milliseconds

const EU_NON_OV_LOCATION_LABEL_REGEX = /([A-Z]\d*)-(\d)([A-Z])/;
const FE_NON_OV_LOCATION_LABEL_REGEX = /([A-Z]\d*)-(\d)([A-Z])/;
const NA_NON_OV_LOCATION_LABEL_REGEX = /([A-Z]-\d*)\.(\d)([A-Z])/;

export const STOW_PACKAGES = 'DirectedStow';
export const AREA_READINESS = 'AreaReadiness';

const Params = Object.freeze({
    IS_MODULE_LAUNCHED_FROM_NATIVE : "isModuleLaunchedFromNative"
});

export const MODULE_NAME = 'DirectedStow'

//training status
export const TRAINING_STATUS = {
    EXPIRED: 'EXPIRED'
}

export const chainWalk = (func, defaultValue) => {
    try {
        const value = func()
        return (value === null || value === undefined) ? defaultValue : value;
    } catch (e) {
        return defaultValue
    }
}

export const NO_INTERNET_RESPONSE = {
    responseCode: RESPONSE.NO_INTERNET
}

export const UNAUTHORIZED_ERROR_RESPONSE = {
    responseCode: RESPONSE.UNAUTHORIZED
}

export const UNKNOWN_ERROR_OCCURRED_RESPONSE = {
    responseCode: RESPONSE.UNKNOWN_ERROR_OCCURRED
}

export const isNonNull = (obj) => {
    return obj !== null && obj !== undefined
}

export const isNonEmpty = (obj) => {
    return isNonNull(obj) && obj !== EMPTY
}

export const dev_debug = false

export const convertToString = (obj) => {
    return isNonNull(obj) ? obj.toString() : EMPTY
}

export const getDisplayContent = (itemId, isPackage) => {
    if (itemId === null || itemId === undefined) {
        return null;
    }
    const itemLength = itemId.length
    if (isPackage) {
        if (itemLength >= 5) {
            return itemId.substring(itemLength - 5,
                itemLength);
        }
        return itemId;
    } else {
        if (itemLength >= 3) {
            return itemId.substring(itemLength - 3,
                itemLength);
        }
        return itemId;
    }
}

export const getSelectContainerRoutingPath = (operation) => {
    return ROUTING.SELECT_CONTAINER + SLASH + operation
}

export const getHelpOptions = (callbacks, pageTitle, pageText) => {
    return {
        pageTitle: pageTitle,
        list: [{
            title: pageText,
            onClick: () => {
                callbacks.forEach(callback => callback())
            }
        }]
    }
};

export const getMultipleHelpOptions = (callbacksList, pageTitle, pageTextList) => {
    const helpList = []
    for(let i=0;i<callbacksList.length;i++){
        helpList.push({
            title: pageTextList[i],
            onClick: () => {
                callbacksList[i].forEach(callback => callback())
            }
        })
    }
    return {
        pageTitle: pageTitle,
        list: helpList
    }
};

export const getNotificationFormat = (type, message, values) => {
    return {
        type: type,
        message: message,
        values: values
    }
}

export const isArScope = (scope) => {
    return SCOPE.AR === scope || SCOPE.AR_NEXT_GEN === scope
}

export const isWorkflowBagHeavyOrBagFull = (workflow) => {
    return WORKFLOW.BAG_WEIGHT_LIMIT_AT_PACKAGE_SCAN === workflow || WORKFLOW.BAG_FULL === workflow
}

export const isAttachCartWorkflow = (workflow) => {
    return WORKFLOW.ATTACH_CART === workflow;
}

export const isPrepCartWorkflow = (workflow) => {
    return WORKFLOW.PREP_CART === workflow;
}

export const getFormattedString = (props, text) => {
    if(isNonEmpty(text)) {
        return props.intl.formatMessage({id: text})
    }
    return EMPTY
}

export const blockBackPress = (initContext, closeBackEnabled) => {
    const onBackPressed = ()=>{
        window.navigator.vibrate(VIBRATION_TIME);
    };
    initContext({onBackPressed});
    interceptBackButton(NO_OP);
    closeBackEnabled();
};

export const resetBlockedBackPress = (openBackEnabled, initContext, openBackDrop) => {
    openBackEnabled();
    const onBackPressed = openBackDrop;
    initContext({onBackPressed})
};

export const doesScannedCartMatchesWithExpectedCart = (scannedCart, expectedCart) => {
    if (scannedCart && expectedCart && (scannedCart === expectedCart)) {
        return true;
    }
    return false;
};

export const isOVPackage = (stowPackageModel) => {
    if (stowPackageModel.isOVPackage === true && FeatureManager.isFeatureEnabled(FeatureManager.Features.OV_SCAN_TO_BAG_BLOCK)) {
        return true;
    }
    return false;
}

export const isSidelinePackage = (stowPackageModel) => {
    if (stowPackageModel.isSidelinePackage === true) {
        return true;
    }
    return false;
}

export const isLiDAREventAllowed = () => {
    let currentTime = new Date();
    if (currentTime - lastLiDAREventTime < LIDAR_THROTTLE_TIME) {
        return false;
    } else {
        lastLiDAREventTime = currentTime;
        return true;
    }
}

export const isDestinationScanEventAllowed = () => {
    let currentTime = new Date();
    if (currentTime - lastDestinationScanEventTime < DESTINATION_SCAN_THROTTLE_TIME) {
        return false;
    } else {
        lastDestinationScanEventTime = currentTime;
        return true;
    }
}

export function getAisleRowAndColumnFromLocationLabel(label) {
    //get aisle & rack label from Bin label
    // FE : 'A26-2B' -> { aisleLabel: 'A26', rowLabel: '2', columnLabel: 'B' }
    // EU : 'A26-2B' -> { aisleLabel: 'A26', rowLabel: '2', columnLabel: 'B' }
    // NA : 'A-26.2B' -> { aisleLabel: 'A-26', rowLabel: '2', columnLabel: 'B' }
    let result;
    switch(getRegion()) {
        case Constants.Region.REGION_FE:
            result = label.match(FE_NON_OV_LOCATION_LABEL_REGEX)
            break
        case Constants.Region.REGION_EU:
            result = label.match(EU_NON_OV_LOCATION_LABEL_REGEX)
            break;
        case Constants.Region.REGION_NA:
            result = label.match(NA_NON_OV_LOCATION_LABEL_REGEX)
            break;
        default:
        //no-op
    }
    return {
        aisleLabel: result ? result[1] : EMPTY,
        rowLabel: result ? result[2] : EMPTY,
        columnLabel: result ? result[3] : EMPTY
    };
}

export const isModuleLaunchedFromNative = () => {
    return DataHandler.params && (DataHandler.params.get(Params.IS_MODULE_LAUNCHED_FROM_NATIVE) ? DataHandler.params.get(Params.IS_MODULE_LAUNCHED_FROM_NATIVE) : false);
}
