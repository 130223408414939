import {
    GET_SORT_INFO_FOR_PACKAGE_RESPONSE,
    PROCESS_STOW_RESPONSE,
    VALIDATE_LOCATION_BEFORE_STOW,
    GET_ACTIVE_ROUTES_RESPONSE,
    GET_ACTIVE_ROUTES_PAGE, GET_DATA_SNAPSHOTS
} from "../reducer/StowPackageReducer";
import {
    getStowScanLocationResource,
    getStowScanPackageFlowResource,
    getStowScanPackageResource,
    getValidateLocationBeforeStowResource,
    getStowSblSourceScanResource,
    getStowSblDestinationScanResource,
    getActiveRoutesForStowResource,
    generateContainerLabelsResource, getSectorsResource
} from "../resource/StowPackageResource";
import {WORKFLOW} from "../../workflow/Workflow";
import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {RESPONSE} from "../../../constants/ResponseCodes";

const getSortInfoForPackageResponse = (data, workflow, expectedPackageId) => {
    return {
        type: GET_SORT_INFO_FOR_PACKAGE_RESPONSE,
        data: {
            ...data,
            workflow: workflow,
            expectedPackageId: expectedPackageId
        }
    }
}

const processStowResponse = (data, workflow) => {
    return {
        type: PROCESS_STOW_RESPONSE,
        data: {
            ...data,
            workflow: workflow
        }
    }
}

const getSectorsResponse = (response) => {
    return {
        type: GET_DATA_SNAPSHOTS,
        data: {
        dataSnapshots: response
        }
    }
}

const getValidateLocationBeforeStowResponse = (data, workflow) => {
    return {
        type: VALIDATE_LOCATION_BEFORE_STOW,
        data: {
            ...data,
            workflow: workflow
        }
    }
}

const getActiveRoutesForStowResponse = (data, history) => {
    return {
        type: GET_ACTIVE_ROUTES_RESPONSE,
        data: {
            ...data,
            printEnabled:false,
            helpFlow: history.location.state.helpFlow,
            containerView: false
        }
    }
}

const generateContainerLabelsResponse = (data) => {
    return {
        type: GET_ACTIVE_ROUTES_PAGE,
        data: {
            ...data,
            printEnabled: true
        }

    }
}

export const getSortInfoForPackage = (dispatcher, packageScannableId, scope, workflow, expectedPackageId, sblEndpoint, shouldNotifySbl, statePlayer) => {
    if(shouldNotifySbl) {
        getStowSblSourceScanResource(sblEndpoint, packageScannableId)
    }
    getStowScanPackageResource(packageScannableId, scope).then(response => {
        if(statePlayer){
            statePlayer.isValidPackageReScan = (expectedPackageId === response.packageTrackingId);
        }
        dispatcher(getSortInfoForPackageResponse(response, workflow, expectedPackageId))
    }).catch(() => {
    })
}

export const processStow = (dispatcher, packageDestinationInfo, workflow, sblEndpoint) => {
    getStowSblDestinationScanResource(sblEndpoint, packageDestinationInfo.packageTrackingId,
        packageDestinationInfo.scannedDestination)
    if (WORKFLOW.STOW_FLOW === workflow) {
        getStowScanPackageFlowResource(packageDestinationInfo).then(response => {
            dispatcher(processStowResponse(response, workflow))
        }).catch(() => {
        })
    }
     else {
         getStowScanLocationResource(packageDestinationInfo).then(response => {
            dispatcher(processStowResponse(response, workflow))
        }).catch(() => {
        })
    }
}

export const getValidateLocationBeforeStow = (dispatcher, packageScannableId, scope, workflow) => {
    getValidateLocationBeforeStowResource(packageScannableId, scope, workflow).then(response => {
        if(!response.targetSortLocation) {
            workflow = null
        }
        dispatcher(getValidateLocationBeforeStowResponse(response, workflow))
    }).catch(() => {
    })
}

export async function printContainerLabel (containerList, dispatcher) {
    generateContainerLabelsResource(containerList).then( async response =>{
        if (RESPONSE[response.responseCode] !== "UNKNOWN_ERROR_OCCURRED") {
            for (let i = 0; i < response.routeContainerLabelList.length; i++) {
                for(let j=0; j < response.routeContainerLabelList[i].length; j++) {
                    await NativeMeshInteractor.printZebra(response.routeContainerLabelList[i][j].containerLabel.ZPL_PRINTER)
                }
            }
        }

        dispatcher(generateContainerLabelsResponse(response))
    })
}

export const getActiveRoutesForStow = (sectorId, sectorName, dispatcher, history) => {
    getActiveRoutesForStowResource(sectorId, sectorName).then(response => {
        dispatcher(getActiveRoutesForStowResponse(response, history))
    }).catch(() => {
    })

}


export const getSectors = (dispatcher) => {
    getSectorsResource().then(response => {
        dispatcher(getSectorsResponse(response))
    }).catch(() => {
    })

}