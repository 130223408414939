import React, {useContext} from "react"
import Column from "@amzn/meridian/column";
import Alert from "@amzn/meridian/alert";
import {NotificationContext} from "../context/NotificationContext";
import {FormattedMessage} from "react-intl";
import {isNonEmpty} from "../../utils/Util";
import {AppNavigationContext} from "../../app-navigation/context/AppNavigationContext";

const NotificationView = () => {

    const {notificationModel, notificationActions: {clearNotification}} = useContext(NotificationContext)
    const {navigationModel} = useContext(AppNavigationContext);

    const renderNotification = () => {
        if (isNonEmpty(notificationModel.message)) {
            return (
                <Column width="100%" spacingInset="small">
                    <div aria-hidden={navigationModel.helpOptions.open || navigationModel.backDropOpen}>
                        <Alert type={notificationModel.type} size="medium" onClose={clearNotification}>
                            <FormattedMessage id={notificationModel.message} values={notificationModel.values}/>
                        </Alert>
                    </div>
                </Column>
            )
        } else {
            return null
        }
    }

    return renderNotification()
}

export default NotificationView;
