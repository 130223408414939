import React, {useContext, useEffect} from "react"
import {WarningView} from "@amzn/dolphin-uiweb-framework";
import {AppNavigationContext} from "../../../app-navigation/context/AppNavigationContext";
import {StowPackageContext} from "../../context/StowPackageContext";
import {AnalyticEventKeys, Logger, MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {playSoundByAlertType} from "../../../sound/SoundManager";
import {NotificationType} from "../../../../constants/Constants";
import {LIDAR_OBSTRUCTION_VIEW_STRINGS, NOTIFICATION_STRING} from "../../../../constants/Strings";
import {NOTIFICATION_TYPE} from "../../../../constants/NotificationType";
import {NotificationContext} from "../../../notification/context/NotificationContext";
import {injectIntl} from "react-intl";
import {CLEAR_LIDAR_OBSTRUCTION} from "../../reducer/StowPackageReducer";
import LidarInteractor from "../../../scanless-communicator/handler/LidarInteractor";

const LiDARObstructionView = (props) => {

    const {navActions: {setNavWarningCol, resetNavCol}} = useContext(AppNavigationContext);
    const {stowPackageModel, stowPackageDispatch} = useContext(StowPackageContext);
    const {notificationActions: {setNotification}} = useContext(NotificationContext);

    const obstructingBin = LidarInteractor.getObstructingBin(stowPackageModel.targetSortLocation.locationInfo.locationLabel)

    const clearObstruction = () => {
        if (!LidarInteractor.getObstructingBin(stowPackageModel.targetSortLocation.locationInfo.locationLabel)) {
        LidarInteractor.clearObstructionMessageBroker();
        stowPackageDispatch({type: CLEAR_LIDAR_OBSTRUCTION})
        }
    }

    const clearObstructionNotification = {
        type: NOTIFICATION_TYPE.WARNING,
        message: NOTIFICATION_STRING.ALERT_WARNING_OBSTRUCTION_CLEARED,
        values: {
            packageId: stowPackageModel.packageTrackingId,
            locationLabel: stowPackageModel.targetSortLocation.locationInfo.locationLabel
        }
    };

    useEffect(() => {
        setNavWarningCol();
        MobileAnalyticsHelper.processAnalytics(Date.now(), AnalyticEventKeys.Modules.PWA, "", AnalyticEventKeys.Modules.STOW,
            obstructingBin, "Obstruction", AnalyticEventKeys.Events.USER_LOADED_SCREEN);
        Logger.log.warn("Blocking obstruction recorded at : " + LidarInteractor.getObstructingBin(stowPackageModel.targetSortLocation.locationInfo.locationLabel)
                + " for : " + stowPackageModel.packageTrackingId);
        playSoundByAlertType(NotificationType.ERROR);
        LidarInteractor.setObstructionMessageBroker(clearObstruction);
        return () => {
            resetNavCol();
            LidarInteractor.clearObstructionMessageBroker();
            setNotification(clearObstructionNotification)
            MobileAnalyticsHelper.processAnalytics(Date.now(), AnalyticEventKeys.Modules.PWA, "", AnalyticEventKeys.Modules.STOW,
                obstructingBin, "ClearObstruction", AnalyticEventKeys.Events.USER_LOADED_SCREEN);
        }
    }, []);

    return (
        <WarningView warningMessage={LIDAR_OBSTRUCTION_VIEW_STRINGS.lidar_obstruction_message} values={{bin: obstructingBin}}/>
    )
}

export default injectIntl(LiDARObstructionView)
