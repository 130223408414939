import React, {useContext, useEffect} from 'react';
import {NotificationContext} from "../notification/context/NotificationContext";
import {ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import {Logger, MeshEvents} from "@amzn/dolphin-web-framework";
//import {ConfigContext} from "../config/context/ConfigContext";
//import {WORKFLOW} from "../workflow/Workflow";

const ScanView = (props) => {
    const dolphinBus = new BroadcastChannel('DOLPHIN_BUS');
    //const {configViewModel} = useContext(ConfigContext);
    const {notificationActions: {clearNotification}} = useContext(NotificationContext);

    const onMessage = (event) => {
        try {
            const eventData = JSON.parse(event.data);
            console.log("NEWDATA" + eventData);
            if(eventData.eventName && eventData.eventName === MeshEvents.MESH_EVENTS.AVERY_SCAN) {
                handleScan(eventData.payload);
            }
        } catch (JSONParseException) {
            Logger.log.warn("Enable to parse event : " + event.data);
        }
    };

    const handleScan = (input) => {
        console.log("NEWDATA2" + input);
        Logger.log.info("Scanned value " + input);
        clearNotification();
        props.onScan(input.trim())
    };

    useEffect(() => {
//        if (WORKFLOW.CON_PACKAGE === configViewModel.workflow) {
        console.log("Inside UseEffect SEW");
            dolphinBus.addEventListener('message', onMessage);
            return () => {
                console.log("Returning from UseEffect SEW");
                dolphinBus.removeEventListener('message', onMessage);
            };
//        }
    });

    return (
        <ScanWithCodeView onSubmit={handleScan} focusLock={true} ariaLabel={props.ariaLabel}/>
    );
};

export default ScanView
