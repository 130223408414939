import {RESPONSE} from "../../../../../../constants/ResponseCodes";
import {VIEW} from "../../../../../workflow/Workflow";
import {NOTIFICATION_TYPE} from "../../../../../../constants/NotificationType";
import {COMMON_STRINGS, NOTIFICATION_STRING} from "../../../../../../constants/Strings";
import {CONTAINER_TYPE} from "../../../../../../constants/ContainerType";
import {getContainerRoutingPath, ROUTING} from "../../../../../../constants/Routing";
import {getNotificationFormat} from "../../../../../utils/Util";
import {SCOPE} from "../../../../../../constants/Scope";
import {FeatureManager} from "@amzn/dolphin-web-framework";

export const getScanOvCartViewData = (input) => {
    const values = {
        scannedCartId: input.activeCartId,
        destinationLabel: input.existingLocationLabel
    }

    switch (input.responseCode) {
        case RESPONSE.SUCCESS:
            return {
                view: SCOPE.SSD === input.scope ? VIEW.SCAN_CONTAINER_LOCATION : VIEW.SCAN_CART_LOCATION
            }
        case RESPONSE.NO_INTERNET:
            return {
                nextRoute: ROUTING.NO_INTERNET
            }
        case RESPONSE.INVALID_SCAN_DONE:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_INVALID_CART_SCAN, values)
            }
        case RESPONSE.NON_EMPTY_CART:
            if (shouldScrubContainerBeforeAttach(input.scope)) {
                return {  
                    view: VIEW.CART_ATTACH_ISSUE_CONFIRM,
                    notification: getNotificationFormat(NOTIFICATION_TYPE.WARNING, NOTIFICATION_STRING.ALERT_WARNING_VIRTUAL_NON_EMPTY_CART, values)
                }
                
            }
            return {
                nextRoute: getContainerRoutingPath(ROUTING.REPLACE_CONTAINER, CONTAINER_TYPE.CART),
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_CART_CANT_BE_USED, values)
            }
        case RESPONSE.CART_ALREADY_ATTACHED:
            if (shouldScrubContainerBeforeAttach(input.scope)) {
                values.existingContainerParentLabel = input.existingLocationLabel;
                return {  
                    view: VIEW.CART_ATTACH_ISSUE_CONFIRM,
                    notification: getNotificationFormat(NOTIFICATION_TYPE.WARNING, NOTIFICATION_STRING.ALERT_WARNING_CART_ALREADY_ATTACHED, values)
                }
                
            }
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.WARNING, NOTIFICATION_STRING.ALERT_WARNING_CART_ALREADY_ATTACHED, values)
            }
        case RESPONSE.UNAUTHORIZED:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.auth_error)
            }
        default:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.default_error)
            }
    }
}

const shouldScrubContainerBeforeAttach = (scope)  => {
    return (SCOPE.AMZL === scope
        && FeatureManager.isFeatureEnabled(FeatureManager.Features.SCRUB_CONTAINER_BEFORE_ATTACH_ENABLED));
}