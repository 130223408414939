import React, {useContext, useEffect} from 'react';
import {
    CLOSE_SCAN_BAG_VIEW_STRINGS,
    COMMON_STRINGS,
    HELP_CENTER_STRINGS,
    NOTIFICATION_STRING
} from "../../../../../constants/Strings";
import {NotificationContext} from "../../../../notification/context/NotificationContext";
import {FormattedMessage, injectIntl} from "react-intl";
import {AppNavigationContext} from "../../../../app-navigation/context/AppNavigationContext";
import {getHelpOptions, getSelectContainerRoutingPath, isWorkflowBagHeavyOrBagFull} from "../../../../utils/Util";
import {STOW_AREA_OPERATION} from "../../../../../constants/StowAreaOperation";
import {CloseContainerContext} from "../../context/CloseContainerContext";
import {getBagSortInfoForClose} from "../../action/CloseContainerAction";
import ScanView from "../../../../scan-view/ScanView";
import Column from "@amzn/meridian/column";
import {ConfigContext} from "../../../../config/context/ConfigContext";
import {NOTIFICATION_TYPE} from "../../../../../constants/NotificationType";
import ScanQrCodeView , {HEADING_TYPE} from "../../../../scan-qr-code-view/ScanQrCodeView";
import {PlayerContext} from "../../../../player/context/PlayerContext";
import {ScanTypes} from "../../../../player/PlayerConstant";
import {ApplicationActions} from "../../../../player/action/ApplicationActions";
import {RESPONSE} from "../../../../../constants/ResponseCodes";
import {CONTAINER_TYPE} from "../../../../../constants/ContainerType";

const CloseScanBagView = (props) => {

    const {configViewModel} = useContext(ConfigContext)
    const {notificationActions: {setNotification}} = useContext(NotificationContext)
    const {navActions: {setHelpOption, closeHelpOption}} = useContext(AppNavigationContext)
    const {closeContainerDispatch} = useContext(CloseContainerContext)
    const {statePlayer, dispatchPlayer} = useContext(PlayerContext);

    const onScan = (input) => {
        if (isWorkflowBagHeavyOrBagFull(configViewModel.workflow) && input !== configViewModel.expectedBagLabel) {
            const notification = {
                type: NOTIFICATION_TYPE.ERROR,
                message: NOTIFICATION_STRING.ALERT_ERROR_INCORRECT_BAG_SCANNED,
                values: {bagLabel: input}
            }
            setNotification(notification)
            dispatchPlayer({type: ApplicationActions.CONTAINER_SCAN, data: {errorResponse: RESPONSE.INVALID_MOVE,
                    operation: STOW_AREA_OPERATION.CLOSE_CONTAINER, type: CONTAINER_TYPE.BAG}});
        } else {
            statePlayer.scanType = ScanTypes.CLOSE_BAG_SCAN
            getBagSortInfoForClose(closeContainerDispatch, input, configViewModel.scope)
        }
    }

    useEffect(() => {
        const callbacks = [
            () => {
                dispatchPlayer({type: ApplicationActions.HELP_OPTION_SELECTED, data: {operation: HELP_CENTER_STRINGS.CLOSE_CONTAINER_HELP}});
                props.history.push(getSelectContainerRoutingPath(STOW_AREA_OPERATION.CLOSE_CONTAINER))
            },
            closeHelpOption
        ]
        setHelpOption(true, getHelpOptions(callbacks,
            <FormattedMessage id={HELP_CENTER_STRINGS.CLOSE_CONTAINER_HELP}/>,
            <FormattedMessage id={HELP_CENTER_STRINGS.CLOSE_CONTAINER_HELP_TEXT}/>));
        return () => setHelpOption(false, {});
    }, [])

    const init = {
        primaryTitle: COMMON_STRINGS.scan_bag,
        secondaryTitle: CLOSE_SCAN_BAG_VIEW_STRINGS.close_bag_guidance
    }

    return (
        <Column spacingInset="medium" alignmentHorizontal="center">
            <ScanQrCodeView {...init} type={HEADING_TYPE.PRIMARY}/>
            <ScanView onScan={onScan}/>
        </Column>
    )
}

export default injectIntl(CloseScanBagView)
