import React, {useContext, useEffect} from 'react';
import {StowPackageContext} from "../context/StowPackageContext";
import StowScanPackageView from "./scan-package/StowScanPackageView";
import {NotificationContext} from "../../notification/context/NotificationContext";
import {chainWalk, getFormattedString, isNonNull} from "../../utils/Util";
import {getNavTitleForWorkflow, VIEW} from "../../workflow/Workflow";
import StowRescanPackageView from "./rescan-package/StowRescanPackageView";
import {ConfigContext} from "../../config/context/ConfigContext";
import {initializeSbl, setGlobalContext} from "../../config/action/ConfigAction";
import {AppNavigationContext} from "../../app-navigation/context/AppNavigationContext";
import {APP_NAVIGATION_STRINGS} from "../../../constants/Strings";
import Loading from "../../loading/Loading";
import {interceptBackButton, interceptBackButtonStop} from "../../bridge/BackButton";
import StowManualScan from "./manual-scan/StowManualScanView";
import {CUSTOM_HELP_OPTIONS} from "../../app-navigation/AppNavigation";
import {injectIntl} from "react-intl";
import {CONSTANT, POLLER_TIMER} from "../../../constants/Constants";
import {PlayerContext} from "../../player/context/PlayerContext";
import {ScanTypes} from "../../player/PlayerConstant";
import {ApplicationActions} from "../../player/action/ApplicationActions";
import AppDataStore from "../../app-data";
import StowScanLocationView from "./scan-location/StowScanLocationView";
import CartStageReadinessPollerInstance from "../../../util/CartStageReadinessPoller";
import {ROUTING} from "../../../constants/Routing";
import {SCOPE} from "../../../constants/Scope";
import {FeatureManager} from "@amzn/dolphin-web-framework";
import LiDARObstructionView from "./lidar-obstruction/LiDARObstructionView";

const StowPackageView = (props) => {

    const {configViewModel, configViewDispatch} = useContext(ConfigContext);
    const {stowPackageModel} = useContext(StowPackageContext);
    const {notificationActions: {setNotification}} = useContext(NotificationContext);
    const {navigationModel, navActions: {setNavTitle, openBackDrop, initContext, clearContext}} = useContext(AppNavigationContext)
    const {statePlayer, dispatchPlayer} = useContext(PlayerContext);

    const isTopupFlow = props.stowFlow === CONSTANT.TOPUP_PACKAGE

    useEffect(() => {
        if (isNonNull(stowPackageModel.notification)) {
            setNotification(stowPackageModel.notification)
        }
        if (isNonNull(stowPackageModel.nextRoute)) {
            interceptBackButtonStop()
            props.history.push(stowPackageModel.nextRoute)
        }
        if (isNonNull(stowPackageModel.responseCode) && !(stowPackageModel.loading)) {
            switch (statePlayer.scanType) {
                case ScanTypes.PACKAGE_SCAN:
                    dispatchPlayer({type: ApplicationActions.PACKAGE_SCAN, data: {errorResponse: stowPackageModel.responseCode}});
                    break;
                case ScanTypes.LOCATION_SCAN:
                    dispatchPlayer({type: ApplicationActions.PACKAGE_LOCATION_SCAN, data: {errorResponse: stowPackageModel.responseCode}});
                    break;
                case ScanTypes.PACKAGE_RESCAN:
                    dispatchPlayer({type: ApplicationActions.PACKAGE_RESCAN, data: {errorResponse: stowPackageModel.responseCode}});
                    break;
                default:
                    break;
            }
        }

        const globalContext = {
            packageId: stowPackageModel.packageId,
            displayPackageId: stowPackageModel.displayPackageId,
            aisle: stowPackageModel.aisle,
            rack: stowPackageModel.rack,
            locationLabel: chainWalk(() => stowPackageModel.targetSortLocation.locationInfo.locationLabel, undefined),
            expectedBagLabel: stowPackageModel.expectedBagLabel
        };
        if(stowPackageModel.workflow) {
            globalContext.workflow = stowPackageModel.workflow;
        }
        setGlobalContext(configViewDispatch, globalContext)
    }, [stowPackageModel]);

    useEffect(() => {
        if (FeatureManager.isFeatureEnabled(FeatureManager.Features.CART_HOT_SWAP) && (SCOPE.AR_NEXT_GEN === configViewModel.scope)) {
            AppDataStore.completeHotSwap();
            CartStageReadinessPollerInstance.startPolling(renderCartHotSwapView, POLLER_TIMER);
        }
        if (FeatureManager.isFeatureEnabled(FeatureManager.Features.SBL_LOCAL_ENDPOINT_ENABLED)) {
            initializeSbl(configViewDispatch);
        }
        const onBackPressed = openBackDrop
        initContext({onBackPressed})
        const title = isTopupFlow ? APP_NAVIGATION_STRINGS.topup : APP_NAVIGATION_STRINGS.stow
        setNavTitle(getNavTitleForWorkflow(configViewModel.workflow, getFormattedString(props, title)));
        interceptBackButton('stow package')
        return() => {
            clearContext();
            CartStageReadinessPollerInstance.stopPolling();
        }
    }, []);

    const renderCartHotSwapView = (cartId, stationPair, location, routeId) => {
        interceptBackButtonStop();
        return props.history.push({pathname: ROUTING.CART_HOT_SWAP, state: {cartId, stationPair, location, routeId}});
    };

    const renderView = () => {
        switch (stowPackageModel.view) {
            case VIEW.STOW_LOCATION:
                return <StowScanLocationView history={props.history}/>
            case VIEW.STOW_RESCAN_PACKAGE:
                return <StowRescanPackageView history={props.history}/>
            case VIEW.OBSTRUCTION:
                return <LiDARObstructionView history={props.history}/>
            default:
                if (navigationModel.customHelpOption.open
                    && CUSTOM_HELP_OPTIONS.STOW_MANUAL_SCAN_PACKAGE === navigationModel.customHelpOption.type) {
                    return <StowManualScan history={props.history} isTopupFlow={isTopupFlow}/>
                }
                return <StowScanPackageView history={props.history} isTopupFlow={isTopupFlow}/>
        }
    }

    return (
        <>
            {(stowPackageModel.loading) && <Loading/>}
            {renderView()}
        </>
    )
};

export default injectIntl(StowPackageView)
