import {RESPONSE} from "../../../../../../constants/ResponseCodes";
import {VIEW} from "../../../../../workflow/Workflow";
import {getNotificationFormat} from "../../../../../utils/Util";
import {NOTIFICATION_TYPE} from "../../../../../../constants/NotificationType";
import {COMMON_STRINGS, NOTIFICATION_STRING} from "../../../../../../constants/Strings";

export const getRoutesContainerLocationViewData = (input) => {

    const values = {
        locationLabel: input.sortLocationLabel
    }
    switch (input.responseCode) {
        case RESPONSE.SUCCESS:
            if (input.activeRouteIndex < input.routes.length) {
                return {
                    view: VIEW.SCAN_CONTAINER
                }
            }
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.SUCCESS,
                    NOTIFICATION_STRING.ALERT_ERROR_ROUTE_CONTAINERS_READY_AT_LOCATION, values)
            }
        case RESPONSE.INVALID_SCAN_DONE:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_INVALID_LOCATION_SCANNED)
            }
        case RESPONSE.UNAUTHORIZED:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.auth_error)
            }
        default:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.default_error)
            }
    }
}