import {RESPONSE} from "../../../../../../constants/ResponseCodes";
import {VIEW, WORKFLOW} from "../../../../../workflow/Workflow";
import {NOTIFICATION_TYPE} from "../../../../../../constants/NotificationType";
import {COMMON_STRINGS, NOTIFICATION_STRING} from "../../../../../../constants/Strings";
import {getNotificationFormat} from "../../../../../utils/Util";
import {ROUTING} from "../../../../../../constants/Routing";

export const getScanCartLocationViewData = (input) => {
    const values = {
        activeCartId: input.activeCartId,
        destinationLabel: input.destinationLabel
    }

    switch (input.responseCode) {

        case RESPONSE.SUCCESS:
            let view = VIEW.SCAN_CART;
            if (WORKFLOW.ATTACH_EMPTY_CART_AT_STOWING === input.workflow) {
                view = VIEW.STOW_PACKAGE
            }
            return {
                view: view,
                notification: getNotificationFormat(NOTIFICATION_TYPE.SUCCESS,
                    NOTIFICATION_STRING.ALERT_SUCCESS_CART_READY_AT_LOCATION, values)
            }
        case RESPONSE.NO_INTERNET:
            return {
                nextRoute: ROUTING.NO_INTERNET
            }
        case RESPONSE.INVALID_MOVE:
            return {
                view: VIEW.SCAN_CART,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_INVALID_BARCODE_SCANNED)
            }
        case RESPONSE.INVALID_SCAN_DONE:
            return {
                view: VIEW.SCAN_CART,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_INVALID_LOCATION_SCANNED)
            }
        case RESPONSE.NON_EMPTY_LOCATION:
            return {
                view: VIEW.SCAN_CART,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_CART_IS_ACTIVE_AT_LOCATION, values)
            }
        case RESPONSE.UNAUTHORIZED:
            return {
                view: VIEW.SCAN_CART,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.auth_error)
            }
        default:
            return {
                view: VIEW.SCAN_CART,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.default_error)
            }
    }

}
