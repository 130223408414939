// Export Interceptors
import {Logger , PerformanceMonitoringHelper} from "@amzn/dolphin-web-framework";
import { v4 as uuidv4 } from 'uuid';

let dolphinRequestId = "";

export const sblInterceptorBuilder = (axiosInstance) => {
    return {
        onBeforeRequest,
        onRequestError,
        onResponseSuccess,
        onResponseError
    };
};

// Interceptors
const onBeforeRequest = async (config) => {
    dolphinRequestId = uuidv4();
    const logConfig = (({method, url, headers, data, params}) => ({method, url, headers, data, params}))(config);
    Logger.log.warn("SBL Interceptor Request log", applyDolphinRequestId(logConfig));
    Logger.log.info("SBL Interceptor Request", logConfig);
    return config;
};

const onRequestError = (error) => {
    Logger.log.error("SBL Interceptor Request Error", error);
    throw error;
};

const onResponseSuccess = async (response) => {
    const {config, ...logResponse} = response;
    const logResponseWithRequestId =  applyDolphinRequestId(logResponse);
    const logResponseWithRequestIdAndPerformanceData = applyPerformanceData(logResponseWithRequestId);
    Logger.log.warn("Success Response for SBL", logResponseWithRequestIdAndPerformanceData);
    Logger.log.info("SBL Response Success", response);
    return response;
};

const onResponseError = async (error) => {
    Logger.log.error("SBL Response Error", error);
    throw error;
};

const applyDolphinRequestId = (object = {}) => {
    return {...object, dolphinClientRequestId: dolphinRequestId};
};

const applyPerformanceData = (logResponse) => {
    const performanceData = PerformanceMonitoringHelper.getPerformanceData(getResponseUrl(logResponse));
    return {...logResponse, performanceData: performanceData}
};

const getResponseUrl = (logResponse) => {
    if (logResponse?.request) {
        return logResponse.request.responseURL;
    }
};